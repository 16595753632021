import {Component} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../auth/auth.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ValidationMessages} from "../../shared/Validation/validation.messages";
import {PasswordResetService} from "./password-reset.service";
import {RequestPassword} from "./password-reset.types";
import {ButtonTypes} from "../ui/loading-button/loading-button.component";
import {environment} from "../../environments/environment";


@Component(
    {
        selector: 'password-reset',
        templateUrl: './password-reset.component.html',
        styleUrls: ['./password-reset.component.scss']
    }
)
export class PasswordResetComponent {
    buttonTypes = ButtonTypes
    passForgot: boolean = true;
    isNotLoading: boolean = true;
    login = true;
    prGroup = new FormGroup({
        email: new FormControl("", [Validators.required, Validators.email]),
    })
    constructor(private authService: AuthService, private router: Router, private snackbar: MatSnackBar, private passwordResetService: PasswordResetService) {}

    email = this.prGroup.get('email')!
    backToLogin(){
        this.router.navigate(["/login"])
    }
   async resetPass(repeated: boolean) {
        this.isNotLoading = false
       if (!this.prGroup.valid) {
           this.prGroup.markAllAsTouched()
           this.isNotLoading = true
           this.snackbar.open(ValidationMessages.FAILURE, ValidationMessages.OK);
           return;
       }
           this.isNotLoading= false
       const url = environment.ccUrl + "/resetPassword"
           this.passwordResetService.requestResetPassword(new RequestPassword(this.prGroup.get('email')?.value!, url )).subscribe(
               () => {
                   this.passForgot = false;
                   if(repeated){
                   this.snackbar.open(ValidationMessages.GOT_MAIL, ValidationMessages.OK)}},
               (error) => {
                   console.log(error.error)
                   if(error.error.toString() == "Tenant not found."){
                       this.snackbar.open(ValidationMessages.TENANT_EMAIL_NOT_FOUND, "OK", {duration: 5000})
                   }else{
                       this.snackbar.open(ValidationMessages.HTTP_FAILURE, "OK", {duration: 5000});
                   }
                   }
           )
           this.isNotLoading = true
   }

    getErrorMessage() {
        if (this.email.hasError('required')) {
            return ValidationMessages.WRONG_EMAIL;
        }

        return this.email.hasError('email') ? ValidationMessages.WRONG_EMAIL : '';
    }

    protected readonly ValidationMessages = ValidationMessages;
}