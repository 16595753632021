import {NgModule} from '@angular/core';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {PasswordResetComponent} from "./password-reset.component";
import {DefaultCardModule} from "../ui/default-card/default-card.module";
import {PasswordResetService} from "./password-reset.service";
import {PasswordResetDatasource} from "./password-reset.datasource";
import {LoadingButtonModule} from "../ui/loading-button/loading-button.module";



@NgModule({
    declarations: [
        PasswordResetComponent
    ],
    imports: [
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        CommonModule,
        DefaultCardModule,
        LoadingButtonModule

    ],
    providers: [
        PasswordResetService,
        PasswordResetDatasource
    ]
})
export class PasswordResetModule {
}
