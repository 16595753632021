import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from "@angular/core";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {ValidationMessages} from "../../../shared/Validation/validation.messages";
@Component(
    {
        selector: 'from-to',
        templateUrl: './fromTo.component.html',
        styleUrls: ['./fromTo.component.scss']
    }
)
export class FromToComponent implements OnInit {
    @Input()
    pFormGroup: FormGroup = new FormGroup({
        from: new FormControl(""),
        to: new FormControl(""),
    })

    @Output() groupError = new EventEmitter<boolean>

    @Output()
    updateClock = new EventEmitter<boolean>

    @Output()
    delete = new EventEmitter<FormGroup>();

    timeRegex = /^([01][0-9]|2[0-3]):([0-5][0-9])$/


    deleteFromTo() {
        this.pFormGroup.get('from')?.setValue("00:00")
        this.pFormGroup.get('to')?.setValue("00:00")
        this.pFormGroup.setErrors(null);
        this.delete.emit(this.pFormGroup);
    }

    ngOnInit() {
        this.pFormGroup.get('from')?.setValidators([Validators.required, Validators.pattern(this.timeRegex)]);
        this.pFormGroup.get('to')?.setValidators([Validators.required, Validators.pattern(this.timeRegex)]);
        this.pFormGroup.setValidators([this.groupValidator()])
    }

    constructor(private eRef: ElementRef) {
    }

    updateTime($event: string, input: string) {
        this.pFormGroup.get(input)?.setValue($event);
        this.updateClock.emit(true);
    }


    groupValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            if (this.pFormGroup.get('from')?.errors || this.pFormGroup.get('to')?.errors) {
                this.groupError.emit(true)
            } else {
                this.groupError.emit(false)
            }
            return null
        }
    }

    getErrorMessage() {
        if (this.pFormGroup.get('from')?.hasError('pattern') || this.pFormGroup.get('to')?.hasError('pattern')) {
            return 'pattern'
        } else if (this.pFormGroup.get('from')?.hasError('required') || this.pFormGroup.get('to')?.hasError('required')) {
            return 'required'
        } else {
            return ''
        }

    }
    protected readonly ValidationMessages = ValidationMessages;

    onChange(control: any){
        let value = control.value
        if(!value.includes(":")){
            value = value.length == 1 ? "0" + value + ":00" : value
            value = value.length == 2 ? value + ":00" : value
            value = value.length == 3 ? [value.slice(0, 2), ":", value.slice(2)].join('') + "0" : value
            value = value.length == 4 ? [value.slice(0, 2), ":", value.slice(2)].join('') : value
        }
       return control.setValue(value);
    }

    protected readonly FormControl = FormControl;
}

