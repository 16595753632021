
export class RequestPassword{
    constructor(
        public email: string,
        public callbackUrl: string
    ) {}

    public static fromJson(json: any){
        return new RequestPassword(json.email, json.callbackUrl);
    }
}


export class PasswordReset{
    constructor(

    ) {
    }
}