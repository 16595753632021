import {Injectable} from "@angular/core";
import {Contact} from "./contact.type";
import {ContactDatasource, UpdateContactDTO} from "./contact.datasource";

@Injectable(
    {providedIn: 'root'}
)
export class ContactService {

    constructor(private contactDatasource: ContactDatasource) {
    }

    public getContact() {
        return this.contactDatasource.get()
    }

    public updateContact(contact: Contact) {
        console.log(contact);
        return this.contactDatasource.updateContact(contact);
    }
}
