<ng-container *ngIf="isNotLoading; else loading">
    <button *ngIf="buttonType === buttonTypes.raised" color="accent" (click)="clickEvent()" mat-raised-button>{{text}}</button>
    <button *ngIf="buttonType === buttonTypes.normal" color="accent" (click)="clickEvent()" mat-button>{{text}}</button>
    <div class="text-button" *ngIf="buttonType === buttonTypes.text" (click)="clickEvent()" >{{text}}</div>
</ng-container>

<ng-template #loading>
    <div class="spinner" [ngStyle]="{'width': width,}">
    <mat-spinner color="accent" diameter="{{height}}" ></mat-spinner>
    </div>
</ng-template>