<div *ngIf="addressFormGroup" class="address-group" [formGroup]="addressFormGroup">
    <div style="padding-bottom: 8px; font-size: 16px; font-weight: bold">Adresse</div>
    <div class="address-cluster" [class.compact]="compact">
        <mat-form-field [class.field-compact]="compact" color="accent">
            <mat-icon fontSet="material-icons-outlined" matPrefix>edit_road</mat-icon>
            <mat-label>Strasse</mat-label>
            <input maxlength="50" matInput formControlName="street">
            <mat-error>{{getErrorMessage("street")}}</mat-error>
        </mat-form-field>
        <mat-form-field [class.field-compact]="compact" [class.pl]="compact" [class.house-number]="compact"
                        color="accent">
            <mat-label>Nummer</mat-label>
            <mat-icon fontSet="material-icons-outlined" matPrefix>home</mat-icon>
            <input maxlength="10" matInput formControlName="houseNumber">
            <mat-error>{{addressFormGroup.controls["houseNumber"].hasError("required") ? "Erforderlich" : addressFormGroup.controls.houseNumber.getError("server") }}</mat-error>
        </mat-form-field>
    </div>
    <div class="address-cluster" [class.compact]="compact">
        <mat-form-field [class.field-compact]="compact" color="accent">
            <mat-label>Postleitzahl</mat-label>
            <mat-icon fontSet="material-icons-outlined" matPrefix>location_city</mat-icon>
            <input max="9999999999" type="number" matInput formControlName="postalCode">
            <mat-error *ngIf=" addressFormGroup.controls.postalCode.hasError('required') ">{{ValidationMessages.REQUIRED}}</mat-error>
            <mat-error *ngIf=" addressFormGroup.controls.postalCode.hasError('max')">{{ValidationMessages.MAX_LENGTH}}</mat-error>
            <mat-error *ngIf=" addressFormGroup.controls.postalCode.hasError('server')">{{ addressFormGroup.controls.postalCode.getError("server")}}</mat-error>
        </mat-form-field>
        <mat-form-field [class.field-compact]="compact" [class.pl]="compact" color="accent">
            <mat-label>Stadt</mat-label>
            <mat-icon fontSet="material-icons-outlined" matPrefix>location_city</mat-icon>
            <input matInput formControlName="city">
            <mat-error>{{getErrorMessage("city")}}</mat-error>
        </mat-form-field>
    </div>
</div>
