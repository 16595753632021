import {Allergen} from "../allergen/allergen.types";

export class MenuItem {
    constructor(
        public id: string,
        public name: string,
        public price: number,
        public allergens: Allergen[],
        public description?: string,
        public image?: string,
        public imageUrl?: string,
    ) {
    }

    static fromJson(json: any): MenuItem {
        return new MenuItem(
            json?.id ?? "",
            json?.name ?? "",
            json?.price ?? 0,
            json?.allergens?.map((allergen: any) => Allergen.fromJson(allergen)) ?? [],
            json?.description ?? "",
            json?.image ?? "",
            json?.imageUrl ?? "",
        )
    }
}
