import {Injectable} from "@angular/core";
import {VerifyMailDatasource} from "./verify-mail.datasource";




@Injectable()
export class VerifyMailService {

    constructor(private verifyMailDatasource: VerifyMailDatasource) {
    }

    verify(id: string){
        return this.verifyMailDatasource.verify(id);
    }
}