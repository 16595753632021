import {NgModule} from "@angular/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {DefaultCardModule} from "../../ui/default-card/default-card.module";
import {MatIconModule} from "@angular/material/icon";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {FlavPasswordFieldModule} from "../../ui/flav-password-field/flav-password-field.module";
import {VerifyMailComponent} from "./verify-mail.component";
import {VerifyMailService} from "./verify-mail.service";
import {VerifyMailDatasource} from "./verify-mail.datasource";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
    declarations: [
        VerifyMailComponent
    ],
    imports: [
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        CommonModule,
        DefaultCardModule,
        MatIconModule,
        MatSnackBarModule,
        FlavPasswordFieldModule,
        MatProgressSpinnerModule
    ],
    providers: [
        VerifyMailService,
        VerifyMailDatasource
    ]
})
export class VerifyMailModule {
}