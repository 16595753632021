import {Injectable} from "@angular/core";
import {RMAbstractDataSource} from "../../shared/datasource/rm-datasource.abstract";
import {Endpoint} from "../../endpoints.definition";
import {Contact} from "./contact.type";

export class UpdateAddressDTO{
    constructor(
        public id: string,
        public street: string,
        public houseNumber: string,
        public city: string,
        public countryId: string,
        public postalCode: string,
    ){}

    static fromJson(json: any):  UpdateAddressDTO{
        return new  UpdateAddressDTO(
            json.id,
            json.street,
            json.houseNumber,
            json.city,
            json.contactId,
            json.postalCode
        )
    }
}

export class UpdateContactDTO{
    constructor(
        public name: string,
        public address: UpdateAddressDTO,
        public contactNumber: string,
        public mail: string,
    ){}

    static fromJson(json: any): UpdateContactDTO{
        return new UpdateContactDTO(
            json.name,
            UpdateAddressDTO.fromJson(json.address),
            json.contactNumber,
            json.mail,
        )
    }
}



@Injectable()
export class ContactDatasource extends RMAbstractDataSource<Contact,  UpdateContactDTO> {
    readonly path = Endpoint.CONTACT;
    readonly serializer = Contact.fromJson;
    updateContact(contact: Contact){
        const value = contact.address;
        const addressDTO = new UpdateAddressDTO(
            value.id,
            value.street,
            value.houseNumber,
            value.city,
            value.country.id,
            value.postalCode,
        )
        const contactDTO = new UpdateContactDTO(
            contact.name,
            addressDTO,
            contact.contactNumber,
            contact.mail
        )
       return this.update(contactDTO);
    }

}