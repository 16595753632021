export class ValidationMessages{
  public static SUCCES: string = "Erfolgreich gespeichert!"
  public static FAILURE: string = "Fehler! Bitte die rot markierten Felder ausfüllen"
  public static HTTP_FAILURE: string = "Da ist etwas schief gelaufen. Bitte überprüfe deine Internetverbindung und versuche es erneut."
  public static LOGIN_FAILURE: string = "Falscher Benutzername oder Passwort"
  public static OK: string = "OK"
  public static PASSWORD_FAILURE: string = "Passwörter stimmen nicht überein"
  public static PASSWORD_INCOMPLETE: string = "Das Passwort muss 8 Zeichen mit Großbuchstaben, Kleinbuchstaben, Zahl, und Sonderzeichen beinhalten."
  public static PASSWORD_CHANGE_SUCCESS: string = "Dein Passwort wurde erfolgreich zurückgesetzt"
  public static WRONG_EMAIL: string= "Bitte eine gültige Email Adresse eingeben"
  public static EMAIL_IN_USE: string= "Email wird bereits verwendet"
  public static REQUIRED: string = "*Pflichtfeld"
  public static REGISTRATION_SUCCES = "Die Registrierung war erfolgreich"
  public static REGISTRATION_FAILED = "Der Service ist im moment nicht erreichbar. Verusch es später nochmal."
  public static WRONG_EMAIL_SHORT: string= "Email ungültig"
  public static WRONG_PHONE:string = "Telefonnummer ungültig"
  public static GOT_MAIL: string = "Schau in dein Postfach!"
  public static WRONG_TIME: string = "Falsches Zeitformat! Erfordert: HH:MM"
  public static WRONG_DATE_SHORT: string = "Erfordert: dd.mm.yyyy"
  public static WRONG_IMAGE: string = "Bitte ein Bild mit Dateityp ”png”,\"svg\" oder “jpg“ wählen"
  public static MAX_IMAGE_SIZE: string = "Das Bild ist zu groß. Maximale Größe beträgt 10MB"
  public static TENANT_EMAIL_NOT_FOUND: string = "Es wurde kein Benutzer mit dieser email Adresse gefunden"
  public static NO_INTERNET: string = "Registrierung fehlgeschlagen. Überprüfe deine Internetverbindung"
  public static WRONG_NAME: string = "Kein gültiger Name"
  public static WRONG_ADDRESS: string = "Keine gültige Adresse"
  public static DESCRIPTION_MAX_LENGTH: string = "Beschreibungstext ist zu lang"
  public static NO_SPECIAL_CHARACTER: string = "Sonderzeichen (außer '-'), Umlaute und 'ß' sind nicht erlaubt "
  public static IMAGE_NOT_UPLOADED:string = "Oops, das Bild konnte nicht hochgeldaen werdden"
  public static DUPLICATE_TITLE: string = "Doppelter Titel"
  public static DUPLICATE_DATE: string = "Doppeltes Datum"
  public static MAX_LENGTH: string = "Maximale Zeichenanzahl überschritten"
  public static COLOR_DOESNT_EXIST: string = "Diese Farbe existiert nicht"
}