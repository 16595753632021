<h1 mat-dialog-title>Kategorie löschen</h1>
<div mat-dialog-content>
    <p>Soll die Kategorie <b>{{category.name}}</b> wirklich gelöscht werden?</p>.
    <p> <b>ACHTUNG:</b> Alle Gerichte in {{category.name}} werden ebenfalls gelöscht. </p>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="dialogRef.close(false)">Abbrechen</button>
    <button mat-raised-button (click)="deleteCategory()" [disabled]="deleting">
        <span *ngIf="!deleting">Löschen</span>
        <mat-spinner *ngIf="deleting" diameter="24" mode="indeterminate"></mat-spinner>
    </button>
</div>
