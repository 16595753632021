<mat-sidenav-container hasBackdrop="false">
    <mat-sidenav mode="over" position="end" class="sidenav-container" #drawer>
        <div [formGroup]="formGroup" style="padding: 32px">
            <div style="margin-bottom: 32px;">
                <h2>Speise <span *ngIf="isCreate">erstellen</span> <span *ngIf="!isCreate">bearbeiten</span></h2>
                <div [ngStyle]="imageError ? {border: '1px solid red', borderRadius: '10px'} : {} " >
                <flav-image *ngIf="!isLoading; else loading" [src]="getMenuItemImage()" height="100%" width="100%" aspectRatio="16 / 9" objectFit="cover" (click)="menuItemImageUpload.click()"></flav-image>
                <input type="file" (change)="onMenuItemImageSelected($event)" #menuItemImageUpload style="display: none">
                </div>
                <div *ngIf="imageError" style="color: red; font-size: 12px">{{imageErrorMessage}} </div>
            </div>

            <div style="display: flex; flex-direction: column; justify-content: space-between">
                <div style="display: flex; flex-direction: column">
                    <mat-form-field color="accent">
                        <mat-label>Name</mat-label>
                        <input maxlength="50" matInput formControlName="name" placeholder="Name">
                        <mat-error *ngIf="!formGroup.controls['price'].valid">Bitte den Namen des Gerichts angeben</mat-error>

                    </mat-form-field>

                    <mat-form-field color="accent">
                        <mat-label>Beschreibung</mat-label>
                        <textarea maxlength="250" matInput rows="7" formControlName="description" placeholder="Beschreibung"></textarea>
                    </mat-form-field>

                    <mat-form-field color="accent">
                        <mat-label>Allergene</mat-label>
                        <mat-select multiple formControlName="allergenIds">
                            <mat-option
                                    *ngFor="let allergen of allergenService.getAllergene() | async"
                                    [value]="allergen.id"
                            >
                                {{allergen.identification}} - {{allergen.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <price-field  [priceFormGroup]="formGroup"  ></price-field>
                </div>
                <div style="display: flex; flex-direction: row; justify-content: flex-end; padding-bottom: 48px">
                    <button mat-button color="accent" (click)="abort()">ABBRECHEN</button>
                    <button [disabled]="disableSaveButton()" mat-raised-button color="accent" (click)="save()">SPEICHERN</button>
                </div>
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <ng-content></ng-content>

    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #loading>
    <div style="height: 270px; display: flex; flex-direction: row; justify-content: center; align-items: center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>