<div class="contact-wrapper">
    <default-card>
        <div *ngIf="contact$ | async; let contact" style="width: 392px">
            <div class="contact-group" [formGroup]="contactFormGroup">
                <h2>Kontaktangaben</h2>
                <mat-form-field color="accent">
                    <mat-label> Restaurant-Name</mat-label>
                    <mat-icon matPrefix>restaurant</mat-icon>
                    <input maxlength="40" matInput formControlName="name">
                </mat-form-field>
                <mat-form-field color="accent">
                    <mat-label>Telefon</mat-label>
                    <mat-icon fontSet="material-icons-outlined" matPrefix>phone</mat-icon>
                    <input maxlength="20" matInput formControlName="contactNumber">
                    <mat-error
                            *ngIf="contactFormGroup.get('contactNumber')?.hasError('pattern')">{{ValidationMessages.WRONG_PHONE}}</mat-error>
                </mat-form-field>
                <mat-form-field color="accent">
                    <mat-label>Mail</mat-label>
                    <mat-icon matPrefix>alternate_email</mat-icon>
                    <input maxlength="50" matInput formControlName="mail">
                    <mat-error
                            *ngIf="contactFormGroup.get('mail')?.hasError('email')">{{ValidationMessages.WRONG_EMAIL}}</mat-error>
                </mat-form-field>


                <flav-address [formGroup]="addressFormGroup"></flav-address>

            </div>
        </div>
        <div style="display: flex; flex-direction: row; justify-content: end;">
            <button color="accent" mat-button style="font-weight: bold; letter-spacing: 1.25px; margin-right: 16px"
                    (click)="abort()">ABBRECHEN
            </button>
            <button [disabled]="disableSaveButton()" color="accent" mat-raised-button style="font-weight: bold; letter-spacing: 1.25px;"
                    (click)="onSubmitContact()">SPEICHERN
            </button>
        </div>
    </default-card>
</div>
