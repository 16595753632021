<div class="pc-wrapper">
    <img style="width: 300px;height: 200px; object-fit: cover; padding-top: 64px" src="../../assets/OrangeRegular.svg"/>
    <default-card>
        <div style="display: flex; flex-direction: column" [formGroup]="passwordFormGroup">
            <h1 style="font-weight: bold">Passwort wählen</h1>
           <flav-password-field [passwordFormGroup]="passwordFormGroup" fieldWidth="350px"></flav-password-field>
            <div class="pc-buttons">
                <button mat-button color="accent" style="margin-right: 8px" (click)="abort()">Abbrechen</button>
                <button mat-raised-button color="accent" (click)="changePassword()">Passwort zurücksetzen</button>
            </div>
        </div>
    </default-card>
</div>