import {Country} from "../contact/contact.type";

export class Address {
    constructor(
        public id: string,
        public street: string,
        public houseNumber: string,
        public city: string,
        public country: Country,
        public postalCode: string,
    ) {
    }

    static fromJson(json: any,): Address {
        return new Address(
            json.id,
            json.street,
            json.houseNumber,
            json.city,
            Country.fromJson(json.country),
            json.postalCode
        )
    }
}
