import {FlavoroHttpClient} from "../../shared/http/http.client";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Endpoint} from "../../endpoints.definition";

@Injectable({
    providedIn: 'root'
})
export class DomainService {
    constructor(private _http: FlavoroHttpClient) {
    }

    getDomain(): Observable<string> {
        return this._http.getAsPlainText(Endpoint.GET_DOMAIN);
    }
}
