import {Category} from "./category.types";
import {CRUDWithReorderDatasource} from "../../../shared/datasource/crud-reorder-datasource.abstract";
import {MenuItem} from "../menu-item/menu-item.types";
import {Endpoint} from "../../../endpoints.definition";

export class CreateCategoryDTO {
    constructor(
        public name: string,
        public description?: string,
    ) {
    }
}

export class UpdateCategoryDTO {
    constructor(
        public id: string,
        public name: string,
        public description?: string) {
    }
}

export class CategoryDatasource extends CRUDWithReorderDatasource<Category, CreateCategoryDTO, UpdateCategoryDTO> {
    readonly path = Endpoint.CATEGORY;
    readonly serializer = Category.fromJson;

    reorderMenuItems(categoryId: string, items: MenuItem[]) {
        return this.reorder("menu-items", items.map(item => item.id), categoryId)
    }
}
