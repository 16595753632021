
import {IrregularOpeningTimes, RegularOpeningTimes, SingleIrregularOpeningTime} from "./opening-times.types";
import {Endpoint} from "../../endpoints.definition";
import {RMAbstractDataSource} from "../../shared/datasource/rm-datasource.abstract";
import {Injectable} from "@angular/core";




@Injectable()
export class RegularOpeningTimesDatasource extends RMAbstractDataSource<RegularOpeningTimes, RegularOpeningTimes> {
    readonly path = Endpoint.OPENING_TIMES_REGULAR;
    readonly serializer = RegularOpeningTimes.fromJson;
}

@Injectable()
export class IrregularOpeningTimesDatasource extends RMAbstractDataSource<IrregularOpeningTimes, SingleIrregularOpeningTime[]> {
    readonly path = Endpoint.OPENING_TIMES_IRREGULAR;
    readonly serializer = IrregularOpeningTimes.fromJson;
}