import {inject, NgModule} from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateFn, CanDeactivateFn, Router,
    RouterModule,
    RouterStateSnapshot,
    Routes
} from '@angular/router';
import {MenuComponent} from "../features/menu/menu.component";
import {OpeningTimesComponent} from "../features/opening-times/opening-times.component";
import {ContactComponent} from "../features/contact/contact.component";
import {GestaltungComponent} from "../features/gestaltung/gestaltung.component";
import {BasisdatenComponent} from "../features/basisdaten/basisdaten.component";
import {LoginComponent} from "../features/login/login.component";
import {TokenStorageService} from "../features/auth/tokenstorage.service";
import {PasswordResetComponent} from "../features/password-reset/password-reset.component";
import {RegistrationComponent} from "../features/registration/registration.component";
import {
    PasswordConfiguratorComponent
} from "../features/password-reset/password-configurator/password-configurator.component";
import {RestaurantSetupPage} from "../features/restaurant/setup/setup.page";
import {VerifyMailComponent} from "../features/registration/verify-mail/verify-mail.component";
import {TenantSetupPage} from "../features/registration/setup-tenant/setup.page";
import {HomeComponent} from "../features/Routes/home/home.component";
import {WelcomeComponent} from "../features/Routes/welcome/welcome.component";
import {AppComponent} from "./app.component";
import {SubscribeComponent} from "../features/subscribe/subscribe.component";
import {PageNotFoundComponent} from "../features/404page/page-not-found.component";
import {FormGroupComponentInterface} from "../features/ui/CanDeactivateGuard/formgroupcomponent.interface";

const loginGuard: CanActivateFn = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) => {

    const authService = inject(TokenStorageService);
    const router = inject(Router);
    if (!authService.getToken()) {
        return router.parseUrl("/login");
    }
    return true;
}

const adminLoginGuard: CanActivateFn = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) => {

    const authService = inject(TokenStorageService);
    const router = inject(Router);
    const route = inject(ActivatedRoute);
    let impersonateToken = state.root.queryParams['impersonateToken'];
    if (impersonateToken) {
        authService.signOut();
        authService.saveToken(impersonateToken);
    }
    return true;
}

const canDeactivateGuard: CanDeactivateFn<FormGroupComponentInterface> = (
    component: FormGroupComponentInterface,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot) => {
    if (component.getFormGroups().some((formGroup) => formGroup?.dirty)) {
        return window.confirm("Du hast ungespeicherte Änderungen. Willst du die Seite wirklich verlassen?")
    }
    return true;
}

const routes: Routes = [
    {
        path: '',
        component: AppComponent,
        redirectTo: 'speisekarte',
        pathMatch: 'full',
    },
    {
        path: '',
        component: HomeComponent,
        children: [
            {
                path: 'speisekarte',
                component: MenuComponent,
                canActivate: [adminLoginGuard, loginGuard],
            },
            {
                path: 'offnungszeiten',
                component: OpeningTimesComponent,
                canActivate: [adminLoginGuard, loginGuard],
                canDeactivate: [canDeactivateGuard]
            },
            {
                path: 'kontakt',
                component: ContactComponent,
                canActivate: [adminLoginGuard, loginGuard],
                canDeactivate: [canDeactivateGuard]
            },
            {
                path: 'gestaltung',
                component: GestaltungComponent,
                canActivate: [adminLoginGuard, loginGuard],
                canDeactivate: [canDeactivateGuard]
            },
            {
                path: 'basisdaten',
                component: BasisdatenComponent,
                canActivate: [adminLoginGuard, loginGuard],
                canDeactivate: [canDeactivateGuard]
            },


        ]
    },
    {
        path: '',
        component: WelcomeComponent,
        children: [

            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'password-reset',
                component: PasswordResetComponent
            },
            {
                path: 'resetPassword',
                component: PasswordConfiguratorComponent
            },
            {
                path: 'verify-mail',
                component: VerifyMailComponent
            },
            {
                path: 'tenant-setup',
                component: TenantSetupPage
            },
            {
                path: 'subscribe',
                component: SubscribeComponent
            },
            {
                path: 'setup',
                component: RestaurantSetupPage,
                canActivate: [adminLoginGuard, loginGuard]
            },
        ]
    },
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
