import {Injectable} from "@angular/core";
import {CreateMenuItemDTO, MenuItemDatasource, UpdateMenuItemDTO} from "./menu-item.datasource";

@Injectable({
    providedIn: 'root',
})
export class MenuItemService {

    constructor(private menuItemDatasource: MenuItemDatasource) {
    }

    createMenuItem(menuItem: CreateMenuItemDTO) {
        return this.menuItemDatasource.create(menuItem);
    }

    updateMenuItem(menuItem: UpdateMenuItemDTO) {
        return this.menuItemDatasource.update(menuItem, menuItem.id);
    }

    deleteMenuItem(id: string) {
        return this.menuItemDatasource.delete(id);
    }
}
