<div style="display: flex; flex-direction: row; justify-content: center; align-items: center; height: 100vh">
    <default-card>
        <ng-container *ngIf="notLoading; else loading">
        <ng-container *ngIf="verifyMail$ | async; else error">
        <div style="display: flex; flex-direction: column; font-size: 24px;">
        <div  style="padding-bottom: 16px; max-width: 280px; text-align: center; line-height: 1.1">Deine Mailadresse wurde erfolgreich verifiziert!</div>
        <button mat-raised-button color="accent" (click)="toLogin()">Zum Login</button>
        </div>
        </ng-container>
        </ng-container>
    </default-card>
</div>

<ng-template #error>
    <div style="display: flex; flex-direction: column; font-size: 24px;">
        <div  style="padding-bottom: 16px; max-width: 280px; text-align: center; line-height: 1.1">Ooops deine Mailadresse konnte nicht verifiziert werden!</div>
        <button mat-raised-button color="accent" (click)="toLogin()">Zum Login</button>
    </div>
</ng-template>


<ng-template #loading>
    <mat-spinner></mat-spinner>
</ng-template>