import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

interface IScript {
    /** The source url of the script */ src: string;
    /**
     * The loading state of the script. Is undefined if the script is currently being loaded
     */
    loadSuccess?: boolean;
}

/**
 * Singleton service that can be used to load scripts on demand
 */
@Injectable({providedIn: 'root'})
export class ScriptLoaderService {
    /**
     * Internal stream that holds information about the current loading state of the requested scripts
     * @private
     */
    private _scripts$ = new BehaviorSubject<IScript[]>([]);

    constructor(@Inject(DOCUMENT) private _document: Document) {
    }

    /**
     *
     * @param src The source url of the script to be loaded
     * @returns {Observable<boolean>} A stream that emits a boolean indicating whether the script has been loaded successfully
     */
    loadScript(src: string): Observable<boolean> {
        const current = this._scripts$.value;
        if (current.some((s) => s.src === src)) {
            return of(true);
        }
        this._updateScripts({src});
        const scriptTag = this._document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.src = src;
        scriptTag.async = true;
        scriptTag.onload = () => {
            this._updateScripts({src, loadSuccess: true});
        };
        scriptTag.onerror = () => {
            this._updateScripts({src, loadSuccess: false});
        };
        this._document.getElementsByTagName('head')[0].appendChild(scriptTag);
        return this.scriptLoaded(src);
    }

    /**
     *
     * @param src The source url of the script
     * @returns {Observable<boolean>} A stream that emits a boolean indicating whether the script has been loaded successfully
     */
    scriptLoaded(src: string): Observable<boolean> {
        return this._scripts$.pipe(
            map((scripts: IScript[]) => {
                return scripts.find(
                    (script: IScript) => script.src === src && typeof script.loadSuccess === 'boolean')
            }),
            map<IScript | undefined, boolean>(script => {
                console.log(script)
                if (!script || script === undefined) {
                    return false
                } else {
                    return !!script.loadSuccess;
                }
            })
        );
    }

    /**
     * Updates the state of the script
     * @param script The script to be updated in the state
     * @private
     */
    private _updateScripts(script: IScript): void {
        const currentScripts = this._scripts$.value.filter(
            (s) => s.src != script.src
        );
        this._scripts$.next([...currentScripts, script]);
    }

    removeScript(src: string): void {
        const currentScripts = this._scripts$.value.filter(
            (s) => s.src != src
        );
        this._removeScriptFromDom(src);
        this._scripts$.next([...currentScripts]);
    }

    private _removeScriptFromDom(src: string): void {
        const scripts = this._document.getElementsByTagName('head')[0].getElementsByTagName('script');
        for (let i = 0; i < scripts.length; i++) {
            if (scripts[i].src == src) {
                scripts[i].remove();
            }
        }
    }
}
