import {Component} from "@angular/core";
import {Router} from "@angular/router";

@Component(
    {
        selector: 'subscribe',
        templateUrl: './subscribe.component.html',
        styleUrls: ['./subscribe.component.scss']
    }
)
export class SubscribeComponent {
    constructor(private router: Router) {
    }
    toWix(){
        window.open("https://www.flavoro.de/preise", '_blank');
        this.router.navigate(["/login"])
    }
}