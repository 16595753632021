import {AbstractDatasource} from "./datasource.abstract";
import {appendPath} from "../util/path.helper";
import {map, Observable} from "rxjs";
import {Injectable} from "@angular/core";


//RM = RetrieveModify
@Injectable()
export abstract class RMAbstractDataSource<T, E> extends AbstractDatasource<T>{

    get(){
        return this.http.get<T>(this.path).pipe(map(this.serializer));
    }

    update(editDTO: E): Observable<T> {
        return this.http.put<T>(this.path, editDTO);
    }

}