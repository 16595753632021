import {NgModule} from "@angular/core";
import {StylesDatasource} from "./styles.datasource";
import {StylesService} from "./styles.service";
import {StylesComponent} from "./styles.component";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {ColorFormsModule} from "../color-forms/color-forms.module";
import {DefaultCardModule} from "../../ui/default-card/default-card.module";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {AsyncPipe, NgForOf, NgIf, NgStyle} from "@angular/common";
import {FlavInputModule} from "../../ui/flav-input/flav-input.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FlavoroUiModule} from "../../ui/flavoro-ui.module";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {TourAnchorMatMenuDirective} from "ngx-ui-tour-md-menu";

@NgModule({
    declarations: [
        StylesComponent,
    ],
    imports: [
        FlavInputModule,
        MatButtonModule,
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        ColorFormsModule,
        DefaultCardModule,
        ReactiveFormsModule,
        AsyncPipe,
        NgForOf,
        FlavoroUiModule,
        NgStyle,
        MatProgressSpinnerModule,
        TourAnchorMatMenuDirective
    ],
    exports: [
        StylesComponent
    ],
    providers: [
        StylesDatasource,
        StylesService
    ]
})
export class StylesModule {
}