import {NgModule} from "@angular/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {DefaultCardModule} from "../ui/default-card/default-card.module";
import {MatIconModule} from "@angular/material/icon";
import {RegistrationComponent} from "./registration.component";
import {RegistrationService} from "./registration.service";
import {RegistrationDatasource, SendVerificationMailDatasource} from "./registration.datasource";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {CountryModule} from "../country/country.module";
import {FlavPasswordFieldModule} from "../ui/flav-password-field/flav-password-field.module";
import AddressModule from "../address/address.module";
import {LoadingButtonModule} from "../ui/loading-button/loading-button.module";
import {TenantSetupPage} from "./setup-tenant/setup.page";

@NgModule({
    declarations: [
        RegistrationComponent,
        TenantSetupPage
    ],
    imports: [
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        CommonModule,
        DefaultCardModule,
        MatIconModule,
        MatSnackBarModule,
        CountryModule,
        FlavPasswordFieldModule,
        AddressModule,
        LoadingButtonModule,
    ],
    providers: [
        RegistrationService,
        RegistrationDatasource,
        SendVerificationMailDatasource
    ]
})
export class RegistrationModule {
}
