import {NgModule} from '@angular/core';
import {MatIconModule} from "@angular/material/icon";

import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {ContactComponent} from "./contact.component";
import {ContactDatasource} from "./contact.datasource";
import {DefaultCardModule} from "../ui/default-card/default-card.module";
import AddressModule from "../address/address.module";


@NgModule({
    declarations: [
        ContactComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        ReactiveFormsModule,
        DefaultCardModule,
        AddressModule,
    ],
    providers: [
        ContactDatasource
    ]
})
export class ContactModule {
}
