import {Component, EventEmitter, Input, Output} from "@angular/core";


export enum ButtonTypes {
    raised,normal, text
}
@Component({
    selector: 'loading-button',
    templateUrl: './loading-button.component.html',
    styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent{
    buttonTypes = ButtonTypes;
    @Input() text!: string;
    @Input() isNotLoading!: boolean;
    @Input() width!: string;
    @Input() height:string = "35";
    @Input() buttonType: ButtonTypes = ButtonTypes.raised;
    @Output() bClick: EventEmitter<any> = new EventEmitter();


    constructor() {
    }

    clickEvent(){
        this.bClick.emit();
    }
}