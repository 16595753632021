import {AbstractControl, ValidationErrors} from "@angular/forms";
import {toDoc} from "ngx-editor";

export function editorLengthValidator(){
    return (control: AbstractControl): ValidationErrors | null => {
        let maxLength = 627;
        let actualLength = 0;
        toDoc(control.value)["content"].map((content: any)=>{
            if(content.type == "paragraph"){
                maxLength = maxLength - 35;
            }
            content?.content?.map((text: any) => {
                    actualLength = actualLength + text.text?.length
            })
        })
        if(actualLength >= maxLength){
            return {size: true}
        }
        return null
    }
}