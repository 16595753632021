import {Address} from "../address/address.type";

export class Country {
    constructor(
        public id: string,
        public name: string,
        public code: string,
    ) {
    }

    static fromJson(json: any): Country {
        return new Country(
            json.id,
            json.name,
            json.code,
        )
    }
}


export class Contact {
    constructor(
        public name: string,
        public address: Address,
        public contactNumber: string,
        public mail: string,
    ) {
    }

    static fromJson(json: any): Contact {
        return new Contact(
            json.name,
            Address.fromJson(json.address),
            json.contactNumber,
            json.mail,
        )
    }
}
