import {Injectable} from "@angular/core";
import {READDatasource} from "../../../shared/datasource/read-datasource.abstract";
import {Endpoint} from "../../../endpoints.definition";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";




@Injectable()
export class VerifyMailDatasource{
    constructor(private http: HttpClient) {}
    verify(id: string){
        return this.http.get(environment.apiUrl + Endpoint.VERIFY_EMAIL + id, {responseType: 'text' as 'json'})
    }
}