import {Injectable} from '@angular/core';
import {
    HttpClient,
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpHeaders,
    HttpParams
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";

export interface IRequestOptions {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any;
}

@Injectable(
    {providedIn: 'root'}
)
export class FlavoroHttpClient {
    readonly apiUrl: string;
    private _restaurantId: number | undefined;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = environment.apiUrl;
    }

    setRestaurantId(restaurantId: number) {
        this._restaurantId = restaurantId;
    }

    get<T>(url: string, options?: IRequestOptions): Observable<T> {
        return this.httpClient.get<T>(this.getFullUrl(url), this.getOptions(options));
    }

    getAsPlainText(url: string, options?: IRequestOptions): Observable<string> {
        return this.httpClient.get(this.getFullUrl(url), {...this.getOptions(options), responseType: 'text'});
    }

    post<T>(url: string, body: any, options?: IRequestOptions): Observable<T> {
        return this.httpClient.post<T>(this.getFullUrl(url), body, this.getOptions(options));
    }

    put<T>(url: string, body: any, options?: IRequestOptions): Observable<T> {
        return this.httpClient.put<T>(this.getFullUrl(url), body, this.getOptions(options));
    }

    putAsPlainText(url: string, body: any, options?: IRequestOptions): Observable<string> {
        return this.httpClient.put(this.getFullUrl(url), body, {...this.getOptions(options), responseType: 'text'});
    }

    delete<T>(url: string, options?: IRequestOptions): Observable<T> {
        return this.httpClient.delete<T>(this.getFullUrl(url), this.getOptions(options));
    }

    patch<T>(url: string, body: any, options?: IRequestOptions): Observable<T> {
        return this.httpClient.patch<T>(this.getFullUrl(url), body, this.getOptions(options));
    }

    head<T>(url: string, options?: IRequestOptions): Observable<T> {
        return this.httpClient.head<T>(this.getFullUrl(url), this.getOptions(options));
    }

    options<T>(url: string, options?: IRequestOptions): Observable<T> {
        return this.httpClient.options<T>(this.getFullUrl(url), this.getOptions(options));
    }

    private getFullUrl(url: string): string {
        let transformedUrl = url;
        if (!url.startsWith('http')) {
            if (!url.startsWith('/')) {
                transformedUrl = '/' + url;
            }
            transformedUrl = this.apiUrl + transformedUrl;
        }
        return transformedUrl;
    }

    private getOptions(options?: IRequestOptions): IRequestOptions | undefined {
        return options;
    }
}

