import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {Router} from "@angular/router";

@Injectable()
export class SetupInterceptor implements HttpInterceptor {


    constructor(private router: Router) {
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError((error) => {
            if (error instanceof HttpErrorResponse && error.status === 452) {
                this.router.navigate(["/setup"])
            }
            return throwError(error);
        }));
    }

}
