<div class="panel">
    <div class="panel-header">
        <ng-content select="[heading]"></ng-content>
    </div>
    <div class="panel-content scroll-shadows">
        <ng-content></ng-content>
    </div>
    <div class="panel-actions-area">
        <div class="panel-actions-with-divider">
            <mat-divider></mat-divider>
            <div class="panel-actions">
                <ng-content select="[actions]"></ng-content>
            </div>
        </div>
    </div>
</div>
