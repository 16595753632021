<div style="background-color: #E9F2EB; border-radius: 10px; width: 100%; height: 161px; display: flex; flex-direction: row">
    <div (click)="uploadItem()">
        <flav-image [src]="item?.imageUrl" width="286px" height="161px" borderRadius="10px" objectFit="cover" ></flav-image>
    </div>

    <div style="display: flex; flex-direction: column; width: 100%; justify-content: space-between">
        <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: baseline">
            <h4 style="font-weight: bold; padding-left: 10px; padding-top: 10px; margin-bottom: 0">{{item?.name}}</h4>
            <mat-icon cdkDragHandle>
                drag_indicator
            </mat-icon>
        </div>

        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; padding: 10px; max-height: 64px;">
            <div >
                <span class="description">{{item?.description}}</span>
            </div>
            <div class="allergenes">
                <h5 style="margin-bottom: 0; font-weight: bold">Allergene:</h5>
                <span *ngIf="item?.allergens?.length == 0">Keine</span>
                <div>
                <div class="allergen" *ngFor="let allergen of item?.allergens">{{allergen.identification}}</div>
                </div>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: center; height: 100%">
                <span style="font-weight: bold">{{getitemPrice(item)}}€</span>
            </div>
        </div>
        <div style="display: flex; flex-direction: row; justify-content: flex-end;">
            <button mat-icon-button (click)="toggleEditMode()">
                <mat-icon style="font-size: 16px;line-height: 1.3">edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteItem()">
                <mat-icon style="font-size: 16px; line-height: 1.3">delete</mat-icon>
            </button>
        </div>
    </div>
</div>
