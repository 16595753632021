import {Injectable} from "@angular/core";
import {FlavoroHttpClient} from "../../shared/http/http.client";
import {Observable} from "rxjs";
import {TenantInfo} from "./tenant-info.types";

@Injectable(
    {providedIn: 'root'}
)
export class TenantInfoService {
    path = "/cc/tenants";

    constructor(private _http: FlavoroHttpClient) {
    }

    getTenantInfo(): Observable<TenantInfo> {
        return this._http.get(this.path);
    }

    getTenantRestoreId(): Observable<string | undefined> {
        return this._http.getAsPlainText(this.path + "/restore-id");
    }

    setTenantRestoreId(restoreId: string): Observable<string> {
        return this._http.putAsPlainText(this.path + "/restore-id", restoreId);
    }
}
