import {Component, Input} from "@angular/core";

@Component({
    selector: 'flav-image',
    templateUrl: './flav-image.component.html',
    styleUrls: ['./flav-image.component.scss']
})
export class FlavImageComponent {
    constructor() {
    }

    @Input()
    src?: string | undefined;

    @Input()
    logo?: boolean = false;

    @Input()
    aspectRatio?: string;

    @Input()
    objectFit?: string = "cover";

    @Input()
    width?: string = "100%"

    @Input()
    height?: string = "100%";

    @Input()
    borderRadius?: string = "10px"

    getSrc() {
        if(!this.src || this.src === ""){
            if(this.logo){
                return "/assets/logoPlaceholder.png";
            }
            return "/assets/placeholder-image.jpeg";
        }
        return this.src;
    }
}
