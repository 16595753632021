import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component(
    {
        selector: 'flav-fab-button',
        templateUrl: './flav-fab-button.component.html',
        styleUrls: ['./flav-fab-button.component.scss']

    }
)

export class FlavFabButtonComponent{
    @Input() iconName!: string
    @Input() disabled!: boolean
    @Output() clickedFlavButton = new EventEmitter

    clickOnFlavButton(){
        this.clickedFlavButton.emit()
    }
}