import {Component, ElementRef, EventEmitter, Output, ViewChild} from "@angular/core";
import {MatSidenav} from "@angular/material/sidenav";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {AllergenService} from "../../allergen/allergen.service";
import {MenuItem} from "../menu-item.types";
import {MenuItemService} from "../menu-item.service";
import {MediaService, MediaType} from "../../../media/media.service";
import {UpdateMenuItemDTO} from "../menu-item.datasource";
import {priceFormatValidator} from "./price-field/price-field.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ValidationMessages} from "../../../../shared/Validation/validation.messages";
import {firstValueFrom} from "rxjs";

@Component({
    selector: 'edit-sidenav',
    templateUrl: './edit-sidenav.component.html',
    styleUrls: ['./edit-sidenav.component.scss']
})
export class EditSidenavComponent {

    @ViewChild("drawer") drawer?: MatSidenav;

    @ViewChild("scrollContainer") scrollContainer?: ElementRef;

    @Output()
    itemChanged = new EventEmitter<boolean>();

    isCreate: boolean = false;

    currentCategory: string = "";

    currentItem: MenuItem | undefined;

    menuItemImage?: string = "";

    menuItemImageId? = "";

    imageError = false;

    imageErrorMessage = "";

    isLoading = false;

   waitingForResponse = false;

    formGroup: FormGroup = new FormGroup<any>({
        name: new FormControl(""),
        price: new FormControl(""),
        description: new FormControl(""),
        allergenIds: new FormControl([]),
    });


    constructor(public allergenService: AllergenService, private menuItemService: MenuItemService, private mediaService: MediaService, private snackbar: MatSnackBar) {
    }


    public toggle() {
        this.drawer?.toggle();
    }

    buildForm(categoryId: string, menuItem?: MenuItem) {
        this.formGroup = new FormGroup<any>({
            name: new FormControl(menuItem?.name ?? "", [Validators.required]),
            price: new FormControl(menuItem?.price ?? "", Validators.compose([Validators.required, priceFormatValidator()])),
            description: new FormControl(menuItem?.description ?? ""),
            allergenIds: new FormControl(menuItem?.allergens.map(allergen => allergen.id) ?? []),
        });
    }

    public startEdit(menuItem: MenuItem, categoryId: string) {
        this.isCreate = false;
        this.currentItem = menuItem;
        this.currentCategory = categoryId;
        console.log(menuItem)
        this.menuItemImage = menuItem.imageUrl;
        this.buildForm(categoryId, menuItem);
        this.drawer?.open()
    }

    public startCreate(categoryId: string) {
        this.isCreate = true;
        this.currentCategory = categoryId;
        this.menuItemImage = undefined;
        this.menuItemImageId = undefined;
        this.buildForm(categoryId);
        this.drawer?.open()
    }

    abort() {
        this.drawer?.close();
    }

    save() {
        for (const key in this.formGroup.controls) {
            //To trigger validation
            this.formGroup.controls[key].markAsTouched();
        }
        if(!this.formGroup.valid) {
            this.snackbar.open(ValidationMessages.FAILURE, "OK", {duration: 5000});
            return;
        }

        let price: string = this.formGroup.get("price")?.value;
        console.log(price)
        //TODO: Uniformize Create and Update DTOs
        let newMenuItem: UpdateMenuItemDTO = {
            id: this.isCreate ? undefined : this.currentItem?.id ?? undefined,
            orderNumber: 0,
            imageId: this.menuItemImageId,
            categoryId: this.currentCategory,
            ...this.formGroup.value,
            price: price
        }
        this.waitingForResponse = true;
        if (this.isCreate) {
            this.menuItemService.createMenuItem(newMenuItem).subscribe(() => {
                this.drawer?.close();
                this.itemChanged.emit(this.isCreate);
                this.waitingForResponse = false;
            },(error) => {
                this.snackbar.open(ValidationMessages.HTTP_FAILURE, "OK", {duration: 5000});
                this.waitingForResponse = false;
            });
        } else {
            this.menuItemService.updateMenuItem(newMenuItem).subscribe(() => {
                this.drawer?.close();
                this.itemChanged.emit(this.isCreate);
                this.waitingForResponse = false;
            },(error) => {
                this.snackbar.open(ValidationMessages.HTTP_FAILURE, "OK", {duration: 5000});
                this.waitingForResponse = false;
            });
        }
        this.snackbar.open(ValidationMessages.SUCCES, ValidationMessages.OK, {duration: 5000, panelClass: ['success-snackbar']})
    }

    getMenuItemImage() {
        return this.menuItemImage;
    }

    async onMenuItemImageSelected($event: Event) {
        const file: File = ($event?.target as any)?.files[0];
        if(!file) return
        let imageValid = true;
        if (!(file?.type == "image/jpeg" || file?.type == "image/png" || file?.type == "image/svg+xml")) {
            imageValid = false;
            this.imageError = true
            this.menuItemImage = "/assets/placeholder-image.jpeg";
            this.imageErrorMessage = "Bitte ein Bild mit Dateityp ”png”,\"svg\" oder “jpg“ wählen"

        }
        if (!(file?.size <= 10000000)) {
            imageValid = false;
            this.imageError = true
            this.menuItemImage = "/assets/placeholder-image.jpeg";
            this.imageErrorMessage = "Das Bild ist zu groß. Maximale Größe beträgt 10MB"
        }


        if (imageValid) {
            this.isLoading = true;
            this.imageError = false
            try {
                const uploadedImage = await firstValueFrom(this.mediaService.uploadImage(file, MediaType.MENU_ITEM))
                this.menuItemImage = uploadedImage.path;
                this.menuItemImageId = uploadedImage.imageId;
                this.isLoading = false
            } catch (err) {
                this.snackbar.open(ValidationMessages.IMAGE_NOT_UPLOADED, ValidationMessages.OK);
                this.isLoading = false
            }
        }
    }

    disableSaveButton(){
        return this.waitingForResponse;
    }

}




