import {Category} from "./category/category.types";

export class Menu {
    constructor(
        public id: string,
        public categories: Category[],
    ) {
    }

    static fromJson(json: any): Menu {
        return new Menu(
            json.id,
            json.categories?.map((category: any) => Category.fromJson(category)) ?? [],
        )
    }
}
