import {Injectable} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, distinct, map, shareReplay, tap} from "rxjs";
import {TokenStorageService} from "./tokenstorage.service";
import {FlavoroHttpClient} from "../../shared/http/http.client";
import {Endpoint} from "../../endpoints.definition";


const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    serviceId: number = 0;
    static idCounter: number = 0;

    authenticated$ = this.tokenStorageService.currentToken$.pipe(
        map(token => {
            return !!token
        }), tap(authenticated => {
            console.log("in auth", authenticated)
        }));

    constructor(private http: FlavoroHttpClient, private tokenStorageService: TokenStorageService) {
        this.serviceId = AuthService.idCounter++;
    }

    login(credentials: { username: string, password: string }) {
        return this.http.post(Endpoint.LOGIN, {
            username: credentials.username,
            password: credentials.password
        }, httpOptions).pipe(tap((response: any) => {
            this.tokenStorageService.saveToken(response.access_token);
            this.tokenStorageService.saveRefreshToken(response.refresh_token);
        }));
    }

    logout() {
        return this.tokenStorageService.signOut();
    }

    refreshToken(token: string) {
        return this.http.post(Endpoint.REFRESH, {
            refreshToken: token
        }, httpOptions);
    }

}
