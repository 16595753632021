import {Component, ElementRef, SimpleChanges, ViewChild} from '@angular/core';
import {MenuService} from "./menu.service";
import {BehaviorSubject, firstValueFrom, map, Observable, of, tap} from "rxjs";
import {CategoryCreateDto} from "../restaurant/restaurant";
import {CategoryService} from "./category/category.service";
import {EditSidenavComponent} from "./menu-item/edit-sidenav/edit-sidenav.component";
import {animate, style, transition, trigger} from "@angular/animations";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {Menu} from "./menu.types";
import {Category} from "./category/category.types";
import {MenuItemService} from "./menu-item/menu-item.service";
import {MenuItem} from "./menu-item/menu-item.types";
import {UpdateMenuItemDTO} from "./menu-item/menu-item.datasource";
import {MatDialog} from "@angular/material/dialog";
import {CategoryDeleteDialog} from "./category-delete-dialog/category-delete.dialog";
import {ValidationMessages} from "../../shared/Validation/validation.messages";
import {MatChipSelectionChange} from "@angular/material/chips";


@Component({
    selector: 'menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    animations: [trigger('fadeAnimation', [
        transition(':enter', [
            style({opacity: 0}),
            animate('0.5s', style({opacity: 1})),
        ]),
        transition(':leave', [
            style({opacity: 1}),
            animate('0.5s', style({opacity: 0})),
        ]),
    ])]
})
export class MenuComponent {

    @ViewChild("drawer") drawer?: EditSidenavComponent;

    @ViewChild("scrollContainer") scrollContainer?: ElementRef;

    menu$: BehaviorSubject<Menu | null> = new BehaviorSubject<Menu | null>(null)
    categories: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>([])
    categorieLength!: number

    editCategoryMode: boolean = false;
    editCategoryNameMode: boolean = false;

    selectedCategory: Category = {} as Category
    currentMenuItemsBuffer: MenuItem[] = []
    currentMenuItems: MenuItem[] = []

    edit: boolean = false;
    showMenuItems: boolean = true;

    categoryName!: string;

    catAddDisabled: boolean = false;

    waitingForResponse = false;

    scrollCounter = 0;

    constructor(private menuService: MenuService, private menuItemService: MenuItemService, private categoryService: CategoryService, private dialog: MatDialog) {
        this.menuService.getMenu().subscribe((menu: Menu) => {
            this.selectedCategory = menu?.categories[0];
            this.currentMenuItems = this.selectedCategory?.menuItems;
            this.categories.next(menu?.categories)
            this.menu$.next(menu)
            this.catAddDisabled = menu.categories.length > 15;
            this.categorieLength = menu.categories.length
        });
    }

    getItemsForSelectedCategory(): MenuItem[] {
        return this.currentMenuItems;
    }

    startEdit(menuItem: MenuItem) {
        this.drawer?.startEdit(menuItem, this.selectedCategory.id);
    }

    createNewMenuItem() {
        if (this.selectedCategory) {
            this.drawer?.startCreate(this.selectedCategory?.id);
        }
    }

    reloadItems(scroll: boolean = false) {
        this.menuService.getMenu().subscribe((menu: Menu) => {
            this.menu$.next(menu)
            this.selectedCategory = menu?.categories?.find(cat => cat.id === this.selectedCategory?.id) ?? menu?.categories[0];
            this.currentMenuItems = this.selectedCategory?.menuItems;
            this.categories?.next(menu?.categories)
            if (scroll) {
                setTimeout(() => {
                    this.scrollContainer?.nativeElement.scrollTo({
                        top: this.scrollContainer?.nativeElement.scrollHeight,
                        behavior: 'smooth'
                    });
                }, 1);
            }
        });
    }

    deleteMenuItem(menuItem: MenuItem) {
        this.menuItemService.deleteMenuItem(menuItem.id).subscribe(() => {
            this.reloadItems();
        })
    }

    itemTrackBy(index: number, item: MenuItem) {
        return item?.id;
    }

    dropItem($event: CdkDragDrop<MenuItem, any>) {
        let items = this.categories.getValue().find(cat => cat.id === this.selectedCategory?.id)?.menuItems
        if (items) {
            let item = items[$event.previousIndex]
            items.splice($event.previousIndex, 1)
            items.splice($event.currentIndex, 0, item);
            // @ts-ignore
            this.categories.next(this.categories.getValue());
            this.categoryService.reorderMenuItems(this.selectedCategory.id, items).subscribe(() => {
                this.reloadItems();
            });

        }
    }

    droppedOnCategory($event: CdkDragDrop<MenuItem, any>, category: Category) {
        let items = this.menu$.getValue()?.categories.find(cat => cat.id === this.selectedCategory?.id)?.menuItems
        if (items) {
            let item = items[$event.previousIndex]
            items.splice($event.previousIndex, 1);
            // @ts-ignore
            this.categories.next(this.categories.getValue());
            const itemDto: UpdateMenuItemDTO = {
                id: item.id,
                name: item.name,
                description: item.description,
                price: item.price,
                categoryId: category.id,
                allergenIds: item.allergens.map(allergen => allergen.id),
                imageId: item.image
            }
            this.menuItemService.updateMenuItem(itemDto).subscribe(() => {
                this.reloadItems();
            });
        }
    }


    async createCategory() {
        const categories = await firstValueFrom(this.categories);
        if (categories.length < 16) {
            let newCategory: CategoryCreateDto = {
                name: "Neue Kategorie",
            }
            this.categoryService.createCategory(newCategory).subscribe(() => {
                this.reloadItems();
            });
            if (categories.length == 15) {
                this.catAddDisabled = true
            }
        }
    }

    editCategoryDescription() {
        if (this.editCategoryMode) {
            this.waitingForResponse = true;
            this.editCategoryMode = false;
            this.categoryService.updateCategory(this.selectedCategory).subscribe(() => {
                this.reloadItems()
                this.waitingForResponse = false;
            }, (err) => {
                this.waitingForResponse = false;
            });
        } else {
            this.editCategoryMode = true;
        }
    }

    editCategoryName() {
        if (this.editCategoryNameMode) {
            this.waitingForResponse = true;
            this.editCategoryNameMode = false;
            this.selectedCategory.name = this.categoryName;
            this.categoryService.updateCategory(this.selectedCategory).subscribe(() => {
                this.reloadItems()
                this.waitingForResponse = false;
            }, (err) => {
                this.waitingForResponse = false;
            })
        } else {
            this.categoryName = this.selectedCategory.name;
            this.editCategoryNameMode = true;
        }
    }

    createMenu() {
        this.menuService.createMenu().subscribe(() => {
            this.reloadItems();
        });
    }

    getMenuItemDropLists() {

        return ['menu-item-drop-list', ...this.categories.getValue().map(cat => cat.id)];
    }

    reorderCategories($event: { previousIndex: number; currentIndex: number }) {
        let categories = this.categories.getValue();
        let category = categories[$event.previousIndex]
        categories.splice($event.previousIndex, 1)
        categories.splice($event.currentIndex, 0, category);
        // @ts-ignore
        this.categories.next(categories);
        let menuId = this.menu$.getValue()?.id;
        if (!menuId) {
            return;
        }
        this.menuService.reorderCategories(categories, menuId).subscribe(() => {
            this.reloadItems();
        });
    }

    deleteCurrentCategory() {
        this.dialog.open(CategoryDeleteDialog, {
            data: this.selectedCategory
        }).afterClosed().subscribe((categoryDeleted: boolean) => {
            if (categoryDeleted) {
                this.reloadItems()
            }
        })
        this.catAddDisabled = false;
    }

    animationDone($event: any) {
        if (!$event.toState)
            return;
        this.currentMenuItems = this.currentMenuItemsBuffer;
        this.showMenuItems = true;
    }

    onCurrentCategoryChange(newCategory: Category) {
        if (this.drawer) {
            this.drawer.currentCategory = this.selectedCategory?.id
        }
        this.categoryName = this.selectedCategory?.name;
        this.showMenuItems = false;
        this.currentMenuItemsBuffer = newCategory?.menuItems;
    }


    get buttonDisabled(): boolean {
        return !this.selectedCategory;
    }


    disableSaveButton() {
        return this.waitingForResponse;
    }

    scrollNextCategory() {
        document.getElementById("scrollContainer")?.scrollTo({left: document.getElementById('scrollContainer')?.scrollLeft! + 510, behavior: "smooth"})
    }

    scrollPreviousCategory() {
        document.getElementById("scrollContainer")?.scrollTo({left: document.getElementById('scrollContainer')?.scrollLeft! - 510, behavior: "smooth"})
    }
    

}
