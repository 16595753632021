<div style="display: flex; justify-content: center; align-items: center; height: 100vh">
    <default-card>
        <div style="width:560px">
            <h1 style="font-weight: bold">Restaurant erstellen</h1>
            <div style="margin-bottom: 16px">Bevor du Flavoro nutzen kannst, musst du ein Restaurant erstellen.</div>
            <mat-horizontal-stepper #stepper (selectionChange)="onStepChange($event)" style="border-radius: 25px">
                <mat-step [hasError]="(nameInvalid$|async) ?? false" [stepControl]="restaurantNameFormGroup">
                    <form style="min-height: 204px; display: flex; flex-direction: column; align-items: center; justify-content: center"
                          [formGroup]="restaurantNameFormGroup">
                        <ng-template matStepLabel>Name</ng-template>
                        <mat-form-field style="width: 100%; margin-bottom: 8px" color="accent">
                            <mat-label>Restaurantname</mat-label>
                            <input matInput placeholder="Name" formControlName="name">
                            <mat-error *ngIf="restaurantNameFormGroup.controls['name'].hasError('required')">
                                Bitte gib einen Namen ein.
                            </mat-error>
                            <mat-error *ngIf="restaurantNameFormGroup.controls['name'].hasError('server')">
                                {{restaurantNameFormGroup.controls['name'].getError("server")}}</mat-error>
                        </mat-form-field>
                        <span *ngIf="restaurantNameText$|async; let restaurantName; else noRestaurantName">
                            <b>Super! Dein Restaurant wird jetzt <span
                                    style='color: #ff4081'> {{restaurantName}} </span> heißen.</b>
                            <div style="display:flex; flex-direction:column; justify-content:center; align-items:center;height: 64px; font-size: 32px">
                                🎉
                            </div>
                        </span>
                        <ng-template #noRestaurantName>
                            <b>Du hast noch keinen Namen für dein Restaurant eingegeben.</b>
                            <div style="display:flex; flex-direction:column; justify-content:center;height: 64px; font-size: 32px">
                                👨🏻‍🍳
                            </div>
                        </ng-template>

                        <div [innerHTML]=""></div>
                    </form>
                    <div style="display: flex; flex-direction: row; justify-content: end;">
                        <button color="accent" mat-raised-button matStepperNext>Weiter</button>
                    </div>
                </mat-step>

                <mat-step [hasError]="(restaurantInvalid$|async) ?? false" [stepControl]="restaurantAddressFormGroup">
                    <form  [formGroup]="restaurantAddressFormGroup">
                        <ng-template matStepLabel>Addresse</ng-template>
                        <flav-address [formGroup]="restaurantAddressFormGroup" [compact]="true"></flav-address>
                        <mat-error style="display: flex; align-items: center"
                                   *ngIf="restaurantAddressFormGroup.hasError('server')">
                            <mat-icon style="margin-right: 8px">error</mat-icon>
                            {{restaurantAddressFormGroup.getError("server")}}
                        </mat-error>
                        <div style="display: flex; flex-direction: row; justify-content: end">
                            <button style="margin-right: 8px" color="accent" mat-button matStepperPrevious>
                                Zurück
                            </button>
                            <button color="accent" mat-raised-button matStepperNext>Weiter</button>
                        </div>
                    </form>
                </mat-step>

                <mat-step [hasError]="(subdomainInvalid$|async) ?? false" [stepControl]="restaurantSubdomainFormGroup">
                    <form style="min-height: 204px; display: flex; flex-direction: column; align-items: center; justify-content: center"
                          [formGroup]="restaurantSubdomainFormGroup">
                        <ng-template matStepLabel>Subdomain</ng-template>
                        <div>
                        <mat-form-field [floatLabel]="'always'" style="width: 100%;" color="accent" >
                            <mat-label>Subdomain</mat-label>
                            <input matInput maxlength="40" placeholder="beispiel" formControlName="subdomain">
                            <mat-error *ngIf="restaurantSubdomainFormGroup.controls['subdomain'].hasError('server')">
                                {{restaurantSubdomainFormGroup.controls['subdomain'].getError("server")}}</mat-error>
                            <mat-error *ngIf="restaurantSubdomainFormGroup.controls['subdomain'].hasError('pattern')">{{ValidationMessages.NO_SPECIAL_CHARACTER}}</mat-error>
                        </mat-form-field>
                        <mat-checkbox formControlName="ownDomain">Eigene Domain verwenden</mat-checkbox>
                        </div>
                        Deine Adresse wird dann so aussehen: <b>https://<span
                            style="color: #ff4081">{{(subdomain$ |async)}}</span>.flavoro.de</b>
                    </form>
                    <mat-error *ngIf="formError" style="display: flex;align-items: center">
                        <mat-icon style="margin-right: 8px">error</mat-icon>
                        {{formError}}
                    </mat-error>
                    <div style="display: flex; flex-direction: row; justify-content: end;">
                        <button style="margin-right: 8px" color="accent" mat-button matStepperPrevious>
                            Zurück
                        </button>
                        <button color="accent" mat-raised-button (click)="setupRestaurant()"
                                [disabled]="buttonDisabled">Erstellen
                        </button>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </default-card>
</div>
