import {Component, ElementRef, EventEmitter, HostListener, Input, Output} from "@angular/core";
import {AbstractControl, FormGroup, ValidationErrors} from "@angular/forms";
import {ValidationMessages} from "../../../shared/Validation/validation.messages";

@Component({
    selector: 'variable-form-field',
    templateUrl: './variable-form-field.component.html',
    styleUrls: ['./variable-form-field.component.scss']
})
export class VariableFormFieldComponent {
    @Input()
    placeholder: string = "";

    @Input()
    label: string = "";

    @Input()
    pFormControlName: string = "";

    @Input()
    pFormGroup: FormGroup = new FormGroup<any>({});

    editMode: boolean = false;

    @Output()
    changeTitle = new EventEmitter()

    constructor(private eRef: ElementRef) {
    }

    @HostListener('document:click', ['$event'])
    clickout(event: any) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            if (this.editMode) {
                this.exitEditMode();
            }
        }
        else {
            if (!this.editMode) {
                this.enterEditMode();
            }
        }
    }

   validateTitle(){
        this.changeTitle.emit()
   }

    enterEditMode() {
        this.editMode = true;
    }

    exitEditMode() {
        this.editMode = false;
    }


    protected readonly ValidationMessages = ValidationMessages;
}
