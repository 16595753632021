import {CRUDWithReorderDatasource} from "../../shared/datasource/crud-reorder-datasource.abstract";
import {Menu} from "./menu.types";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {Endpoint} from "../../endpoints.definition";

export class CreateMenuDTO {
}

export class UpdateMenuDTO {
}


export class MenuDatasource extends CRUDWithReorderDatasource<Menu, CreateMenuDTO, UpdateMenuDTO> {
    path = Endpoint.MENUES;
    serializer = Menu.fromJson;
}
