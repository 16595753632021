<edit-sidenav style="height: 100vh" (itemChanged)="reloadItems($event)" #drawer>
    <div class="scroll-container" #scrollContainer cdkScrollable>
        <div class="menu-container">

            <div class="category-container" tourAnchor="kategorien">
                <h2>Kategorien</h2>
                <div cdkScrollable style="display: flex; flex-direction: row; width: 100%; align-items: center; justify-content: space-between">
                <button mat-icon-button style="margin-right: 8px;" class="scroll-arrow" (click)="scrollPreviousCategory()"><mat-icon>arrow_left</mat-icon></button>
                <mat-chip-listbox  cdkScrollable id="scrollContainer"  class="mat-chip-listbox"  required="true" [(ngModel)]="selectedCategory" (ngModelChange)="onCurrentCategoryChange($event)" cdkDropListGroup mixedCdkDragDrop [orientation]="'horizontal'"
                     (dropped)="reorderCategories($event)" [itemList]="(categories | async) || undefined" >
                    <div style="display: flex; flex-direction: row; ">
                    <div  *ngFor="let category of categories | async; let i = index"  cdkDropList mixedCdkDropList>
                                            <mat-chip-option disableRipple
                                                    id="{{category.id}}"
                                                             [value]="category" cdkDropList cdkDrag
                                                             (cdkDropListDropped)="droppedOnCategory($event, category)" >{{category.name}}
                                            </mat-chip-option>
                    </div>
                    <mat-chip-option [disabled]="catAddDisabled" (click)="createCategory()" tourAnchor="kategorieHinzufügen">
                        <div>+</div>
                    </mat-chip-option>
                    </div>
                </mat-chip-listbox>

                <button mat-icon-button style="margin-left: 8px;" (click)="scrollNextCategory()"><mat-icon >arrow_right</mat-icon></button>
                </div>
            </div>


            <div class="menu-edit-container">
                <div style="display: flex; flex-direction: row; justify-content: space-between">
                    <div style="display: flex; flex-direction: row; align-items: baseline; gap: 16px;">
                        <h2>
                            <span *ngIf="!editCategoryNameMode">{{selectedCategory?.name}}</span>
                            <mat-form-field color="accent" *ngIf="editCategoryNameMode">
                                <input matInput maxlength="40" [(ngModel)]="categoryName">
                            </mat-form-field>
                        </h2>
                        <flav-fab-button [disabled]="disableSaveButton()" *ngIf="editCategoryNameMode" (clickedFlavButton)="editCategoryName()" iconName="save"></flav-fab-button>
                        <flav-fab-button *ngIf="!editCategoryNameMode" [disabled]="buttonDisabled" (clickedFlavButton)="editCategoryName()" iconName="edit" tourAnchor="kategorieNameBearbeiten"></flav-fab-button>
                        <flav-fab-button (clickedFlavButton)="deleteCurrentCategory()" [disabled]="buttonDisabled" iconName="delete" tourAnchor="kategorieLöschen"></flav-fab-button>

                    </div>
                    <button [disabled]="buttonDisabled" mat-button (click)="createNewMenuItem()">+ Speise hinzufügen</button>
                </div>

                <div class="description-box">
                    <div style="display: flex; flex-direction: row; align-items: baseline; gap: 16px">
                        <h3 style="font-weight: bold">Beschreibung & Hinweise</h3>
                        <flav-fab-button [disabled]="disableSaveButton()" *ngIf="editCategoryMode" (clickedFlavButton)="editCategoryDescription()" iconName="save"></flav-fab-button>
                        <flav-fab-button *ngIf="!editCategoryMode"  [disabled]="buttonDisabled" (clickedFlavButton)="editCategoryDescription()" iconName="edit" tourAnchor="kategorieBeschreibungBearbeiten"></flav-fab-button>

                    </div>
                    <span *ngIf="!editCategoryMode">{{selectedCategory?.description}}</span>
                    <mat-form-field style=" width:100%" color="accent"  *ngIf="editCategoryMode">
                        <textarea maxlength="250" style="font-size: 14px; min-height: 120px; width:100%" matInput [(ngModel)]="selectedCategory.description" name="description"
                                  placeholder="Beschreibung"></textarea>
                    </mat-form-field>
                </div>
                <div style="font-size: 16px; font-weight: bold; padding-bottom: 16px">Speisen</div>
                <div *ngIf="showMenuItems" @fadeAnimation (@fadeAnimation.done)="animationDone($event)" style="display: flex; flex-direction: column; gap: 32px; flex-grow: 1" cdkDropList id="menuItemDropList" [cdkDropListConnectedTo]="getMenuItemDropLists()"
                     (cdkDropListDropped)="dropItem($event)">
                    <item-card  style="width: 100%;"
                               *ngFor="let menuItem of getItemsForSelectedCategory(); trackBy: itemTrackBy "
                               [item]="menuItem" (editClicked)="startEdit(menuItem)"
                               (deleteClicked)="deleteMenuItem(menuItem)"
                                (imageUploadClicked)="startEdit(menuItem)"
                               [cdkDragData]="menuItem"

                               cdkDrag></item-card>
                </div>
            </div>
        </div>
    </div>
</edit-sidenav>

