import {Injectable} from "@angular/core";
import {FlavoroHttpClient} from "../../shared/http/http.client";
import {RestaurantSetupDTO} from "./setup/setup.types";
import {Endpoint} from "../../endpoints.definition";
import {Restaurant} from "./restaurant";

@Injectable({
    providedIn: 'root'
})
export class RestaurantService {


    constructor(private http: FlavoroHttpClient) {
    }

    setupRestaurant(restaurantSetupDTO: RestaurantSetupDTO) {
        return this.http.post(Endpoint.SETUP_RESTAURANT, restaurantSetupDTO);
    }

    getRestaurant() {
        return this.http.get<Restaurant>("/cc/restaurant/info");
    }
}
