import {Injectable} from "@angular/core";
import {RestaurantService} from "../restaurant/restaurant.service";
import {map, mergeMap, Observable} from "rxjs";
import {OpeningTime, WeeklyOpeningTimes} from "../restaurant/restaurant";
import {FlavoroHttpClient} from "../../shared/http/http.client";
import {
    IrregularOpeningTimesDatasource,
    RegularOpeningTimesDatasource,
} from "./opening-times.datasource";
import {IrregularOpeningTimes, RegularOpeningTimes} from "./opening-times.types";


@Injectable({
    providedIn: 'root'
})
export class OpeningTimesService {

    constructor(private datasourceRegular: RegularOpeningTimesDatasource, private datasourceIrregular: IrregularOpeningTimesDatasource) {
    }

    public getRegularOpeningTimes() {
        return this.datasourceRegular.get();
    }
    public getIrregularOpeningTimes() {
        return this.datasourceIrregular.get();
    }

    public updateRegularOpeningTimes(newOpeningTimes: WeeklyOpeningTimes) {
        const ROT = RegularOpeningTimes.fromJson(newOpeningTimes);
      return this.datasourceRegular.update(ROT);
    }
    public updateIrregularOpeningTimes(newOpeningTimes: OpeningTime[]): Observable<IrregularOpeningTimes> {
        const IROT = IrregularOpeningTimes.fromJson(newOpeningTimes).irOpTimes;
       return this.datasourceIrregular.update(IROT);
    }

}
