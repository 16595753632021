import {NgModule} from "@angular/core";
import {FreshchatComponent} from "./freshchat.component";

@NgModule({
    declarations: [
        FreshchatComponent,
    ],
    exports: [
        FreshchatComponent,
    ]
})
export default class FreshchatModule {

}
