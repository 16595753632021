<default-card >
    <div *ngIf="styles$ | async" [formGroup]="stylesFormGroup" style="min-width: 480px">
        <div tourAnchor="styles" class="title" style="padding-bottom: 16px">Design</div>
        <div style="display: flex; flex-direction: row">
            <div>
                <div class="style-title">Logo</div>
                <div style="margin-bottom: 24px;">
                    <div class="image" [ngStyle]="imageError ? {border: '1px solid red', borderRadius: '10px'} : {} ">
                        <flav-image *ngIf="!isLoading; else loading" [src]="logoImageLink" [logo]="true" height="115px" (click)="logoUpload.click()" ></flav-image>
                        <input type="file" (change)="onLogoSelected($event)" #logoUpload style="display: none">

                    </div>
                    <div  *ngIf="imageError" style="color: red; font-size: 12px; max-width: 187px">{{imageErrorMessage}} </div>
                </div>
                <div class="style-title" style="padding-bottom: 16px" >Schriftart</div>

                <mat-form-field tourAnchor="font"  color="accent" style="max-width: 187px">
                    <mat-label>
                        Font
                    </mat-label>
                    <mat-select [style]="getCurrentFontCSS()" formControlName="font">
                        <mat-option *ngFor="let font of fontSelection"  [value]="font.name">
                           <div [style]="font.css">{{font.name}}</div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div [style]="getCurrentFontCSS()">Das ist ein Beispieltext</div>
            </div>
            <div style="display: flex; flex-direction: column; padding-left: 54px;">
                <div class="style-title">Farben</div>
                <div  *ngFor="let color of colorFormArray; let i = index">
                    <color-forms [index]="i" [stylesFormGroup]="stylesFormGroup" label="{{color.label}}" control="{{color.control}}"></color-forms>
                </div>
                <mat-slide-toggle tourAnchor="darkMode" style="margin: 8px 0 16px 2px" formControlName="darkMode">
                    <div style="padding-left: 16px; font-weight: bold;"> Dunkel Modus</div>
                </mat-slide-toggle>
            </div>
        </div>
        <div style="display: flex; flex-direction: row; justify-content: end">
            <button class="button" color="accent" mat-button style="margin-right: 16px " (click)="abortStyles()">ABBRECHEN</button>
            <button class="button" [disabled]="disableSaveButton()" color="accent" mat-raised-button  (click)="submitDesign()">SPEICHERN</button>
        </div>
    </div>
</default-card>

<ng-template #loading>
    <div style="height: 115px; display: flex; flex-direction: row; justify-content: center; align-items: center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>