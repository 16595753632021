<default-card>
    <div *ngIf="introduction$ | async" [formGroup]="introductionFormGroup" style="display: flex; flex-direction: column">
        <div tourAnchor="introduction" class="title">Vorstellungsbereich</div>
        <div style="margin-bottom: 24px;">
            <div class="image" [ngStyle]="imageError ? {border: '1px solid red', borderRadius: '10px'} : {} ">
                <flav-image *ngIf="!isLoading; else loading" [src]="introductionImageLink" height="270px" (click)="introductionUpload.click()" ></flav-image>
                <input maxlength="50" type="file" (change)="onIntroductionImageSelected($event)" #introductionUpload style="display: none">
            </div>
            <div  *ngIf="imageError" style="color: red; font-size: 12px;">{{imageErrorMessage}} </div>
        </div>
        <mat-form-field color="accent">
            <mat-label>
                Vorstellungsüberschrift
            </mat-label>
            <input maxlength="32" matInput formControlName="introHeader">
        </mat-form-field>
        <div tourAnchor="introText" class="editor" >
            <div style="font-size: 9px; padding-bottom: 6px; line-height: 1">Vorstellungstext</div>
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
            </ngx-editor-menu>
            <ngx-editor [editor]="editor" formControlName="introBody">
            </ngx-editor>
            <mat-error *ngIf="!introductionFormGroup.controls['introBody'].valid">Maximale Zeichenlänge überschritten!</mat-error>
        </div>
        <div style="display: flex; flex-direction: row; justify-content: end">
            <button class="button" color="accent" mat-button style="margin-right: 16px" (click)="abortIntroduction()">ABBRECHEN</button>
            <button class="button" color="accent" [disabled]="disableSaveButton()" mat-raised-button  (click)="submitIntroduction()">SPEICHERN</button>
        </div>
    </div>
</default-card>

<ng-template #loading>
    <div style="height: 270px; display: flex; flex-direction: row; justify-content: center; align-items: center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>