import {NgModule} from "@angular/core";
import {AsyncPipe, NgIf, NgStyle} from "@angular/common";
import {DefaultCardModule} from "../../ui/default-card/default-card.module";
import {FlavInputModule} from "../../ui/flav-input/flav-input.module";
import {MatButtonModule} from "@angular/material/button";
import {ReactiveFormsModule} from "@angular/forms";
import {IntroductionComponent} from "./introduction.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgxEditorModule} from "ngx-editor";
import {IntroductionDatasource} from "./introduction.datasource";
import {IntroductionService} from "./introduction.service";
import {FlavoroUiModule} from "../../ui/flavoro-ui.module";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {TourAnchorMatMenuDirective} from "ngx-ui-tour-md-menu";



@NgModule({
    declarations: [IntroductionComponent],
    exports: [
        IntroductionComponent
    ],
    imports: [
        AsyncPipe,
        DefaultCardModule,
        FlavInputModule,
        MatButtonModule,
        NgIf,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgxEditorModule,
        FlavoroUiModule,
        NgStyle,
        MatProgressSpinnerModule,
        TourAnchorMatMenuDirective
    ],
    providers:[
        IntroductionDatasource,
        IntroductionService
    ]
})
export class IntroductionModule{
}