import {NgModule} from "@angular/core";
import {HomeComponent} from "./home.component";
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {AsyncPipe, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {TourAnchorMatMenuDirective} from "ngx-ui-tour-md-menu";

@NgModule({
    declarations: [HomeComponent],
    exports: [
        HomeComponent
    ],
    imports: [
        RouterOutlet,
        AsyncPipe,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatSidenavModule,
        NgIf,
        RouterLink,
        RouterLinkActive,
        MatProgressBarModule,
        TourAnchorMatMenuDirective,
    ]
})
export default class HomeModule {
}
