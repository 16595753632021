import {Component} from "@angular/core";
import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from "@angular/forms";
import {TimeEntry, WeeklyOpeningTimes} from "../restaurant/restaurant";
import {OpeningTimesService} from "./opening-times.service";
import {IrregularOpeningTimes, RegularOpeningTimes} from "./opening-times.types";
import {first, firstValueFrom, ReplaySubject, tap} from "rxjs";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {TransferDialogComponent} from "./transfer-dialog/transfer-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ValidationMessages} from "../../shared/Validation/validation.messages";
import {FormGroupComponentInterface} from "../ui/CanDeactivateGuard/formgroupcomponent.interface";

@Component(
    {
        selector: 'opening-times',
        templateUrl: './opening-times.component.html',
        styleUrls: ['./opening-times.component.scss']

    }
)
export class OpeningTimesComponent implements FormGroupComponentInterface {

    public regularOpeningTimesFormGroup!: FormGroup;
    public irregularOpeningTimesFormGroup!: FormArray ;
    waitingForResponse = false;

    public days = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday"
    ]

    public daysDE = [
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
        "Sonntag"
    ]
    timeRegex = /^([01][0-9]|2[0-3]):([0-5][0-9])$/
    public groupError = false;
    _currentRegularOpeningTimesSubject: ReplaySubject<RegularOpeningTimes> = new ReplaySubject(1);
    currentRegularOpeningTimes$ = this._currentRegularOpeningTimesSubject.asObservable();

    _currentIrregularOpeningTimesSubject: ReplaySubject<IrregularOpeningTimes> = new ReplaySubject(1);
    currentIrregularOpeningTimes$ = this._currentIrregularOpeningTimesSubject.asObservable();

    regularOpeningTimes$ =  this.openingTimeService.getRegularOpeningTimes().pipe(tap(weeklyOpeningTimes => {
        this._currentRegularOpeningTimesSubject.next(weeklyOpeningTimes);
        this.buildRegularOpeningTimesForm(weeklyOpeningTimes);
    }));

    irregularOpeningTimes$ =  this.openingTimeService.getIrregularOpeningTimes().pipe(tap(irregularOpeningTimes => {
        this._currentIrregularOpeningTimesSubject.next(irregularOpeningTimes);
        this.buildIrregularOpeningTimesForm(irregularOpeningTimes);
    }));
    constructor(private openingTimeService: OpeningTimesService, public dialog: MatDialog, private snackbar: MatSnackBar) {}

    openDialog(isRegular : boolean, dayArray:string[], day:string): void {
        console.log(day)
        const dialogConfig = new MatDialogConfig();
        const dayToDeleteIndex = dayArray.indexOf(day);
        const arrayWithoutDay = [...dayArray];
        arrayWithoutDay.splice(dayToDeleteIndex, 1);
        arrayWithoutDay.map((aWDay, index) => aWDay == day ? arrayWithoutDay.splice(index) : "")
        dialogConfig.data = {
           selectedDays: arrayWithoutDay,
        };
       const dialogRef =  this.dialog.open(TransferDialogComponent, dialogConfig);

       dialogRef.afterClosed().subscribe(
           (data) => {
               if(isRegular){
                   this.transferRegularOpeningTime(day, data?.selectedDays)
               }else{ this.transferIrregularOpeningTime(day, data?.selectedDays)}
               }
       )
    }

    transferRegularOpeningTime(day: string, selectedDays: string[]){
        if(selectedDays){
      const baseDayGroups =   [...this.getTimeFormGroupsForDay(day)];
      for(let selectedDay of selectedDays){
          const formArray: FormArray<any> = this.regularOpeningTimesFormGroup?.get(selectedDay) as FormArray;
          formArray.controls.splice(0, formArray.controls.length);
          baseDayGroups.map((baseForms) =>{
              formArray.push(new FormGroup({from: new FormControl(baseForms.get("from")?.value, [Validators.required , Validators.pattern(this.timeRegex)]), to: new FormControl(baseForms.get("to")?.value, [Validators.required, Validators.pattern(this.timeRegex)])}))
          })
      }
      this.regularOpeningTimesFormGroup.markAsDirty()
        }
    }

    transferIrregularOpeningTime(day: string, selectedDays: string[]){
        if(selectedDays){
            const baseDayGroups = this.findIrregularOpeningTimeGroup(day)?.get('date')
            const baseDayGroupsTime = [...this.getTimesFormArrayIrregular(this.findIrregularOpeningTimeGroup(day)).controls] as FormGroup[];
            for(let selectedDay of selectedDays){
                let formArrayTime: FormArray<any> = this.getTimesFormArrayIrregular(this.findIrregularOpeningTimeGroup(selectedDay)) as FormArray;
                formArrayTime.controls.splice(0, formArrayTime.controls.length);
                baseDayGroupsTime.map((baseForms) =>{
                    formArrayTime.push(new FormGroup({from: new FormControl(baseForms.get("from")?.value, [Validators.required, Validators.pattern(this.timeRegex)]), to: new FormControl(baseForms.get("to")?.value, [Validators.required, Validators.pattern(this.timeRegex)])}))
                })
            }
        }
        this.irregularOpeningTimesFormGroup.markAsDirty()
    }


    findIrregularOpeningTimeGroup(day: string){
        return this.irregularOpeningTimesFormGroup.controls.find((group: any) => group.value.description == day) as FormGroup;
    }

    buildRegularOpeningTimesForm(initialValue: WeeklyOpeningTimes) {

        const mapDayToFormArray = (day: TimeEntry[]) => {
            return new FormArray(day.map(timeEntry => new FormGroup({
                from: new FormControl(timeEntry.from, [Validators.required]),
                to: new FormControl(timeEntry.to, [Validators.required])
            })));
        }

        this.regularOpeningTimesFormGroup = new FormGroup({
            monday: mapDayToFormArray(initialValue?.monday),
            tuesday: mapDayToFormArray(initialValue?.tuesday),
            wednesday: mapDayToFormArray(initialValue?.wednesday),
            thursday: mapDayToFormArray(initialValue?.thursday),
            friday: mapDayToFormArray(initialValue?.friday),
            saturday: mapDayToFormArray(initialValue?.saturday),
            sunday: mapDayToFormArray(initialValue?.sunday)
        });
    }

    getAllIrregularOpeningTimesDescriptions(){
        let groupDescriptions: string[] = [];
        this.getIrregularOpeningTimesFormGroups().map((group) => groupDescriptions.push(group.get('description')?.value))
        return groupDescriptions;
    }

    buildIrregularOpeningTimesForm(initialValue: IrregularOpeningTimes) {
        console.log(initialValue.irOpTimes)
        this.irregularOpeningTimesFormGroup = new FormArray(initialValue?.irOpTimes?.map(openingTime => new FormGroup({
            date: new FormControl(openingTime.date, [Validators.required]),
            description: new FormControl(openingTime?.description == "" ? "Empty Title"  : openingTime?.description , [Validators.required]),
            times: new FormArray(openingTime?.times?.map(timeEntry => new FormGroup({
                from: new FormControl(timeEntry.from, [Validators.required, Validators.pattern(this.timeRegex)]),
                to: new FormControl(timeEntry.to, [Validators.required, Validators.pattern(this.timeRegex)])
            })))
        })));
        this.irregularOpeningTimesFormGroup?.markAllAsTouched();
    }

    getLocalizedDay(day: string): string {
        switch (day) {
            case "monday":
                return "Montag";
            case "tuesday":
                return "Dienstag";
            case "wednesday":
                return "Mittwoch";
            case "thursday":
                return "Donnerstag";
            case "friday":
                return "Freitag";
            case "saturday":
                return "Samstag";
            case "sunday":
                return "Sonntag";
        }

        return ""
    }


    public getFormDay(day: string): FormArray {
        return this.regularOpeningTimesFormGroup.get(day) as FormArray;
    }
    getTimeFormGroupsForDay(day: string): FormGroup[] {
        return (this.regularOpeningTimesFormGroup.get(day) as FormArray).controls as FormGroup[];
    }
    getIrregularOpeningTimesFormGroups(): FormGroup[] {
        return this.irregularOpeningTimesFormGroup.controls as FormGroup[];
    }
    getTimesFormArrayIrregular(control: FormGroup): FormArray {
        return control.get("times") as FormArray;
    }

    timeControlValidate(control: FormArray){
        return control.controls.length >= 4
    }

    addTimeControlToFormArray(control: FormArray, regular: boolean) {
        control.controls.push(new FormGroup({
            from: new FormControl("", [Validators.required, Validators.pattern(this.timeRegex)]),
            to: new FormControl("", [Validators.required, Validators.pattern(this.timeRegex)])
        }));
        if(regular){
            this.regularOpeningTimesFormGroup.markAsDirty()
        }else{
            this.irregularOpeningTimesFormGroup.markAsDirty()
        }

    }



    async submitRegularOpeningTimes() {
        if(this.groupError){
            this.snackbar.open(ValidationMessages.FAILURE, ValidationMessages.OK)
            return
        }
        this.waitingForResponse = true;
        try {
        const updateRegular = await firstValueFrom(this.openingTimeService.updateRegularOpeningTimes(this.regularOpeningTimesFormGroup.getRawValue()));
            this.snackbar.open(ValidationMessages.SUCCES, ValidationMessages.OK, {duration: 5000, panelClass: ['success-snackbar']})
            this._currentRegularOpeningTimesSubject.next(updateRegular);
            this.regularOpeningTimesFormGroup.markAsPristine();
        } catch (e) {this.snackbar.open(ValidationMessages.HTTP_FAILURE, ValidationMessages.OK,);
            console.error(e)
        }
        this.waitingForResponse = false
    }

    async submitIrregularOpeningTimes() {
        if(this.groupError || this.irregularOpeningTimesFormGroup.invalid){
            this.irregularOpeningTimesFormGroup.markAllAsTouched();
            this.snackbar.open(ValidationMessages.FAILURE, ValidationMessages.OK)
            return
        }
        this.waitingForResponse = true;
        try {
        this.irregularOpeningTimesFormGroup.value.map((irrTime: any) => {console.log(irrTime.date)})
        const updateIrregularArray = await firstValueFrom(this.openingTimeService.updateIrregularOpeningTimes(this.irregularOpeningTimesFormGroup.getRawValue()));
        const updateIrregular = IrregularOpeningTimes.fromJson(updateIrregularArray);
        this._currentIrregularOpeningTimesSubject.next(updateIrregular);
            this.snackbar.open(ValidationMessages.SUCCES, ValidationMessages.OK, {duration: 5000, panelClass: ['success-snackbar']})
            this.irregularOpeningTimesFormGroup.markAsPristine();
        } catch (e) {this.snackbar.open(ValidationMessages.HTTP_FAILURE, ValidationMessages.OK,);
            console.error(e)
        }
        this.waitingForResponse = false;
    }


    removeTimeControlFromDay(day: string, control: FormGroup) {
        let formArray = this.regularOpeningTimesFormGroup.get(day) as FormArray;
        formArray.controls.splice(formArray.controls.indexOf(control), 1);
        this.regularOpeningTimesFormGroup.markAsDirty();
    }

    addIrregularOpeningTime() {
        this.irregularOpeningTimesFormGroup.push(new FormGroup({
            date: new FormControl("", [Validators.required]),
            description: new FormControl("Titel " + (this.irregularOpeningTimesFormGroup.length + 1), [Validators.required]),
            times: new FormArray([])
        }));
        this.renameIrregularOpTimes();
        this.irregularOpeningTimesFormGroup.markAsDirty()
    }



    removeTimeControlFromIrregular(irregularDateGroup: FormGroup, timeGroup: FormGroup) {
        let formArray = this.getTimesFormArrayIrregular(irregularDateGroup);
        formArray?.controls.splice(formArray?.controls.indexOf(timeGroup), 1);
        this.irregularOpeningTimesFormGroup.markAsDirty();
    }

    getTimesFormGroups(control: any): FormGroup[] {
        return control.get("times").controls as FormGroup[];
    }

    removeIrregularOpeningTime(control: any): void {
        this.irregularOpeningTimesFormGroup?.controls.splice(this.irregularOpeningTimesFormGroup.controls.indexOf(control), 1);
        this.renameIrregularOpTimes();
        this.irregularOpeningTimesFormGroup.markAsDirty()
    }

    renameIrregularOpTimes(){
        this.irregularOpeningTimesFormGroup?.controls.map((control, index) => {
            if(control.get('description')?.value.slice(0,5) == "Titel"){
                control.get('description')?.setValue('Titel ' + (index + 1))
            }})
    }
    async abortRegularOpenTime() {
        const originalData = await firstValueFrom(this.currentRegularOpeningTimes$);
        this.buildRegularOpeningTimesForm(originalData);
        this.regularOpeningTimesFormGroup.markAsPristine();
    }

    async abortIrregularOpenTime(){
        const originalData = await firstValueFrom(this.currentIrregularOpeningTimes$);
        this.buildIrregularOpeningTimesForm(originalData);
        this.irregularOpeningTimesFormGroup.markAsPristine();
    }

    getGroupError(groupError: boolean){
        this.groupError = groupError;
    }


    validateTitleDate(controlName: string){
        this.irregularOpeningTimesFormGroup?.controls.map((control) => {
            let counter = 0
            let controlValue = controlName == "date" ? new Date(control.get(controlName)?.value).toString() : control.get(controlName)?.value
            this.irregularOpeningTimesFormGroup?.controls.map((control2) => {
                let controlValue2 = controlName == "date" ? new Date(control2.get(controlName)?.value).toString() : control2.get(controlName)?.value
                    if(controlValue == controlValue2){
                        counter++
                    }
            })
            if(counter > 1){
                control.get(controlName)?.setErrors({sameTitle: true})
            } else if(!control.get(controlName)?.hasError('required')){
                control.get(controlName)?.setErrors(null)
            }
        })
    }


    protected readonly ValidationMessages = ValidationMessages;

    getFormGroups(): (FormGroup | FormArray)[] {
        return [this.regularOpeningTimesFormGroup, ...this.days.flatMap((day) => this.getTimeFormGroupsForDay(day)),  this.irregularOpeningTimesFormGroup, ...this.getIrregularOpeningTimesFormGroups()]
    }

    disableSaveButton(formGroup: any){
        let disable = !formGroup?.dirty;
        if(!this.waitingForResponse) {
            if (formGroup?.controls) {
                Object.keys(formGroup.controls).forEach((key: string) => {
                    let group = formGroup.get(key) as FormArray
                    if (group.get('date')?.dirty) {
                        disable = false
                    }
                    Object.keys(group?.controls).forEach((key2: any) => {
                        if (group.get(key2)?.dirty) {
                            disable = false
                        }
                        let group2 = group?.get(key2) as FormArray
                        if (group2?.controls) {
                            Object.keys(group2?.controls)?.forEach((key3: any) => {
                                if (group2.get(key3)?.dirty) {
                                    disable = false
                                }

                            })
                        }
                    })
                });
            }
        }else{
            disable = true;
        }
        return disable;
    }

    setSaveDisable(formGroup: any){
        formGroup.markAsDirty()
    }
}
