import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from "@angular/forms";


export function confirmPasswordValidator(password: FormControl, confirmPassword: FormControl): ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => {
        if (password?.value == confirmPassword?.value && !password.invalid){
                confirmPassword.setErrors( null)
           return null
        }
        confirmPassword.setErrors( {notSame: true})
        return null
    }
}