import {NgModule} from '@angular/core';
import {MatIconModule} from "@angular/material/icon";

import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CdkDrag, CdkDragHandle, CdkDropList, CdkDropListGroup, DragDropModule} from "@angular/cdk/drag-drop";
import {CommonModule} from "@angular/common";
import {MenuComponent} from "./menu.component";
import {EditSidenavComponent} from "./menu-item/edit-sidenav/edit-sidenav.component";
import {ItemCardComponent} from "./menu-item/item-card/item-card.component";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatOptionModule} from "@angular/material/core";
import {MatChipsModule} from "@angular/material/chips";
import {MatSelectModule} from "@angular/material/select";
import {MenuDatasource} from "./menu.datasource";
import {MenuItemDatasource} from "./menu-item/menu-item.datasource";
import {FlavoroHttpClient} from "../../shared/http/http.client";
import {CategoryService} from "./category/category.service";
import {CategoryDatasource} from "./category/category.datasource";
import {MixedCdkDragDropDirective, MixedCdkDragDropModule} from "angular-mixed-cdk-drag-drop";
import {FlavoroUiModule} from "../ui/flavoro-ui.module";
import {FlavFabButtonModule} from "../ui/flav-fab-button/flav-fab-button.module";
import {CategoryDeleteDialog} from "./category-delete-dialog/category-delete.dialog";
import {MatDialogModule} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {PriceFieldComponent} from "./menu-item/edit-sidenav/price-field/price-field.component";
import {TourAnchorMatMenuDirective} from "ngx-ui-tour-md-menu";



@NgModule({
    declarations: [
        MenuComponent,
        EditSidenavComponent,
        ItemCardComponent,
        CategoryDeleteDialog,
        PriceFieldComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatSidenavModule,
        MatOptionModule,
        MatChipsModule,
        ReactiveFormsModule,
        MixedCdkDragDropModule,
        CdkDropList,
        CdkDrag,
        CdkDragHandle,
        CdkDropListGroup,
        FormsModule,
        FlavoroUiModule,
        FlavFabButtonModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        TourAnchorMatMenuDirective,
        DragDropModule
    ],
    providers: [
        MenuDatasource,
        MenuItemDatasource,
        CategoryService,
        CategoryDatasource,
        MixedCdkDragDropDirective,
    ],
})
export class MenuModule {
}
