import {Injectable} from "@angular/core";
import {Impressum} from "./basisdaten.type";
import {BasisdatenDatasource} from "./basisdaten.datasource";

@Injectable(
    {providedIn: 'root'}
)
export class BasisdatenService {


    constructor(private basisdatenDatasource: BasisdatenDatasource) {
    }

    public getImpressum() {
       return this.basisdatenDatasource.get();
    }

    public updateImpressum(impressum: Impressum) {
        return this.basisdatenDatasource.update(impressum);
    }
}
