import {NgModule} from "@angular/core";
import {RouterOutlet} from "@angular/router";
import {WelcomeComponent} from "./welcome.component";


@NgModule({
    declarations: [WelcomeComponent],
    exports: [
        WelcomeComponent
    ],
    imports: [
        RouterOutlet
    ]
})
export default class WelcomeModule {}