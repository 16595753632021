import {NgModule} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {FlavInputComponent} from "./flav-input.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgxColorsModule} from "ngx-colors";



@NgModule({
    declarations: [
        FlavInputComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgxColorsModule,
    ],
    exports: [
        FlavInputComponent
    ],
    providers: []
})
export class FlavInputModule {
}