import {CRUDDatasource} from "./crud-datasource.abstract";
import {Observable} from "rxjs";
import {appendPath} from "../util/path.helper";

export abstract class CRUDWithReorderDatasource<T, C, E> extends CRUDDatasource<T, C, E> {

    reorder(property: string, ids: string[], propertyId?: string): Observable<boolean> {
        const basePath = propertyId ? appendPath(this.path, propertyId) : this.path;
        const propertyPath = appendPath(basePath, property);
        const propertyPathWithReorder = appendPath(propertyPath, "reorder");
        return this.http.put<boolean>(propertyPathWithReorder, ids);
    }
}
