export class PasswordConfig{
    constructor(
        public password: string,
        public confirmPassword: string,
    ) {}

    public static fromJson(json: any){
      return new PasswordConfig(
            json.password,
            json.confirmPassword
        )
    }
}