import {Component, OnDestroy, OnInit} from '@angular/core';
import {TourService} from "ngx-ui-tour-md-menu";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../features/auth/auth.service";
import {Subscription, tap} from "rxjs";
import {TokenStorageService} from "../features/auth/tokenstorage.service";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'restaurant_builder';

    tourEnded: boolean = false;

    authenticated = false;

    authenticationSubscription: Subscription | null = null;

    constructor(public tourService: TourService, private route: ActivatedRoute, private authService: AuthService) {
        this.tourService.initialize([
            {
                anchorId: 'kategorien',
                content: "Hier siehst du deine Menü-Kategorien. Du kannst die Reihenfolge der Kategorien durch Drag & Drop verändern. Klicke auf eine Kategorie um sie zu bearbeiten.",
                title: 'Kategorien',
                enableBackdrop: true
            },
            {
                anchorId: 'kategorieHinzufügen',
                content: "Klicke hier um eine neue Kategorie hinzuzufügen.",
                title: 'Kategorie hinzufügen',
                enableBackdrop: true
            },
            {
                anchorId: 'kategorieNameBearbeiten',
                content: "Klicke hier um den Namen der Kategorie zu bearbeiten.",
                title: 'Kategorienamen bearbeiten',
                enableBackdrop: true
            },
            {
                anchorId: 'kategorieLöschen',
                content: "Klicke hier um die Kategorie zu löschen.",
                title: 'Kategorie löschen',
                enableBackdrop: true
            },
            {
                anchorId: 'kategorieBeschreibungBearbeiten',
                content: "Klicke hier um die Beschreibung der Kategorie zu bearbeiten.",
                title: 'Kategoriebeschreibung bearbeiten',
                enableBackdrop: true
            },
            {
                anchorId: 'speiseHinzufügen',
                content: "Klicke hier um eine neue Speise hinzuzufügen.",
                title: 'Speise hinzufügen',
                nextBtnTitle: 'Fertig',
                enableBackdrop: true
            },
            {
                anchorId: 'regularOpening',
                route: '/offnungszeiten',
                content: "Hier kannst du deine regelmäßigen Öffnungszeiten einstellen.",
                title: 'Regelmäßige Öffnungszeiten',
            },
            {
                anchorId: 'irregularOpening',
                content: "Hier kannst du die unregelmäßigen Öffnungszeiten, jene welche nur einmalig gelten\n" +
                    "sollen. Beispielsweise wenn Wartungsarbeiten an deinem Restaurant anstehen und du\n" +
                    "vorzeitig schließen müsstest.",
                title: 'Unregelmäßige Öffnungszeiten',
            },
            {
                anchorId: 'clickOnClock',
                content: "Mit einem Klick auf die Uhr kannst du mithilfe der Uhr bequem die Uhrzeit einstellen.",
                title: 'Uhrzeit einstellen',
                enableBackdrop: true
            },
            {
                anchorId: 'transferButton',
                content: "Mit dem Übertragen Button, kannst du die Uhrzeit eines Tages auf andere Tage übertragen und musst so nicht manuell jede Uhrzeit einstellen.",
                title: 'Uhrzeit übertragen',
                enableBackdrop: true
            },
            {
                anchorId: 'calendar',
                content: "Mit einem Klick auf den Kalender kannst du ganz einfach das Datum einstellen.",
                title: 'Tag auswählen',
                enableBackdrop: true
            },
            {
                anchorId: 'irregularTransfer',
                content: "Auch die außerordentlichen Öffnungszeiten kannst du auf andere Tage übertragen. Dabei wird aber lediglich die Uhrzeiten übertragen und nicht das Datum oder der Name",
                title: 'Uhrzeit übertragen',
                enableBackdrop: true
            },
            {
                anchorId: 'title',
                content: "Um dich selber nicht zu verwirren, kannst du diesen Tagen auch einen Namen geben.",
                title: 'Tag benennen',
                nextBtnTitle: 'Fertig',
                enableBackdrop: true
            },
            {
                anchorId: 'hero',
                route: '/gestaltung',
                content: "Der Startbereich ist der Bereich, den deine Gäste als erstes sehen.",
                title: 'Startbereich',
            },
            {
                anchorId: 'introduction',
                content: "Der Vorstellungsbereich gibt dir und deinem Restaurant die Chance, euch vorzustellen.",
                title: 'Vorstellungsbereich',
            },
            {
                anchorId: 'styles',
                content: "Im Design kannst du die Schriftart, die Farben und dein Logo einstellen.",
                title: 'Design',
            },
            {
                anchorId: 'changePicture',
                content: "Drücke auf „Bild ändern“ um das Bild zu ändern.",
                title: 'Bild ändern',
                enableBackdrop: true
            },
            {
                anchorId: 'introText',
                content: "Dieser Text gibt dir die Möglichkeit euch vorzustellen.",
                title: 'Vorstellungstext',
                enableBackdrop: true
            },
            {
                anchorId: 'colorCode',
                content: "Hier kannst du einen Farbcode eingeben, wenn du bereits weißt, welche Farben du benutzen möchtest.",
                title: 'Farbcode eigeben',
                enableBackdrop: true
            },
            {
                anchorId: 'paginator',
                content: "Alternativ kannst du auch auf die Farbe drücken und kriegst eine Vorschau verschiedener Farben in einer Farbpalette",
                title: 'Farbe wählen',
                enableBackdrop: true
            },
            {
                anchorId: 'font',
                content: "Hier kannst du die Schriftart ändern und siehst da drunter eine Vorschau",
                title: 'Schriftart wählen',
                enableBackdrop: true
            },
            {
                anchorId: 'darkMode',
                content: "Du kannst auch einstellen, ob deine Webseite einen dunklen Style, oder einen hellen haben sollte.",
                title: 'Dunkel Modus',
                nextBtnTitle: 'Fertig',
                enableBackdrop: true
            },
            {
                anchorId: 'website',
                content: "Wenn du hier drauf klickst, gelangst du zu deiner Webseite",
                title: 'Deine Website',
                enableBackdrop: true
            },
            {
                anchorId: 'loadingWebsite',
                content: "Sollte vorher aber dieser Ladebalken noch da sein, braucht deine Webseite noch etwas Zeit, um aktualisiert zu werden",
                title: 'Website lädt',
                nextBtnTitle: 'Fertig',
                enableBackdrop: true
            },
            {
                anchorId: 'end',
                //This is a workaround, because the tour doesn't end when the last step is reached
            }
        ])

        this.route.queryParams.subscribe(params => {
            /** if (params["showTour"] == "true") {
             this.tourService.start();
             }
             this.tourService.next()*/
        })
    }

    ngOnInit(): void {
        this.authenticationSubscription = this.authService.authenticated$.subscribe((authenticated => {
            console.log("authenticated", authenticated)
            this.authenticated = authenticated
        }))
    }

    ngOnDestroy(): void {
        this.authenticationSubscription?.unsubscribe();
    }
}
