import {Component, OnDestroy, OnInit} from "@angular/core";
import {Editor, Toolbar} from "ngx-editor";
import {FormControl, FormGroup } from '@angular/forms';
import {BasisdatenService} from "./basisdaten.service";
import {Impressum} from "./basisdaten.type";
import {firstValueFrom, ReplaySubject, tap} from "rxjs";
import {ValidationMessages} from "../../shared/Validation/validation.messages";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormGroupComponentInterface} from "../ui/CanDeactivateGuard/formgroupcomponent.interface";

@Component({
    selector: 'app-basisdaten',
    templateUrl: './basisdaten.component.html',
    styleUrls: ['./basisdaten.component.scss']
})



export class BasisdatenComponent implements OnInit, OnDestroy, FormGroupComponentInterface {
    basisdatenFormGroup!: FormGroup;
    editor!: Editor;
    waitingForResponse: boolean = false;


    private _currentImpressumSubject:ReplaySubject<Impressum> = new ReplaySubject(1)
    currentImpressum$ = this._currentImpressumSubject.asObservable();


    form$ = this.basisdatenService.getImpressum().pipe(tap( (impressum) =>{
            this._currentImpressumSubject.next(impressum);
            this.basisdatenFormGroup = new FormGroup<any>({editorContent: new FormControl(impressum?.legalNotice ?? "")})
        }
    ))

    constructor(private basisdatenService: BasisdatenService, private snackbar: MatSnackBar) {}
    async abort(){
        const originalData = await firstValueFrom(this.currentImpressum$);
        this.basisdatenFormGroup.get("editorContent")?.setValue(originalData.legalNotice);
        this.basisdatenFormGroup.markAsPristine();
    }

    async submitContent(){
        const impressum:Impressum = {legalNotice: this.basisdatenFormGroup.get('editorContent')?.value + ""}
        this.basisdatenFormGroup.markAsPristine();
        this.waitingForResponse = true;
        try{
            const updatedImpressum = await firstValueFrom(this.basisdatenService.updateImpressum(impressum))
            this._currentImpressumSubject.next(updatedImpressum)
            this.snackbar.open(ValidationMessages.SUCCES, ValidationMessages.OK, {duration: 5000, panelClass: ['success-snackbar']})
        }
        catch(e){ this.snackbar.open(ValidationMessages.HTTP_FAILURE, ValidationMessages.OK)
            this.basisdatenFormGroup.markAsDirty();
        }
        this.waitingForResponse = false;
    }



    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['code', 'blockquote'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['link', 'image'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    ngOnInit() {
        this.editor = new Editor();
    }

    // make sure to destory the editor
    ngOnDestroy() {
        this.editor.destroy();
    }

    getFormGroups(): FormGroup[] {
        return [this.basisdatenFormGroup];
    }

    disableSaveButton(){
        return !(this.basisdatenFormGroup?.dirty && this.basisdatenFormGroup?.valid && !this.waitingForResponse)
    }
}

