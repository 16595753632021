import {Component, OnDestroy} from "@angular/core";
import {AuthService} from "../../auth/auth.service";
import {Router} from "@angular/router";
import {DomainService} from "../../domain/domain.service";
import {distinct, firstValueFrom, Observable, Subscription} from "rxjs";
import {RenderLoadingService} from "../../render-loading/render-loading.service";
import {TokenStorageService} from "../../auth/tokenstorage.service";
import {RestaurantService} from "../../restaurant/restaurant.service";

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy {

    title = 'restaurant_builder';
    websiteUrl: Observable<string> = this.domainService.getDomain();

    isRendering$ = this.renderLoadingService.isRendering$;


    constructor(private authService: AuthService, private router: Router, private domainService: DomainService, private renderLoadingService: RenderLoadingService, private restaurantService: RestaurantService) {
        authService.authenticated$.pipe(distinct()).subscribe(async authenticated => {
            if (authenticated) {
                const restaurant = await firstValueFrom(restaurantService.getRestaurant())
                renderLoadingService.connect(restaurant.id);
            }
        })
    }

    sidebarClosed = false;

    toggleSidebar() {
        this.sidebarClosed = !this.sidebarClosed;
    }

    logout() {
        this.authService.logout()
        this.router.navigate(["/login"])
    }

    ngOnDestroy(): void {

    }
}
