import {Injectable} from "@angular/core";
import {RMAbstractDataSource} from "../../../shared/datasource/rm-datasource.abstract";
import {Endpoint} from "../../../endpoints.definition";
import {Introduction} from "./introduction.type";

export class UpdateIntroductionDTO{
    constructor(
        public header: string,
        public body: string,
        public imageId: string,
    ) {
    }

    private static fromJson(json: any){
        return new UpdateIntroductionDTO(
            json.header,
            json.body,
            json.imageId,
        )
    }
}

@Injectable()
export class IntroductionDatasource extends RMAbstractDataSource<Introduction, UpdateIntroductionDTO> {
    readonly path = Endpoint.ITRODUCTION;
    readonly serializer = Introduction.fromJson;
}