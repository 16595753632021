<div class="registration-wrapper">
    <img style="width: 300px; height: 200px; object-fit: cover" src="../../assets/OrangeRegular.svg"/>
    <default-card style="margin-bottom: 16px;">
        <ng-container *ngIf="registration != '#success'; else registrationCompleted">
            <div style="max-width: 440px" [formGroup]="registrationFormGroup">
                <h1 style="font-weight: bold">Registration</h1>
                <div style="display: flex; flex-direction: column;">
                    <div>
                        <mat-form-field color="accent">
                            <mat-label>
                                Vorname
                            </mat-label>
                            <input matInput formControlName="firstName">
                            <mat-error *ngIf="registrationFormGroup.get('firstName')?.hasError('required')">{{ValidationMessages.REQUIRED}}</mat-error>
                            <mat-error *ngIf="registrationFormGroup.get('firstName')?.hasError('pattern')">{{ValidationMessages.WRONG_NAME}}</mat-error>
                        </mat-form-field>
                        <mat-form-field style="padding-left: 16px" color="accent">
                            <mat-label>
                                Nachname
                            </mat-label>
                            <input matInput formControlName="lastName">
                            <mat-error *ngIf="registrationFormGroup.get('lastName')?.hasError('required')">{{ValidationMessages.REQUIRED}}</mat-error>
                            <mat-error *ngIf="registrationFormGroup.get('lastName')?.hasError('pattern')">{{ValidationMessages.WRONG_NAME}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <div class="style-title">Kontakt</div>
                        <mat-form-field color="accent">
                            <mat-label>
                                Email
                            </mat-label>
                            <input matInput formControlName="email">
                            <mat-error>{{ValidationMessages.WRONG_EMAIL_SHORT}}</mat-error>
                        </mat-form-field>
                        <mat-form-field style="padding-left: 16px" color="accent">
                            <mat-label>
                                Telefon
                            </mat-label>
                            <input matInput formControlName="phoneNumber">
                            <mat-error
                                    *ngIf="registrationFormGroup.get('phoneNumber')?.hasError('pattern')">{{ValidationMessages.WRONG_PHONE}}</mat-error>
                        </mat-form-field>
                    </div>
                    <flav-address [compact]="true" [formGroup]="addressFormGroup"></flav-address>
                    <div class="style-title">Passwort</div>
                    <flav-password-field [passwordFormGroup]="registrationFormGroup"
                                         fieldWidth="440px"></flav-password-field>
                </div>
                <div style="width: 100%; display: flex; flex-direction: row; justify-content: end; padding-top: 40px">
                    <loading-button [buttonType]="buttonTypes.raised" text="Registrieren" (bClick)="signIn(false)"
                                    [isNotLoading]="isNotLoading"></loading-button>
                </div>
            </div>
        </ng-container>
    </default-card>
</div>

<ng-template #registrationCompleted>
    <h1 style="font-weight: bold">Registrierung erfolgreich</h1>
    <div>Es wurde eine Email zur Verifizierung an deine Email Adresse geschickt.</div>
    <div class="email-again">
        <loading-button [buttonType]="buttonTypes.text" text="Email erneut senden" (bClick)="sendVerificationMail()" height="30"
                        width="110px" [isNotLoading]="isNotLoadingResend"></loading-button>
    </div>
    <div></div>
    <button mat-raised-button color="accent" (click)="backToLogin()">Zum Login</button>
</ng-template>
