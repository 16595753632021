import {Address} from "../../address/address.type";

export type RestaurantSetupDTO = {
    name: string;
    subdomain: string;
    ownDomain: string;
    address: Address
}

export type SetupErrorsType = {
    name: string;
    subdomain: string;
    address: string | undefined;
    "address.street": string;
    "address.houseNumber": string;
    "address.city": string;
    "address.postalCode": string;
    "address.countryId": string;
}

export class SetupErrors {
    constructor(
        public name: string,
        public subdomain: string,
        public address: {
            street: string,
            houseNumber: string,
            city: string,
            postalCode: string,
            countryId: string
        } | string
    ) {
    }

    static fromJson(json: SetupErrorsType): SetupErrors {
        return new SetupErrors(
            json.name,
            json.subdomain,
            typeof json.address === 'string' ? json.address : {
                street: json["address.street"],
                houseNumber: json["address.houseNumber"],
                city: json["address.city"],
                postalCode: json["address.postalCode"],
                countryId: json["address.countryId"]
            }
        )
    }
}
