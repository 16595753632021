<div class="basisdaten-wrapper">
<default-card>
<div style = "width: 545px">
    <div style="font-size: 24px; font-weight: bold; padding-bottom: 16px">Impressum</div>
    <form *ngIf="form$ | async; let form" [formGroup]="basisdatenFormGroup" style="padding-bottom: 31px">
        <div class="editor">
            <ngx-editor-menu  [editor]="editor" [toolbar]="toolbar">
            </ngx-editor-menu>
            <ngx-editor [editor]="editor" formControlName="editorContent">
            </ngx-editor>
        </div>
    </form>
    <div style="display: flex; flex-direction: row; justify-content: end;">
    <button style="margin-right: 16px; font-weight: bold; letter-spacing: 1.25px;" color="accent" mat-button (click)="abort()">ABBRECHEN</button>
    <button  style="font-weight: bold;  letter-spacing: 1.25px;" [disabled]="disableSaveButton()" mat-raised-button color="accent" (click)="submitContent()">SPEICHERN</button>
    </div>
</div>
</default-card>
</div>