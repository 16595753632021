

export class Styles{
    constructor(
        public primaryColor: string,
        public secondaryColor: string,
        public textColorOnPrimaryColor: string,
        public textColorOnSecondaryColor: string,
        public font: string,
        public darkMode: true,
        public logo: string,
        public logoUrl: string
    ) {
    }

    public static fromJson(json: any){
        return new Styles(
            json.primaryColor,
            json.secondaryColor,
            json.textColorOnPrimaryColor,
            json.textColorOnSecondaryColor,
            json.font,
            json.darkMode,
            json.logo,
            json.logoUrl
        )
    }
}
