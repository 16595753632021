

export class RegularOpeningTimes{
    constructor(
        public monday: [{ from: string, to: string }],
        public tuesday: [{ from: string, to: string }],
        public wednesday: [{ from: string, to: string }],
        public thursday: [{ from: string, to: string }],
        public friday: [{ from: string, to: string }],
        public saturday: [{ from: string, to: string }],
        public sunday: [{ from: string, to: string }],
    ) {
    }

    static fromJson(json: any): RegularOpeningTimes {
        return new RegularOpeningTimes(
           json.monday?.map((time: any) => {return {from: time.from, to: time.to}}),
            json.tuesday?.map((time: any) => {return {from: time.from, to: time.to}}),
            json.wednesday?.map((time: any) => {return {from: time.from, to: time.to}}),
            json.thursday?.map((time: any) => {return {from: time.from, to: time.to}}),
            json.friday?.map((time: any) => {return {from: time.from, to: time.to}}),
            json.saturday?.map((time: any) => {return {from: time.from, to: time.to}}),
            json.sunday?.map((time: any) => {return {from: time.from, to: time.to}}),
        )
    }
}

export class SingleIrregularOpeningTime{
    constructor(
        public id: string,
        public date: string,
        public times: [{from: string, to: string}],
        public description: string
    ) {}

    static fromJson(json: any): SingleIrregularOpeningTime {
        return new SingleIrregularOpeningTime(
            json.id,
            json.date,
            json.times?.map((time:any) => {return{from: time.from, to: time.to}}),
            json.description,
        )
    }
}

export class IrregularOpeningTimes{
    constructor(public irOpTimes: SingleIrregularOpeningTime[] ){}

    static fromJson(json: any): IrregularOpeningTimes {
        return new IrregularOpeningTimes(
           json.map((singleTime: SingleIrregularOpeningTime)=> SingleIrregularOpeningTime.fromJson(singleTime))
        )
    }
}