

export class Registration {
    constructor(
        public id: string,
        public firstName: string,
        public lastName: string,
        public email: string,
        public phoneNumber: string,
        public password: string,
        public confirmPassword: string,
        public address: AddressReg,) {
    }

    static fromJson(json: any, address?: any): Registration {
        return new Registration(
            json.id,
            json.firstName,
            json.lastName,
            json.email,
            json.phoneNumber,
            json.password,
            json.confirmPassword,
            AddressReg.fromJson(address ?? json.address),
        )
    }
}

    export class AddressReg {
        constructor(
            public id: string,
            public street: string,
            public houseNumber: string,
            public city: string,
            public countryId: string,
            public postalCode: string,
        ) {
        }

        static fromJson(json: any,): AddressReg {
            return new AddressReg(
                json.id,
                json.street,
                json.houseNumber,
                json.city,
                json.countryId,
                json.postalCode
            )
        }
}

export class VerificationMail{
    constructor(public email: string, public callbackUrl: string) {
    }

    public static fromJson(json:any){
        return new VerificationMail(json.email, json.callbackUrl)
    }
}

