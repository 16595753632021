import {NgModule} from "@angular/core";
import {CommonModule, NgFor} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxEditorModule} from "ngx-editor";
import {MatSelectModule} from "@angular/material/select";
import {DefaultCardModule} from "../../ui/default-card/default-card.module";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {NgxColorsModule} from "ngx-colors";
import {MatIconModule} from "@angular/material/icon";
import {ColorFormsComponent} from "./color-forms.component";
import {TourAnchorMatMenuDirective} from "ngx-ui-tour-md-menu";

@NgModule({
    declarations: [
        ColorFormsComponent,
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        ReactiveFormsModule,
        NgxEditorModule,
        MatSelectModule,
        FormsModule,
        NgFor,
        DefaultCardModule,
        MatSlideToggleModule,
        NgxColorsModule,
        MatIconModule,
        TourAnchorMatMenuDirective
    ],
    exports: [
        ColorFormsComponent
    ]
})
export class ColorFormsModule {
}
