import {Component, ElementRef, HostListener, Input} from "@angular/core";
import { FormGroup} from "@angular/forms";


@Component(
    {
        selector: 'color-forms',
        templateUrl: './color-forms.component.html',
        styleUrls: ['./color-forms.component.scss']
    })
export class ColorFormsComponent{
    @Input() stylesFormGroup!: FormGroup;
    @Input() label: string = "";
    @Input() control: any = "";
    @Input() index!: number;
    constructor(private eRef: ElementRef) {
    }

    valueChanged(){
        let value = this.control.value
        value = value.includes('#') ? "#" + value : value;
    }

}