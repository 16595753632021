import {AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {TenantInfoService} from "../tenant/tenant-info.service";
import {ScriptLoaderService} from "./scriptloader.service";
import {firstValueFrom} from "rxjs";
import {FreshChatMessengerConfigWindow, FreshChatUserGetResponse, FreshChatWindow} from "./freshchat.type";
import {DOCUMENT} from "@angular/common";

@Component(
    {
        selector: 'freshchat',
        templateUrl: './freshchat.component.html',
    }
)
export class FreshchatComponent implements AfterViewInit, OnDestroy {
    constructor(private _tenantInfoService: TenantInfoService, private _scriptLoaderService: ScriptLoaderService, @Inject(DOCUMENT) private _document: Document) {
    }

    async ngAfterViewInit(): Promise<void> {
        const tenantInfo = await firstValueFrom(this._tenantInfoService.getTenantInfo());
        if (!tenantInfo.id) {
            throw new Error("TenantInfo has no id while trying to load freshchat");
        }
        const restoreId = await firstValueFrom(this._tenantInfoService.getTenantRestoreId());

        const freshChatWindow = window as unknown as any;

        console.log("restore id", restoreId)

        freshChatWindow.fcWidgetMessengerConfig = {
            externalId: tenantInfo.id,
            restoreId: restoreId ? restoreId : null,
        }

        freshChatWindow.fcSettings = {
            host: "https://google.com/", // This is important
            onInit: async () => {
                console.log(tenantInfo.id)
                freshChatWindow.fcWidget.setExternalId(tenantInfo.id);
                freshChatWindow.fcWidget.user.get(
                    (response: any) => {
                        console.log("user response", response)
                        if (response.status !== 200) {
                            freshChatWindow.fcWidget.user.setProperties({
                                "firstName": tenantInfo.firstName,
                                "lastName": tenantInfo.lastName,
                                "email": tenantInfo.email,
                            });
                            freshChatWindow.fcWidget.on("user:created", async (response: FreshChatUserGetResponse) => {
                                console.log("saving restore id", response.data.restoreId)
                                await firstValueFrom(this._tenantInfoService.setTenantRestoreId(response.data.restoreId));
                            })
                        }
                    },
                    (err: any) => {
                        freshChatWindow.fcWidget.user.setProperties({
                            "firstName": tenantInfo.firstName,
                            "lastName": tenantInfo.lastName,
                            "email": tenantInfo.email,
                        })
                    }
                )

            }
        }

        const scriptTag = this._document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.src = "https://eu.fw-cdn.com/12456325/513590.js";
        scriptTag.async = true;
        this._document.getElementsByTagName('head')[0].appendChild(scriptTag);


    }

    ngOnDestroy(): void {
        const freshChatWindow = window as unknown as any;
        if (freshChatWindow.fcWidget) {
            freshChatWindow.fcWidget.destroy()
        }
        console.log("destroying freshchat")
    }
}
