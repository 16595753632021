<span [formGroup]="pFormGroup">
    <div class="from-to-fields">
   <mat-form-field color="accent" class="my-form-field" appearance="outline">
       <mat-label>Von</mat-label>
    <input matInput
           (change)="onChange(pFormGroup.get('from'))"
           maxlength="5"
           name="selected_time_B"
           formControlName="from"
           placeholder="10:00"/>
    <mat-icon  style="padding: 0 4px 0 4px" matSuffix  [ngxMatTimepicker]="pickerB" [format]="24">
       schedule
    </mat-icon>
</mat-form-field>
<ngx-mat-timepicker color="accent"
                    (timeSet)="updateTime($event,'from' )"
                    #pickerB></ngx-mat-timepicker>
    <div style="border: 1px solid #191C19; width: 10px; height: 0; margin: 0 5px;"></div>
     <mat-form-field color="accent" class="my-form-field" appearance="outline">
       <mat-label>Bis</mat-label>
    <input matInput
           (change)="onChange(pFormGroup.get('to'))"
           name="selected_time_C"
           formControlName="to"
           placeholder="10:00"/>
    <mat-icon style="padding: 0 4px 0 4px" matSuffix
              [ngxMatTimepicker]="pickerC" [format]="24">
       schedule
    </mat-icon>
</mat-form-field>
<ngx-mat-timepicker color="accent"
                    (timeSet)="updateTime($event,'to' )"
                    #pickerC></ngx-mat-timepicker>
         <span class="delete-button" (click)="deleteFromTo()"><mat-icon style="font-size: 18px">close</mat-icon></span>
        </div>
    <div *ngIf="!pFormGroup.get('from')?.errors && !pFormGroup.get('to')?.errors" style="height: 21px"></div>
    <mat-error *ngIf="getErrorMessage() == 'pattern'" >{{ValidationMessages.WRONG_TIME}}</mat-error>
    <mat-error *ngIf="getErrorMessage() == 'required'" >{{ValidationMessages.REQUIRED}}</mat-error>
</span>
