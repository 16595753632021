import {NgModule} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {BasisdatenComponent} from "./basisdaten.component";
import { NgxEditorModule } from 'ngx-editor';
import {BasisdatenDatasource} from "./basisdaten.datasource";
import {DefaultCardModule} from "../ui/default-card/default-card.module";


@NgModule({
    declarations: [
        BasisdatenComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        ReactiveFormsModule,
        NgxEditorModule,
        FormsModule,
        DefaultCardModule,
    ],
    providers:[
        BasisdatenDatasource
    ]
})
export class BasisdatenModule {
}