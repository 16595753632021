import {Injectable} from "@angular/core";
import {Endpoint} from "../../endpoints.definition";
import {AddressReg, Registration, VerificationMail} from "./registration.type";
import {CRUDDatasource} from "../../shared/datasource/crud-datasource.abstract";
import {environment} from "../../environments/environment";


export class RegistrationDTO {
    constructor(
        public id: string,
        public firstName: string,
        public lastName: string,
        public email: string,
        public phoneNumber: string,
        public password: string,
        public confirmPassword: string,
        public callbackUrl: string,
        public address: AddressReg,
        ) {
    }

    static fromJson(json: any, address?: any): RegistrationDTO {
        return new RegistrationDTO(
            json.id,
            json.firstName,
            json.lastName,
            json.email,
            json.phoneNumber,
            json.password,
            json.confirmPassword,
            json?.callbackUrl,
        AddressReg.fromJson(address ?? json.address),
        )
    }
}



@Injectable()
export class RegistrationDatasource extends CRUDDatasource<Registration,  Registration, Registration> {
    readonly path = Endpoint.REGISTRATION;
    readonly serializer = Registration.fromJson;

    createTenant(regist: Registration){
        const registDTO = RegistrationDTO.fromJson(regist)
        registDTO.callbackUrl =  environment.ccUrl + '/verify-mail'
        console.log(registDTO)
       return this.create(registDTO);
    }
}

@Injectable()
export class SendVerificationMailDatasource extends CRUDDatasource<VerificationMail,  VerificationMail, VerificationMail> {
    readonly path = Endpoint.SEND_VERIFICATION_MAIL;
    readonly serializer = VerificationMail.fromJson;

    send(verificationMail: VerificationMail){
        return this.http.post(this.path, verificationMail, {responseType: 'text' as 'json'})
    }
}
