import {Injectable} from "@angular/core";
import {PasswordConfiguratorDatasource} from "./password-configurator.datasource";
import {PasswordConfig} from "./password-configurator.types";

@Injectable()
export class PasswordConfiguratorService {
    constructor(private passwordResetDatasource: PasswordConfiguratorDatasource) {
    }

    public changePassword(request: PasswordConfig, token: string){
        return this.passwordResetDatasource.changePassword(request, token);
    }

}