import {Component} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Contact, Country} from "./contact.type";
import {ContactService} from "./contact.service";
import {firstValueFrom, Observable, ReplaySubject, tap} from "rxjs";
import {ValidationMessages} from "../../shared/Validation/validation.messages";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Address} from "../address/address.type";
import {FormGroupComponentInterface} from "../ui/CanDeactivateGuard/formgroupcomponent.interface";

@Component({
    selector: 'contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements FormGroupComponentInterface {
    actualCountry!: Country;
    addressFormGroup!: FormGroup;
    contactFormGroup!: FormGroup;
    MOBILE_PATTERN = /^[^A-Za-z]+$/
    waitingForResponse: boolean = false;

    _currentContactSubject: ReplaySubject<Contact> = new ReplaySubject(1);
    currentContact$ = this._currentContactSubject.asObservable();
    contact$ = this.contactService.getContact().pipe(tap((contact) => {
        this.buildContactFormGroup(contact);
        this.buildAddressFormGroup(contact.address);
        this.actualCountry = contact.address.country;
        this._currentContactSubject.next(contact);
    }));

    constructor(private contactService: ContactService, private snackbar: MatSnackBar) {
    }

    buildContactFormGroup(initialValue: Contact) {
        this.contactFormGroup = new FormGroup<any>({
            name: new FormControl(initialValue.name ?? ''),
            contactNumber: new FormControl(initialValue.contactNumber, [Validators.pattern(this.MOBILE_PATTERN)] ?? ''),
            mail: new FormControl(initialValue.mail, [Validators.email] ?? ''),
        });
    }

    buildAddressFormGroup(initialValue: Address) {
        this.addressFormGroup = new FormGroup<any>({
            id: new FormControl(initialValue.id ?? ''),
            street: new FormControl(initialValue.street ?? ''),
            houseNumber: new FormControl(initialValue.houseNumber ?? ''),
            city: new FormControl(initialValue.city ?? ''),
            postalCode: new FormControl(initialValue.postalCode ?? ''),
        });
    }

    async onSubmitContact() {
        this.contactFormGroup.markAllAsTouched();
        if (!this.contactFormGroup.valid) {
            this.snackbar.open(ValidationMessages.FAILURE, ValidationMessages.OK)
            return
        }
        const value = this.addressFormGroup.getRawValue();
        const address = new Address(
            value.id,
            value.street,
            value.houseNumber,
            value.city,
            this.actualCountry,
            value.postalCode,
        )
        const contactValue = this.contactFormGroup.getRawValue();
        const contact = new Contact(
            contactValue.name,
            address,
            contactValue.contactNumber,
            contactValue.mail
        )
        this.contactFormGroup.markAsPristine();
        this.addressFormGroup.markAsPristine();
        this.waitingForResponse = true;
        try {
            const updatedContact = await firstValueFrom(this.contactService.updateContact(contact));
            this._currentContactSubject.next(updatedContact);
            this.snackbar.open(ValidationMessages.SUCCES, ValidationMessages.OK, {duration: 5000, panelClass: ['success-snackbar']})
        }
        catch(err){
            this.snackbar.open(ValidationMessages.HTTP_FAILURE, ValidationMessages.OK)
            this.contactFormGroup.markAsDirty();
            this.addressFormGroup.markAsDirty();
        }
        this.waitingForResponse= false;
    }

    async abort() {
        const originalData = await firstValueFrom(this.currentContact$);
        this.contactFormGroup.get('name')?.setValue(originalData.name)
        this.contactFormGroup.get('contactNumber')?.setValue(originalData.contactNumber)
        this.contactFormGroup.get('mail')?.setValue(originalData.mail)
        this.addressFormGroup.get('street')?.setValue(originalData.address.street)
        this.addressFormGroup.get('houseNumber')?.setValue(originalData.address.houseNumber)
        this.addressFormGroup.get('city')?.setValue(originalData.address.city)
        this.addressFormGroup.get('postalCode')?.setValue(originalData.address.postalCode)
        this.contactFormGroup.markAsPristine();
        this.addressFormGroup.markAsPristine();
    }

    protected readonly ValidationMessages = ValidationMessages;

    getFormGroups(): FormGroup[] {
        return [ this.contactFormGroup, this.addressFormGroup ];
    }

    disableSaveButton(){
       return !((this.contactFormGroup?.dirty || this.addressFormGroup?.dirty) && this.contactFormGroup?.valid && this.addressFormGroup?.valid && !this.waitingForResponse )
    }
}
