import {Component, ViewChild} from "@angular/core";
import {FormGroupComponentInterface} from "../ui/CanDeactivateGuard/formgroupcomponent.interface";
import {FormGroup} from "@angular/forms";
import {HeroComponent} from "./hero/hero.component";
import {StylesComponent} from "./styles/styles.component";
import {IntroductionComponent} from "./introduction/introduction.component";

@Component(
    {
        selector: 'gestaltung',
        templateUrl: './gestaltung.component.html',
        styleUrls: ['./gestaltung.component.scss']
    }
)
export class GestaltungComponent implements FormGroupComponentInterface {

    @ViewChild(HeroComponent) heroComponent!: HeroComponent;
    @ViewChild(StylesComponent) stylesComponent!: StylesComponent;
    @ViewChild(IntroductionComponent) introductionComponent!: IntroductionComponent;
    getFormGroups(): FormGroup[] {
        return [...this.heroComponent.getFormGroups(), ...this.stylesComponent.getFormGroups(), ...this.introductionComponent.getFormGroups()];
    }

}

