import {Address} from "./address.type";
import {FormControl, FormGroup, Validators} from "@angular/forms";

interface AddressForm {
    id: FormControl<string | null>,
    street: FormControl<string | null>,
    houseNumber: FormControl<string | null>,
    city: FormControl<string | null>,
    postalCode: FormControl<string | null>,
    countryId: FormControl<string | null>,
}


const buildAddressFormGroup = (initialValue: Address | null | undefined, required = false) => {
    const LETTER_PATTERN = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
    return new FormGroup<AddressForm>({
        id: new FormControl<string>(initialValue?.id ?? ''),
        street: new FormControl<string>(initialValue?.street ?? '', required ? [Validators.required, Validators.pattern(LETTER_PATTERN)] : []),
        houseNumber: new FormControl<string>(initialValue?.houseNumber ?? '', required ? [Validators.required] : []),
        city: new FormControl<string>(initialValue?.city ?? '', required ? [Validators.required, Validators.pattern(LETTER_PATTERN)] : []),
        postalCode: new FormControl<string>(initialValue?.postalCode ?? '', required ? [Validators.required] : []),
        countryId: new FormControl(initialValue?.country?.id ?? ""),
    });
}

export {buildAddressFormGroup, AddressForm}
