import {Component} from "@angular/core";

@Component({
  selector: 'scalable-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
  }
)
export class PanelComponent {
  constructor() {
  }
}
