import {Injectable} from "@angular/core";

import { CategoryCreateDto} from "../../restaurant/restaurant";
import {FlavoroHttpClient} from "../../../shared/http/http.client";
import {Category} from "./category.types";
import {CategoryDatasource, UpdateCategoryDTO} from "./category.datasource";
import {MenuItem} from "../menu-item/menu-item.types";

@Injectable()
export class CategoryService {

    constructor(private categoryDatasource: CategoryDatasource) {
    }

    getCategories() {
        return this.categoryDatasource.getAll();
    }

    createCategory(category: CategoryCreateDto) {
        return this.categoryDatasource.create(category);
    }

    updateCategory(category: UpdateCategoryDTO) {
        return this.categoryDatasource.update(category, category.id);
    }

    deleteCategory(categoryId: string) {
        return this.categoryDatasource.delete(categoryId);
    }

    reorderMenuItems(categoryId: string, menuItems: MenuItem[]) {
        return this.categoryDatasource.reorderMenuItems(categoryId, menuItems);
    }
}
