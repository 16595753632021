import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {Router, RouterModule} from "@angular/router";
// import * as Sentry from "@sentry/angular" // for Angular 10/11 instead
import * as Sentry from "@sentry/angular-ivy";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthInterceptor} from "../features/auth/auth.interceptor";
import {MenuModule} from "../features/menu/menu.module";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {ContactModule} from "../features/contact/contact.module";
import {GestaltungModule} from "../features/gestaltung/gestaltung.module";
import {OpeningTimesModule} from "../features/opening-times/opening-times.module";
import {FlavoroUiModule} from "../features/ui/flavoro-ui.module";
import {MatButtonModule} from "@angular/material/button";
import {BasisdatenModule} from "../features/basisdaten/basisdaten.module";
import {LoginModule} from "../features/login/login.module";
import {FlavoroHttpClient} from "../shared/http/http.client";
import {PasswordResetModule} from "../features/password-reset/password-reset.module";
import {DefaultCardModule} from "../features/ui/default-card/default-card.module";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {RegistrationModule} from "../features/registration/registration.module";
import {RestaurantModule} from "../features/restaurant/restaurant.module";
import {SetupInterceptor} from "../features/restaurant/setup/setup.interceptor";
import {
    PasswordConfiguratorModule
} from "../features/password-reset/password-configurator/password-configurator.module";
import {VerifyMailModule} from "../features/registration/verify-mail/verify-mail.module";
import {TenantSetupInterceptor} from "../features/registration/setup-tenant/setup.interceptor";
import HomeModule from "../features/Routes/home/home.module";
import WelcomeModule from "../features/Routes/welcome/welcome.module";
import {SubscribeModule} from "../features/subscribe/subscribe.module";
import {PageNotFoundModule} from "../features/404page/page-not-found.module";
import {TourMatMenuModule, TourStepTemplateComponent} from "ngx-ui-tour-md-menu";
import {MatCardModule} from "@angular/material/card";
import {FreshchatComponent} from "../features/Freshchat/freshchat.component";
import FreshchatModule from "../features/Freshchat/freshchat.module";


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatButtonModule,
        FlavoroUiModule,
        MenuModule,
        ContactModule,
        GestaltungModule,
        OpeningTimesModule,
        BasisdatenModule,
        LoginModule,
        PasswordResetModule,
        DefaultCardModule,
        MatSnackBarModule,
        RestaurantModule,
        RegistrationModule,
        PasswordConfiguratorModule,
        VerifyMailModule,
        HomeModule,
        WelcomeModule,
        SubscribeModule,
        PageNotFoundModule,
        TourMatMenuModule,
        TourStepTemplateComponent,
        MatCardModule,
        RouterModule,
        FreshchatModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: SetupInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: TenantSetupInterceptor, multi: true},
        FlavoroHttpClient,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
