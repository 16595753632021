import {NgModule} from '@angular/core';
import {HeroModule} from "./hero/hero.module";
import {IntroductionModule} from "./introduction/introduction.module";
import {StylesModule} from "./styles/styles.module";
import {GestaltungComponent} from "./gestaltung.component";


@NgModule({
    declarations: [
        GestaltungComponent,
    ],
    imports: [
        HeroModule,
        IntroductionModule,
        StylesModule,
    ],
    providers:[
    ]
})
export class GestaltungModule {
}
