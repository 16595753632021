export class Endpoint{
    public static TENANTS = "/cc/tenants"
    public static RESTAURANTS = "/cc/restaurant"
    public static STYLES = "/cc/restaurant/style"
    public static OPENING_TIMES_REGULAR = "/cc/restaurant/opening-times/regular"
    public static OPENING_TIMES_IRREGULAR = "/cc/restaurant/opening-times/irregular"
    public static MENUES = "/cc/restaurant/menues"
    public static MENU_ITEMS =  "/cc/restaurant/menu-items"
    public static CATEGORY="/cc/restaurant/categories"
    public static ITRODUCTION="/cc/restaurant/introduction"
    public static HEROSECTIONS="/cc/restaurant/heroSection"
    public static COUNTRIES_ADMIN="/public/countries/admin"
    public static ADRESSE="/cc/adresses"
    public static CONTACT: string = "/cc/restaurant/contact"
    public static LEGAL_NOTICE: string="/cc/restaurant/legal-notice"
    public static REGISTRATION: string="/public/registration"
    public static GET_DOMAIN: string = "/cc/restaurant/domain"
    public static REQUEST_PASS_RESET = "/public/email/requestPasswordReset"
    public static RESET_PASSWORD = "/public/email/resetPassword"
    public static VERIFY_EMAIL = "/public/email/verifyEmail/"
    public static ALLERGENES = "/public/allergen"
    public static LOGIN = "/public/cc/login"
    public static REFRESH = "/public/auth/refresh"
    public static MEDIA = "/cc/restaurant/media"
    public static SETUP_TENANT = "/public/registration/tenant"
    public static SETUP_RESTAURANT = "/cc/restaurants/setup"
    public static SEND_VERIFICATION_MAIL = "/public/email/sendVerificationEmail"
    public static GET_PLACEHOLDER:string = "/public/PlaceholderHeroImage"
    public static PUBLIC_MEDIA: string = "/public/media"
}