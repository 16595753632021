import {Component, OnDestroy, OnInit} from "@angular/core";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {Editor, toDoc, Toolbar} from "ngx-editor";
import {firstValueFrom, ReplaySubject, tap} from "rxjs";
import {MediaService, MediaType} from "../../media/media.service";
import {IntroductionService} from "./introduction.service";
import {Introduction} from "./introduction.type";
import {UpdateIntroductionDTO} from "./introduction.datasource";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ValidationMessages} from "../../../shared/Validation/validation.messages";
import {editorLengthValidator} from "../../../shared/Validation/validation.editorLength";
import {FormGroupComponentInterface} from "../../ui/CanDeactivateGuard/formgroupcomponent.interface";

@Component(
    {
        selector: 'introduction',
        templateUrl: './introduction.component.html',
        styleUrls: ['./introduction.component.scss']
    }
)
export class IntroductionComponent implements OnInit, OnDestroy, FormGroupComponentInterface {
    introductionFormGroup!: FormGroup;
    introductionImageLink: string = '';
    introductionImageId: string = '';
    imageError = false;
    imageErrorMessage = "";
    isLoading = false;
    waitingForResponse = false;
    editor!: Editor;
    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['link'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    constructor(private mediaService: MediaService, private introductionService: IntroductionService, private snackbar: MatSnackBar) {
    }

    private _currentIntroductionSubject: ReplaySubject<Introduction> = new ReplaySubject(1);
    currentIntroduction$ = this._currentIntroductionSubject.asObservable();


    introduction$ = this.introductionService.getIntroduction().pipe(tap((introduction) =>{
            this._currentIntroductionSubject.next(introduction);
            this.introductionFormGroup = this.buildIntroductionFormGroup(introduction);
            this.introductionImageLink = introduction.imageUrl;
            this.introductionImageId = introduction.image;
        }
    ))

    ngOnInit() {
        this.editor = new Editor(

        );
    }

    // make sure to destory the editor
    ngOnDestroy() {
        this.editor.destroy();
    }

    buildIntroductionFormGroup(introduction?: Introduction) {
        return new FormGroup<any>({
            introHeader: new FormControl(introduction?.header),
            introBody: new FormControl(introduction?.body,[editorLengthValidator()]),
        });
    }

    async submitIntroduction() {
        console.log(toDoc(this.introductionFormGroup.get('introBody')?.value))
        if(!this.introductionFormGroup.valid || this.imageError){
            this.snackbar.open(ValidationMessages.FAILURE, ValidationMessages.OK)
            return;
        }
        let introduction: UpdateIntroductionDTO = new UpdateIntroductionDTO(
            this.introductionFormGroup.getRawValue().introHeader,
            this.introductionFormGroup.getRawValue().introBody,
            this.introductionImageId
        )
        this.introductionFormGroup.markAsPristine();
        this.waitingForResponse = true;
        try{ const updateIntro = await firstValueFrom(this.introductionService.uploadIntroduction(introduction))
            this._currentIntroductionSubject.next(updateIntro);
            this.snackbar.open(ValidationMessages.SUCCES, ValidationMessages.OK, {duration: 5000, panelClass: ['success-snackbar']})
          }
        catch(err){
            this.snackbar.open(ValidationMessages.HTTP_FAILURE, ValidationMessages.OK)
            this.introductionFormGroup.markAsDirty();
        }
        this.waitingForResponse = false;
    }

    async abortIntroduction() {
        const originalData = await firstValueFrom(this.currentIntroduction$);
        this.introductionFormGroup.get('introHeader')?.setValue(originalData.header);
        this.introductionFormGroup.get('introBody')?.setValue(originalData.body);
        this.introductionImageLink = originalData.imageUrl;
        this.introductionImageId = originalData.image;
        this.introductionFormGroup.markAsPristine();
    }

    async onIntroductionImageSelected($event: Event) {
        const file: File = ($event?.target as any)?.files[0];
        if (!file) {
            return
        }
        let imageValid = true;

        if (!(file?.type == "image/jpeg" || file?.type == "image/png" || file?.type == "image/svg+xml")) {
            imageValid = false;
            this.imageError = true
            this.introductionImageLink = "/assets/placeholder-image.jpeg";
            this.imageErrorMessage = "Bitte ein Bild mit Dateityp ”png”,\"svg\" oder “jpg“ wählen"

        }
        if (!(file?.size <= 10000000)) {
            imageValid = false;
            this.imageError = true
            this.introductionImageLink = "/assets/placeholder-image.jpeg";
            this.imageErrorMessage = "Das Bild ist zu groß. Maximale Größe beträgt 10MB"
        }

        if (imageValid) {
            this.isLoading = true;
            this.imageError = false
            try {
                const uploadedImage = await firstValueFrom(this.mediaService.uploadImage(file, MediaType.INTRODUCTION))
                this.introductionImageId = uploadedImage.imageId;
                this.introductionImageLink = uploadedImage.path;
                this.isLoading = false
                this.introductionFormGroup.markAsDirty()
            } catch (err) {
                this.snackbar.open(ValidationMessages.IMAGE_NOT_UPLOADED, ValidationMessages.OK);
                this.isLoading = false
            }
        }
    }

    getFormGroups(): FormGroup[] {
        return [this.introductionFormGroup]
    }

    disableSaveButton(){
        return !(this.introductionFormGroup?.dirty && this.introductionFormGroup?.valid  && !this.waitingForResponse);
    }

}
