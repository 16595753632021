import {Injectable} from "@angular/core";
import {RMAbstractDataSource} from "../../../shared/datasource/rm-datasource.abstract";
import {Endpoint} from "../../../endpoints.definition";
import {Hero} from "./hero.type";

export class UpdateHeroDTO{
    constructor(
        public body: string,
        public imageId: string,
    ) {
    }

    private static fromJson(json: any){
        return new UpdateHeroDTO(
            json.body,
            json.imageId,
        )
    }
}

@Injectable()
export class HeroDatasource extends RMAbstractDataSource<Hero, UpdateHeroDTO> {
    readonly path = Endpoint.HEROSECTIONS;
    readonly serializer = Hero.fromJson;
}