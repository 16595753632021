import {Component, Inject, inject, Input,} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
    selector: 'transfer-dialog',
    templateUrl: './transfer-dialog.component.html',
    styleUrls: ['./transfer-dialog.component.scss']
})
export class TransferDialogComponent {
    selectedFormGroup: FormGroup = new FormGroup<any>(
        {selectedDays: new FormControl()}
    )
    selectedDays!: string[];
    selectedDaysWithGerman: [{key: string, value: string}] = [{key: "", value:""}]
    constructor(public dialogRef: MatDialogRef<TransferDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
            this.selectedDays = data.selectedDays;
            console.log(this.selectedDays)
            this.selectedDaysWithGerman.pop()
            this.selectedDays.map(
                (day) =>{this.selectedDaysWithGerman.push({key: day , value: this.getLocalizedDay(day)})}
            )
    }

    getLocalizedDay(day: string): string {
        switch (day) {
            case "monday":
                return "Montag";
            case "tuesday":
                return "Dienstag";
            case "wednesday":
                return "Mittwoch";
            case "thursday":
                return "Donnerstag";
            case "friday":
                return "Freitag";
            case "saturday":
                return "Samstag";
            case "sunday":
                return "Sonntag";
        }

        return day
    }
    close(){
        console.log(this.selectedFormGroup.value)
        this.dialogRef.close(this.selectedFormGroup.value);
    }
}