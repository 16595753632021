import {Injectable} from "@angular/core";
import {FlavoroHttpClient} from "../../shared/http/http.client";
import {map, Observable} from "rxjs";
import Country from "./country.types";

@Injectable({providedIn: 'root'})
export default class CountryDatasource {

    constructor(private http: FlavoroHttpClient) {
    }


    getGermany(): Observable<Country> {
        return this.http.get("/public/countries/germany").pipe(
            map((json: any) => {
                return Country.fromJson(json)
            })
        )
    }
}
