import {NgModule} from "@angular/core";
import {HeroComponent} from "./hero.component";
import {AsyncPipe, NgIf, NgStyle} from "@angular/common";
import {DefaultCardModule} from "../../ui/default-card/default-card.module";
import {FlavInputModule} from "../../ui/flav-input/flav-input.module";
import {MatButtonModule} from "@angular/material/button";
import {ReactiveFormsModule} from "@angular/forms";
import {HeroDatasource} from "./hero.datasource";
import {HeroService} from "./hero.service";
import {FlavoroUiModule} from "../../ui/flavoro-ui.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {TourAnchorMatMenuDirective} from "ngx-ui-tour-md-menu";


@NgModule({
    declarations: [HeroComponent],
    exports: [
        HeroComponent
    ],
    imports: [
        AsyncPipe,
        DefaultCardModule,
        FlavInputModule,
        MatButtonModule,
        NgIf,
        ReactiveFormsModule,
        FlavoroUiModule,
        NgStyle,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        TourAnchorMatMenuDirective
    ],
    providers:[
    HeroDatasource,
        HeroService
    ]
})
export class HeroModule{
}