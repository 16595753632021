<div class="pr-wrapper">
    <img style="width: 300px;height: 200px; object-fit: cover; padding-top: 64px" src="../../assets/OrangeRegular.svg"/>
<default-card>
    <ng-container *ngIf="passForgot; else passwordCompleted">
<div style="display: flex; flex-direction: column" [formGroup]="prGroup">
    <h1 style="font-weight: bold">Passwort vergessen</h1>
    <mat-form-field color="accent">
        <mat-label>
            Gib deine Email an.
        </mat-label>
        <input matInput formControlName="email">
        <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <div class="buttons">
    <button mat-button color="accent" style="margin-right: 8px" (click)="backToLogin()">Abbrechen</button>
        <loading-button [buttonType]="buttonTypes.raised" text="Passwort zurücksetzen" (bClick)="resetPass(false)"  width="204px" [isNotLoading]="isNotLoading" ></loading-button>
    </div>
</div>
    </ng-container>
</default-card>
</div>


<ng-template #passwordCompleted>
    <h1 style="font-weight: bold">Schau in dein Postfach</h1>
    <div>Es wurde eine Email zur Passwortwiederherstellung an deine Email Adresse geschickt.</div>
    <div class="email-again"> <loading-button  [buttonType]="buttonTypes.text" text="Email erneut senden" (bClick)="resetPass(true)" height="30" width="110px" [isNotLoading]="isNotLoading" ></loading-button></div>
    <div></div>
    <button mat-raised-button color="accent" (click)="backToLogin()">Zum Login</button>
</ng-template>