import {Injectable} from "@angular/core";
import {RegistrationDatasource, SendVerificationMailDatasource} from "./registration.datasource";
import {Registration, VerificationMail} from "./registration.type";


@Injectable()
export class RegistrationService {

    constructor(private RegistrationDatasource: RegistrationDatasource, private sendVerificationMailDatasource: SendVerificationMailDatasource) {
    }

    public signIn(regist: Registration) {
        return this.RegistrationDatasource.createTenant(regist);
    }
    public sendVerificationMail(verificationMail: VerificationMail){
        return this.sendVerificationMailDatasource.send(verificationMail);
    }
}