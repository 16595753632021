import {Injectable} from "@angular/core";
import {Observable, shareReplay} from "rxjs";
import {FlavoroHttpClient} from "../../../shared/http/http.client";
import {Endpoint} from "../../../endpoints.definition";
import {Allergen} from "./allergen.types";

@Injectable({
    providedIn: 'root'
})
export class AllergenService {

    $allergene: Observable<Allergen[]> | undefined;


    constructor(private http: FlavoroHttpClient) {
    }

    getAllergene(): Observable<Allergen[]> {
        if (!this.$allergene) {
            this.$allergene = this.http.get<Allergen[]>(Endpoint.ALLERGENES).pipe(shareReplay(1));
        }
        return this.$allergene;
    }


}
