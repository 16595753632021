import {Injectable} from "@angular/core";
import {map, Observable, of, shareReplay, tap} from "rxjs";
import {MenuDatasource} from "./menu.datasource";
import {Menu} from "./menu.types";
import {Category} from "./category/category.types";


@Injectable({
    providedIn: 'root',
})
export class MenuService {


    constructor(private menuDatasource: MenuDatasource) {
    }

    private menu$: Observable<Menu> | undefined;

    getMenu(): Observable<Menu> {
        return this.menuDatasource.getAll().pipe(map(menus => menus[0]), tap(menu => {
            menu.categories = menu.categories.filter(category => category?.id != "")
            menu.categories = menu.categories.map(category => {
                category.menuItems = category?.menuItems.filter(menuItem => menuItem.id != "")
                return category;
            });
        }));
    }

    createMenu(): Observable<Menu>{
        return this.menuDatasource.create({});
    }

    reorderCategories(categories: Category[], menuId: string) {
        return this.menuDatasource.reorder("categories", categories.map(category => category.id), menuId);
    }

    
}
