import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {BehaviorSubject, interval, startWith, Subscription, tap} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class RenderLoadingService {

    private _socket: WebSocket | undefined;
    private _currentRestaurantId: string | null = null;
    private _reconnectInterval: Subscription | null = null;

    private _isRenderingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isRendering$ = this._isRenderingSubject.asObservable().pipe(
        tap(isRendering => {
            console.log("isRendering: " + isRendering)
        })
    );

    private _renderErrorSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    public renderError$ = this._renderErrorSubject.asObservable().pipe(
        tap(renderError => {
            console.log("renderError: " + renderError)
        })
    );

    lastPongReceived: number = Date.now();

    constructor() {
        setInterval(() => {
            if (this._socket && this._socket.readyState === WebSocket.OPEN) {
                this._socket.send("ping");
            }
        }, 20000);

        setInterval(() => {
            if (Date.now() - this.lastPongReceived > 60000 && this._socket && this._socket.readyState === WebSocket.OPEN) {
                console.log("Socket: Closing socket due to inactivity")
                this._socket.close();
            }
        }, 5000);
    }

    public connect(restaurantId: string) {
        this._currentRestaurantId = restaurantId;
        if (this._socket) {
            this._socket.close();
        }
        this._socket = new WebSocket(environment.wsUrl + "?restaurantId=" + restaurantId);

        this._socket.onopen = () => {
            // Clear any existing reconnect intervals
            console.log("Scoket: Opened");
            this.lastPongReceived = Date.now();
            if (this._reconnectInterval) {
                console.log("Socket: Clearing reconnect interval")
                this._reconnectInterval.unsubscribe();
                this._reconnectInterval = null;
            }
        };

        this._socket.onmessage = (event) => {
            if (event.data === "pong") {
                this.lastPongReceived = Date.now();
                return
            }
            const message = JSON.parse(event.data);
            if (message.isRendering !== undefined) {
                this._isRenderingSubject.next(message.isRendering);
            }
            if (message.renderingError !== undefined) {
                this._renderErrorSubject.next(message.renderingError === "" ? null : message.renderingError);
            }
        }

        this._socket.onclose = () => {
            // Start the reconnect interval if it's not already running
            console.log("Socket: Closed");
            if (!this._reconnectInterval && this._currentRestaurantId) {
                console.log("Socket: Starting reconnect interval")
                this._reconnectInterval = interval(10000).pipe(
                    startWith(0),
                ).subscribe(() => {
                    console.log("Socket: Tries reconnecting")
                    this.connect(this._currentRestaurantId!);
                });
            }
        };
    }

}
