<div class="opening-container">
    <scalable-panel style="width: 45%;height: 90%">
        <div heading><h2 class="title" tourAnchor="regularOpening" >Regelmäßige Öffnungszeiten</h2>
            <mat-divider></mat-divider></div>
        <div *ngIf="regularOpeningTimes$ | async; let regularOpeningTimes">
            <div *ngFor="let day of days, let i = index" [formGroup]="regularOpeningTimesFormGroup" style="padding-bottom: 16px">
                <div style="display: flex; flex-direction: row; justify-content: space-between; padding-bottom: 16px">
                    <div class="subtitle">{{getLocalizedDay(day)}}</div>
                    <flav-fab-button [tourAnchor]="i === 0 ? 'transferButton' : ''" iconName="turn_right" (click)="openDialog(true,days, day)"></flav-fab-button>
                </div>

                <div class="from-to-container" >
                    <span class="subtitle" style="padding-right: 16px; padding-bottom: 8px" *ngIf="getTimeFormGroupsForDay(day).length == 0" >(Geschlossen)</span>
                    <ng-container  *ngFor="let control of getTimeFormGroupsForDay(day) let iControl = index">
                        <from-to style="padding-bottom: 8px" [tourAnchor]="iControl === 0 && i == 0 ? 'clickOnClock' : ''"  (updateClock)="setSaveDisable(regularOpeningTimesFormGroup)" [pFormGroup]="control" (groupError)="getGroupError($event)" (delete)="removeTimeControlFromDay(day, control)"></from-to>
                    </ng-container>
                    <button class="hinzufügen-button" [disabled]="timeControlValidate(getFormDay(day))" mat-button (click)="addTimeControlToFormArray(getFormDay(day), true)">+ Zeitraum hinzufügen
                    </button>
                </div>
            </div>
        </div>
        <ng-container actions>
            <button color="accent" class="button" mat-button (click)="abortRegularOpenTime()">ABBRECHEN</button>
            <button color="accent" [disabled]="disableSaveButton(regularOpeningTimesFormGroup)" class="button" style="margin-right: 16px" mat-raised-button (click)="submitRegularOpeningTimes()">SPEICHERN</button>
        </ng-container>
    </scalable-panel>

    <scalable-panel style="width: 45%; height: 90%">
        <div heading><h2 class="title" tourAnchor="irregularOpening" >Außerordentliche Öffnungszeiten</h2>
            <mat-divider></mat-divider></div>
        <div  *ngIf="irregularOpeningTimes$ | async; let regularOpeningTimes">
            <div style="margin-bottom: 16px" *ngFor="let irregularDateGroup of getIrregularOpeningTimesFormGroups(); let i =  index"
                 [formGroup]="irregularDateGroup">
                <div  style="display: flex; flex-direction: row; justify-content: space-between;  padding-bottom: 16px">
                    <variable-form-field  [tourAnchor]="i === 0 ? 'title' : ''" (changeTitle)="validateTitleDate('description')"  [pFormControlName]="'description'" [pFormGroup]="irregularDateGroup" label="Beschreibung" placeholder="Beschreibung" ></variable-form-field>
                    <div style="display: flex; flex-direction: row; gap: 16px; align-items: center;">
                        <flav-fab-button iconName="delete" (click)="removeIrregularOpeningTime(irregularDateGroup)"></flav-fab-button>
                        <flav-fab-button [tourAnchor]="i === 1 ? 'irregularTransfer' : ''"  iconName="turn_right" (click)="openDialog(false,getAllIrregularOpeningTimesDescriptions(), irregularDateGroup.get('description')?.value)"></flav-fab-button>
                    </div>
                </div>

                <div [tourAnchor]="i === 0 ? 'calendar' : ''" style="width: fit-content; height: fit-content; margin-bottom: 8px">
                    <mat-form-field color="accent" appearance="outline">
                        <mat-label>Datum</mat-label>
                        <input (dateChange)="validateTitleDate('date')" matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="date">
                        <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker   #picker ></mat-datepicker>
                        <mat-error *ngIf="irregularDateGroup.get('date')?.hasError('required')">{{ValidationMessages.WRONG_DATE_SHORT}}</mat-error>
                        <mat-error *ngIf="irregularDateGroup.get('date')?.hasError('sameTitle')">{{ValidationMessages.DUPLICATE_DATE}}</mat-error>
                    </mat-form-field>

                </div>
                <div *ngIf="getTimesFormGroups(irregularDateGroup).length == 0" style="width: 100%; font-weight: bold; font-size: 20px; padding-bottom: 8px">(Geschlossen)</div>
                <span class="from-to-container">
                    <ng-container *ngFor="let timeGroup of getTimesFormGroups(irregularDateGroup)" [formGroup]="timeGroup">
                          <from-to style="padding-bottom: 8px" (updateClock)="setSaveDisable(irregularOpeningTimesFormGroup)" [pFormGroup]="timeGroup" (groupError)="getGroupError($event)"
                                   (delete)="removeTimeControlFromIrregular(irregularDateGroup, timeGroup)"></from-to>
                    </ng-container>
                    <button [disabled]="timeControlValidate(getTimesFormArrayIrregular(irregularDateGroup))" class="hinzufügen-button" mat-button
                            (click)="addTimeControlToFormArray(getTimesFormArrayIrregular(irregularDateGroup), false)">+ Zeitraum hinzufügen</button>
                </span>
            </div>
            <button color="accent" class="button"  mat-raised-button (click)="addIrregularOpeningTime()">+ TAG HINZUFÜGEN</button>
        </div>
        <ng-container actions  >
            <button color="accent" mat-button class="button" (click)="abortIrregularOpenTime()">ABBRECHEN</button>
            <button color="accent" [disabled]="disableSaveButton(irregularOpeningTimesFormGroup)" class="button" style="margin-right: 16px" mat-raised-button (click)="submitIrregularOpeningTimes()">SPEICHERN</button>
        </ng-container>
    </scalable-panel>
</div>
