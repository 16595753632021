
<default-card >
    <div tourAnchor="hero" *ngIf="hero$ | async" [formGroup]="heroFormGroup">
        <div  class="title">Startbereich</div>
        <div style="margin-bottom: 24px;">
            <div class="image" [ngStyle]="imageError ? {border: '1px solid red', borderRadius: '10px'} : {} ">
            <flav-image tourAnchor="changePicture" *ngIf="!isLoading; else loading" [src]="heroImageLink" (click)="heroUpload.click()" height="270px" ></flav-image>
                <input type="file" (change)="onHeroImageSelected($event)" #heroUpload style="display: none">
            </div>
            <div  *ngIf="imageError" style="color: red; font-size: 12px;">{{imageErrorMessage}} </div>
        </div>
        <div style="display: flex; flex-direction: column; ">
            <mat-form-field color="accent">
                <mat-label>
                    "Titeltext"
                </mat-label>
                <input maxlength="50" matInput formControlName="heroBody">
            </mat-form-field>
            <div style="display: flex; flex-direction: row; justify-content: end">
                <button class="button" mat-button color="accent" style="margin-right: 16px" (click)="abortHero()">ABBRECHEN</button>
                <button class="button" [disabled]="disableSaveButton()" mat-raised-button color="accent" (click)="submitHero()">SPEICHERN</button></div>
        </div>
    </div>
</default-card>

<ng-template #loading>
    <div style="height: 270px; display: flex; flex-direction: row; justify-content: center; align-items: center">
    <mat-spinner></mat-spinner>
    </div>
</ng-template>