import {Injectable} from "@angular/core";
import {RMAbstractDataSource} from "../../shared/datasource/rm-datasource.abstract";
import {Contact} from "../contact/contact.type";
import {Endpoint} from "../../endpoints.definition";
import {UpdateContactDTO} from "../contact/contact.datasource";
import {Impressum} from "./basisdaten.type";

@Injectable()
export class BasisdatenDatasource extends RMAbstractDataSource<Impressum,  Impressum> {
    readonly path = Endpoint.LEGAL_NOTICE;
    readonly serializer = Impressum.fromJson;
}