export class Introduction{
    constructor(
        public header: string,
        public body: string,
        public image: string,
        public imageUrl: string
    ) {
    }

    public static fromJson(json: any){
        return new Introduction(
            json.header,
            json.body,
            json.image,
            json.imageUrl
        )
    }
}