import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {RestaurantSetupPage} from "./setup/setup.page";
import {DefaultCardModule} from "../ui/default-card/default-card.module";
import {MatCardModule} from "@angular/material/card";
import {MatStepperModule} from "@angular/material/stepper";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import AddressModule from "../address/address.module";
import {MatIconModule} from "@angular/material/icon";
import {MatCheckboxModule} from "@angular/material/checkbox";

@NgModule({
    imports: [
        CommonModule,
        DefaultCardModule,
        MatCardModule,
        MatStepperModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        AddressModule,
        MatIconModule,
        MatCheckboxModule,
        FormsModule
    ],
    declarations: [
        RestaurantSetupPage
    ],
})
export class RestaurantModule {
}
