export class Allergen {
    constructor(
        public id: number,
        public identification: string,
        public description: string
    ) {
    }

    static fromJson(json: any): Allergen {
        return new Allergen(
            json.id,
            json.identification,
            json.description
        )
    }
}
