import {Injectable} from "@angular/core";
import {BehaviorSubject, distinct, shareReplay, tap} from "rxjs";


const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN_KEY = 'auth-refresh-token';
const USER_KEY = 'auth-user';

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {
    constructor() {
        this.currentTokenSubject$.next(this.getToken() ?? false)
    }

    currentTokenSubject$ = new BehaviorSubject<string | boolean>(false);
    currentToken$ = this.currentTokenSubject$.asObservable()

    signOut() {
        window.sessionStorage.clear()
        console.log("Logout! Tokenservice")
        this.currentTokenSubject$.next(false)
    }

    public saveToken(token: string) {
        window.sessionStorage.removeItem(TOKEN_KEY)
        window.sessionStorage.setItem(TOKEN_KEY, token)
        this.currentTokenSubject$.next(token);
    }

    public saveRefreshToken(token: string) {
        window.sessionStorage.removeItem(REFRESHTOKEN_KEY)
        window.sessionStorage.setItem(REFRESHTOKEN_KEY, token)
    }

    public getToken(): string | null {
        const token = window.sessionStorage.getItem(TOKEN_KEY)
        return token;
    }

    public getRefreshToken(): string | null {
        return window.sessionStorage.getItem(REFRESHTOKEN_KEY)
    }
}
