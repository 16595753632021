import {Component, ViewChild} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {buildAddressFormGroup} from "../../address/address.form";
import {STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent} from "@angular/cdk/stepper";
import {firstValueFrom, map, Observable, startWith, tap} from "rxjs";
import {RestaurantService} from "../restaurant.service";
import CountryDatasource from "../../country/country.datasource";
import {HttpErrorResponse} from "@angular/common/http";
import {MatStepper} from "@angular/material/stepper";
import {SetupErrors} from "./setup.types";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {F} from "@angular/cdk/keycodes";
import {ValidationMessages} from "../../../shared/Validation/validation.messages";


@Component({
    selector: 'flav-restaurant-setup',
    templateUrl: './setup.page.html',
    styleUrls: ['./setup.page.scss'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: {showError: true}
        }
    ]
})
export class RestaurantSetupPage {

    @ViewChild(MatStepper) stepper: MatStepper | undefined;

    formError: string | undefined;

    SUBDOMAIN_PATTERN = /^[-a-zA-Z0-9]{0,40}$/
    constructor(private _formBuilder: FormBuilder, private restaurantService: RestaurantService, private countryDatasource: CountryDatasource, private router: Router, private snackbar: MatSnackBar) {
    }

    get buttonDisabled(): boolean {
        return !this.restaurantNameFormGroup.valid || !this.restaurantAddressFormGroup.valid || !(this.restaurantSubdomainFormGroup.valid || this.restaurantSubdomainFormGroup.value.ownDomain);
    }

    restaurantNameFormGroup: FormGroup = this._formBuilder.group({
        name: ['', Validators.required]
    });
    restaurantAddressFormGroup: FormGroup = buildAddressFormGroup(null, true);
    restaurantSubdomainFormGroup: FormGroup = this._formBuilder.group({
            subdomain: new FormControl('', [Validators.required, Validators.pattern(this.SUBDOMAIN_PATTERN)]),
            ownDomain: new FormControl(false )
        }
    );

    nameInvalid$: Observable<boolean> = this.restaurantNameFormGroup.statusChanges.pipe(
        map((status) => status.toLowerCase() === "invalid")
    )
    restaurantInvalid$: Observable<boolean> = this.restaurantAddressFormGroup.statusChanges.pipe(
        map((status) => {
            const stepInteracted = this.stepper?.steps?.get(1)?.interacted ?? false;
            return status.toLowerCase() === "invalid" && stepInteracted;
        })
    )
    subdomainInvalid$: Observable<boolean> = this.restaurantSubdomainFormGroup.statusChanges.pipe(
        map((status) => status.toLowerCase() === "invalid")
    )


    subdomain$: Observable<string> = this.restaurantSubdomainFormGroup.valueChanges.pipe(
        startWith({subdomain: "beispiel"}),
        map((value) => value.subdomain as string),
        map((value) => {
            if (value) {
                return value.toLowerCase().replace(' ', '')
            } else {
                return "beispiel"
            }
        }),
    );

    restaurantNameText$: Observable<string> = this.restaurantNameFormGroup.valueChanges.pipe(
        startWith({name: ""}),
        map((value) => value.name as string)
    )

    async setupRestaurant() {
        this.resetGeneralFormError();
        const restaurantSetupDTO = {
            name: this.restaurantNameFormGroup.value.name,
            subdomain: this.restaurantSubdomainFormGroup.value.ownDomain ? "" : this.restaurantSubdomainFormGroup.value.subdomain.toLowerCase() ,
            ownDomain: this.restaurantSubdomainFormGroup.value.ownDomain,
            address: this.restaurantAddressFormGroup.value
        }
        try {
            await firstValueFrom(this.restaurantService.setupRestaurant(restaurantSetupDTO));
            this.snackbar.open("Restaurant erfolgreich erstellt", "Schließen", {
                duration: 5000
            })
            this.router.navigate(["/"])
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                if (error.status === 400) {
                    const setupErrors = SetupErrors.fromJson(error.error);
                    if (setupErrors.name) {
                        this.restaurantNameFormGroup.get("name")?.setErrors({server: setupErrors.name})
                    }
                    if (setupErrors.subdomain && !this.restaurantSubdomainFormGroup.value.ownDomain) {
                        this.restaurantSubdomainFormGroup.get("subdomain")?.setErrors({server: setupErrors.subdomain})
                    }
                    if (setupErrors.address) {
                        if (typeof setupErrors.address === 'string') {
                            this.restaurantAddressFormGroup.setErrors({server: setupErrors.address})
                        } else {
                            if (setupErrors.address.street) {
                                this.restaurantAddressFormGroup.get("street")?.setErrors({server: setupErrors.address.street})
                            }
                            if (setupErrors.address.houseNumber) {
                                this.restaurantAddressFormGroup.get("houseNumber")?.setErrors({server: setupErrors.address.houseNumber})
                            }
                            if (setupErrors.address.city) {
                                this.restaurantAddressFormGroup.get("city")?.setErrors({server: setupErrors.address.city})
                            }
                            if (setupErrors.address.postalCode) {
                                this.restaurantAddressFormGroup.get("postalCode")?.setErrors({server: setupErrors.address.postalCode})
                            }
                            if (setupErrors.address.countryId) {
                                console.error("In RestaurantSetupPage:setupRestaurant:setupErrors.address.countryId is not implemented or there is a bug")
                                this.setGeneralFormError()
                            }
                        }

                    }
                } else if(error.status === 403){
                    this.snackbar.open(error.error.replace('Forbidden operation:', ''), ValidationMessages.OK)
                    await this.router.navigate(['/']);
                }
                else {
                    this.setGeneralFormError()
                }
            }
        }
    }

    onStepChange($event: StepperSelectionEvent) {
        $event.previouslySelectedIndex === 0 && this.restaurantNameFormGroup.updateValueAndValidity();
        $event.previouslySelectedIndex === 1 && this.restaurantAddressFormGroup.updateValueAndValidity();
        $event.previouslySelectedIndex === 2 && this.restaurantSubdomainFormGroup.updateValueAndValidity();
    }

    setGeneralFormError() {
        this.formError = "Dieser Service ist zur Zeit nicht verfügbar. Bitte versuchen Sie es später erneut."
    }

    resetGeneralFormError() {
        this.formError = undefined;
    }

    protected readonly ValidationMessages = ValidationMessages;
}
