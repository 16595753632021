import {Injectable} from "@angular/core";
import {HeroDatasource, UpdateHeroDTO} from "./hero.datasource";
import {HttpClient} from "@angular/common/http";
import {Endpoint} from "../../../endpoints.definition";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {Placeholder} from "./hero.type";

@Injectable()
export class HeroService {
    constructor(private heroDatasource: HeroDatasource, private http: HttpClient) {
    }

    public getHero(){
        return this.heroDatasource.get();
    }


    public uploadHero(hero: UpdateHeroDTO) {
        return this.heroDatasource.update(hero);
    }

}