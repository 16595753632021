import {Component} from "@angular/core";
import {PasswordConfiguratorService} from "./password-configurator.service";
import { FormControl, FormGroup, Validators} from "@angular/forms";
import {PasswordConfig} from "./password-configurator.types";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ValidationMessages} from "../../../shared/Validation/validation.messages";
import {ActivatedRoute, Router} from "@angular/router";


@Component(
    {
        selector: 'password-configurator',
        templateUrl: './password-configurator.component.html',
        styleUrls: ['./password-configurator.component.scss']
    }
)
export class PasswordConfiguratorComponent {
    hide = true;
    hideConfirm = true;
    passwordFormGroup = new FormGroup({
        password: new FormControl("", [Validators.required]),
        confirmPassword: new FormControl("",[Validators.required]),
    },)

    constructor(private passwordConfiguratorService: PasswordConfiguratorService, private snackbar: MatSnackBar, private router: Router, private activeRoute: ActivatedRoute) {
    }

    changePassword(){
        if(!this.passwordFormGroup.valid){
            this.passwordFormGroup.markAllAsTouched();
            this.snackbar.open(ValidationMessages.FAILURE, ValidationMessages.OK);
            return;
        }
        let token = '' ;
        this.activeRoute.queryParams.subscribe((params) => token = params['code'])
        this.passwordConfiguratorService.changePassword(PasswordConfig.fromJson(this.passwordFormGroup.getRawValue()), token).subscribe(
            (text)=> {this.snackbar.open(ValidationMessages.PASSWORD_CHANGE_SUCCESS, ValidationMessages.OK); this.router.navigate(["/login"]); console.log(text)},
            (error)=> {
            if(error.error.toString().slice(2, 10) == "password"){
               this.snackbar.open(ValidationMessages.PASSWORD_INCOMPLETE, ValidationMessages.OK)
            }else{this.snackbar.open(ValidationMessages.HTTP_FAILURE, ValidationMessages.OK)}
            }
        )
    }

   /* getMailErrorMessage() {
        if (this.email.hasError('required')) {
            return ValidationMessages.WRONG_EMAIL;
        }
        return this.email.hasError('email') ? ValidationMessages.WRONG_EMAIL : '';
    }*/

    abort(){
        this.router.navigate(["/login"]);
    }

}