import {Injectable} from "@angular/core";
import {StylesDatasource} from "./styles.datasource";
import {Styles} from "./styles.type";


@Injectable()
export class StylesService {
    constructor( private stylesDatasource: StylesDatasource) {
    }

    public getStyles(){
        return this.stylesDatasource.get();
    }

    public uploadDesign(design: Styles) {
        return this.stylesDatasource.update(design);
    }
}