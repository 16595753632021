import {NgModule} from '@angular/core';

import {CommonModule} from "@angular/common";
import {PanelComponent} from "./panel/panel.component";
import {MatDividerModule} from "@angular/material/divider";
import {FlavImageComponent} from "./flav-image/flav-image.component";


@NgModule({
    declarations: [
        PanelComponent,
        FlavImageComponent
    ],
    imports: [
        CommonModule,
        MatDividerModule,
    ],
    exports: [
        PanelComponent,
        FlavImageComponent,
    ]
})
export class FlavoroUiModule {
}
