import {CRUDDatasource} from "../../../shared/datasource/crud-datasource.abstract";
import {MenuItem} from "./menu-item.types";
import {Injectable} from "@angular/core";
import {Endpoint} from "../../../endpoints.definition";

export class CreateMenuItemDTO {
    constructor(
        public categoryId: string,
        public name: string,
        public price: number,
        public allergenIds?: number[],
        public description?: string,
        public imageId?: string,
    ) {
    }
}

export class UpdateMenuItemDTO {
    constructor(
        public id: string,
        public categoryId: string,
        public name: string,
        public price: number,
        public allergenIds: number[],
        public description?: string,
        public imageId?: string,
    ) {
    }
}

export class MenuItemDatasource extends CRUDDatasource<MenuItem, CreateMenuItemDTO, UpdateMenuItemDTO> {
    readonly path: string = Endpoint.MENU_ITEMS;
    readonly serializer = MenuItem.fromJson;
}
