import {CommonModule} from "@angular/common";
import CountryDatasource from "./country.datasource";
import {NgModule} from "@angular/core";

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        CountryDatasource
    ]
})
export class CountryModule {
}
