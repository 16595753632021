import {Injectable} from "@angular/core";
import {CRUDDatasource} from "../../../shared/datasource/crud-datasource.abstract";
import {Endpoint} from "../../../endpoints.definition";
import {PasswordConfig} from "./password-configurator.types";

@Injectable()
export class PasswordConfiguratorDatasource extends CRUDDatasource<PasswordConfig, PasswordConfig, PasswordConfig> {
    readonly path = Endpoint.RESET_PASSWORD;
    readonly serializer = PasswordConfig.fromJson;

    changePassword(createDTO: PasswordConfig, token:string){
        return this.http.post(this.path + "?token=" + token, createDTO, {responseType: 'text' as 'json'})
    }
}