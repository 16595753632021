import {NgModule} from '@angular/core';
import {MatIconModule} from "@angular/material/icon";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {OpeningTimesComponent} from "./opening-times.component";
import {FromToComponent} from "./fromToComponent/fromTo.component";
import {FlavoroUiModule} from "../ui/flavoro-ui.module";
import {VariableFormFieldComponent} from "./variable-form-field/variable-form-field.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {IrregularOpeningTimesDatasource, RegularOpeningTimesDatasource} from "./opening-times.datasource";
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatDividerModule} from "@angular/material/divider";
import {MatSelectModule} from "@angular/material/select";
import {TransferDialogComponent} from "./transfer-dialog/transfer-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatListModule} from "@angular/material/list";
import {FlavFabButtonModule} from "../ui/flav-fab-button/flav-fab-button.module";
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import {TourAnchorMatMenuDirective} from "ngx-ui-tour-md-menu";
@NgModule({
    declarations: [
        OpeningTimesComponent,
        FromToComponent,
        VariableFormFieldComponent,
        TransferDialogComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        FlavoroUiModule,
        NgxMaterialTimepickerModule,
        FormsModule,
        MatDividerModule,
        MatSelectModule,
        MatDialogModule,
        MatListModule,
        FlavFabButtonModule,
        MatMomentDateModule,
        NgxMatTimepickerModule,
        TourAnchorMatMenuDirective,
    ],
    providers:[
        RegularOpeningTimesDatasource,
        IrregularOpeningTimesDatasource,
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: MAT_DATE_LOCALE, useValue: 'de-Ger' }
    ]
})
export class OpeningTimesModule {
}
