import {Component, Input} from "@angular/core";
import {FormGroup} from "@angular/forms";

@Component({
    selector: 'flav-input',
    templateUrl: './flav-input.component.html',
    styleUrls: ['./flav-input.component.scss']
})
export class FlavInputComponent{
    @Input() formGroup!: FormGroup;
    @Input() label: string = "";
    @Input() control: any = "";
}