import {Component} from "@angular/core";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {firstValueFrom, ReplaySubject, tap} from "rxjs";
import {MediaService, MediaType} from "../../media/media.service";
import {Styles} from "./styles.type";
import {StylesService} from "./styles.service";
import {validColorValidator} from "ngx-colors";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ValidationMessages} from "../../../shared/Validation/validation.messages";
import {FormGroupComponentInterface} from "../../ui/CanDeactivateGuard/formgroupcomponent.interface";


@Component(
    {
        selector: 'styles',
        templateUrl: './styles.component.html',
        styleUrls: ['./styles.component.scss']
    }
)
export class StylesComponent implements FormGroupComponentInterface {
    stylesFormGroup!: FormGroup;
    logoImageLink: string = '';
    logoImageId: string = '';
    imageError = false;
    imageErrorMessage = "";
    isLoading = false;
    waitingForResponse = false;

    fontSelection = [
        {name: "Caveat", css: "font-family: 'Caveat', cursive;" },
        {name: "Comfortaa", css: "font-family: 'Comfortaa', cursive;" },
        {name: "Dancing_Script", css: "font-family: 'Dancing Script', cursive;"},
        {name: "Inter", css: "font-family: 'Inter', sans-serif;"},
        {name: "JetBrains_Mono", css: "font-family: 'JetBrains Mono', monospace;" },
        {name: "Lora", css: "font-family: 'Lora', serif;" },
        {name: "Montserrat", css: "font-family: 'Montserrat', sans-serif;" },
        {name: "Open_Sans", css: "font-family: 'Open Sans', sans-serif;" },
        {name: "Oswald", css: "font-family: 'Oswald', sans-serif;" },
        {name: "Raleway", css: "font-family: 'Raleway', sans-serif;" },
        {name: "Roboto", css: "font-family: 'Roboto', sans-serif;" },
        {name: "Roboto_Mono", css: "font-family: 'Roboto Mono', monospace;" },
        {name: "Roboto_Slab", css: "font-family: 'Roboto Slab', serif;" },
        {name: "Source_Code_Pro", css: "font-family: 'Source Code Pro', monospace;" },
        {name: "Unbounded", css: "font-family: 'Unbounded', cursive;" },
    ]


    getCurrentFontCSS(){
      return  this.fontSelection.find((font) => font.name == this.stylesFormGroup.get('font')?.value)?.css
    }
    constructor(private mediaService: MediaService, private stylesService: StylesService, private snackbar: MatSnackBar) {
    }

    private _currentStylesSubject: ReplaySubject<Styles> = new ReplaySubject(1);
    currentStyles$ = this._currentStylesSubject.asObservable();



    styles$ = this.stylesService.getStyles().pipe(tap((styles) =>{
            this._currentStylesSubject.next(styles);
            this.stylesFormGroup = this.buildStylesFormGroup(styles);
            this.logoImageLink = styles.logoUrl;
            this.logoImageId = styles.logo;
        }
    ))

    colorFormArray = [
        {label: "Primärfarbe", control: "primaryColor"},
        {label: "Sekundärfarbe", control: "secondaryColor"},
        {label: "Textfarbe (Primär)", control: "textColorOnPrimaryColor"},
        {label: "Textfarbe (Sekundär)", control: "textColorOnSecondaryColor"}]

    formatFontName(fontName: string): string {
        return fontName.replaceAll("_", " ");
    }

    getCurrentFontName(): string {
        return this.formatFontName(this.stylesFormGroup.get("font")?.value ?? "Roboto");
    }

    buildStylesFormGroup(style?: Styles) {
        return new FormGroup<any>({
            primaryColor: new FormControl(style?.primaryColor, [validColorValidator()] ?? ''),
            secondaryColor: new FormControl(style?.secondaryColor, [validColorValidator()] ?? ''),
            textColorOnPrimaryColor: new FormControl(style?.textColorOnPrimaryColor, [validColorValidator()] ?? ''),
            textColorOnSecondaryColor: new FormControl(style?.textColorOnSecondaryColor, [validColorValidator()] ?? ''),
            font: new FormControl(style?.font ?? ''),
            darkMode: new FormControl(style?.darkMode ?? false),
        });
    }

    async submitDesign() {
        if(!this.stylesFormGroup.valid || this.imageError){
            if(this.stylesFormGroup.get('primaryColor')?.hasError('minlength') || this.stylesFormGroup.get('secondaryColor')?.hasError('minlength') || this.stylesFormGroup.get('textColorOnPrimaryColor')?.hasError('minlength') || this.stylesFormGroup.get('textColorOnSecondaryColor')?.hasError('minlength')){
                this.snackbar.open(ValidationMessages.COLOR_DOESNT_EXIST, ValidationMessages.OK);
            }else{
                this.snackbar.open(ValidationMessages.FAILURE, ValidationMessages.OK);
            }
            return
        }
        const design: Styles = Styles.fromJson(this.stylesFormGroup.value);
        design.logo = this.logoImageId;
        design.logoUrl = this.logoImageLink;
        this.stylesFormGroup.markAsPristine();
        this.waitingForResponse = true;
        try{
            const updateStyles = await firstValueFrom(this.stylesService.uploadDesign(design));
            this._currentStylesSubject.next(updateStyles);
            this.snackbar.open(ValidationMessages.SUCCES, ValidationMessages.OK, {duration: 5000, panelClass: ['success-snackbar']})
        } catch(err){
            this.snackbar.open(ValidationMessages.HTTP_FAILURE, ValidationMessages.OK)
            this.stylesFormGroup.markAsDirty();
        }
        this.waitingForResponse = false;
    }

    async abortStyles() {
        const originalData = await firstValueFrom(this.currentStyles$);
        this.stylesFormGroup.get('primaryColor')?.setValue(originalData.primaryColor);
        this.stylesFormGroup.get('secondaryColor')?.setValue(originalData.secondaryColor);
        this.stylesFormGroup.get('textColorOnPrimaryColor')?.setValue(originalData.textColorOnPrimaryColor);
        this.stylesFormGroup.get('textColorOnSecondaryColor')?.setValue(originalData.textColorOnSecondaryColor);
        this.stylesFormGroup.get('font')?.setValue(originalData.font);
        this.stylesFormGroup.get('darkMode')?.setValue(originalData.darkMode);
        this.logoImageLink = originalData.logoUrl;
        this.logoImageId = originalData.logo;
        this.stylesFormGroup.markAsPristine();
    }

    async onLogoSelected($event: Event) {
        const file: File = ($event?.target as any)?.files[0];
        if (!file) {
            return
        }
        let imageValid = true;
        if (!(file?.type == "image/jpeg" || file?.type == "image/png" || file?.type == "image/svg+xml")) {
            imageValid = false;
            this.imageError = true
            this.logoImageLink = "/assets/placeholder-image.jpeg";
            this.imageErrorMessage = "Bitte ein Bild mit Dateityp ”png”,\"svg\" oder “jpg“ wählen"

        }
        if (!(file?.size <= 10000000)) {
            imageValid = false;
            this.imageError = true
            this.logoImageLink = "/assets/placeholder-image.jpeg";
            this.imageErrorMessage = "Das Bild ist zu groß. Maximale Größe beträgt 10MB"
        }
        if (imageValid) {
            this.isLoading = true;
            this.imageError = false
            try {
                const uploadedImage = await firstValueFrom(this.mediaService.uploadImage(file, MediaType.RESTAURANT))
                this.logoImageId = uploadedImage.imageId;
                this.logoImageLink = uploadedImage.path;
                this.isLoading = false
                this.stylesFormGroup.markAsDirty()
            } catch (err) {
                this.snackbar.open(ValidationMessages.IMAGE_NOT_UPLOADED, ValidationMessages.OK);
                this.isLoading = false
            }
        }
    }

    getFormGroups(): FormGroup[] {
        return [this.stylesFormGroup];
    }

    disableSaveButton(){
        return !(this.stylesFormGroup?.dirty && this.stylesFormGroup?.valid && !this.waitingForResponse) ;
    }

}
