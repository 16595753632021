import {NgModule} from "@angular/core";
import {SubscribeComponent} from "./subscribe.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {DefaultCardModule} from "../ui/default-card/default-card.module";



@NgModule(
    {
        declarations: [
         SubscribeComponent
        ],
        imports: [
            MatFormFieldModule,
            MatInputModule,
            MatButtonModule,
            DefaultCardModule,
        ],
    }
)
export class SubscribeModule{}