<div class="login-wrapper">
<img style="width: 300px; height: 200px; object-fit: cover; padding-top: 64px" src="../../assets/OrangeRegular.svg"/>
<default-card >
<div [formGroup]="loginGroup" (keyup.enter)="logIn()">
    <div style="display: flex; flex-direction: row;">
        <h1 style="font-weight: bold; line-height: 1; padding-right: 8px">Login</h1>
        <mat-icon class="material-icons-outlined" matTooltip="{{tooltipText}}">info</mat-icon>
    </div>
    <div style="display: flex; flex-direction: column; min-width: 328px">
    <mat-form-field color="accent">
        <mat-label>
            Email
        </mat-label>
        <input matInput  formControlName="email">
    </mat-form-field>

    <mat-form-field color="accent" (keyup.enter)="logIn()">
        <mat-label>
            Passwort
        </mat-label>
        <input matInput  [type]="hide ? 'password' : 'text'" formControlName="password">
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
    </mat-form-field>
    </div>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
    <div class="pass-forgot" (click)="forgotPass()">Passwot vergessen?</div>
    </div>
    <div *ngIf="justbought" style="width: 328px; margin-top: 32px; font-weight: bold">
        Falls du dich das erste Mal einloggst: Du findest deine Zugangsdaten in der Mail die wir dir zugestellt haben.
    </div>
    <div style="width: 100%; text-align: end; padding-top: 40px">
    <button mat-raised-button  color="accent" (click)="logIn()" (keydown.enter)="logIn()">Einloggen</button>
    </div>
</div>
</default-card>
</div>
