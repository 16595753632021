import {Injectable} from "@angular/core";
import {IntroductionDatasource, UpdateIntroductionDTO} from "./introduction.datasource";

@Injectable()
export class IntroductionService {
    constructor( private introductionDatasource: IntroductionDatasource) {
    }
    public getIntroduction(){
        return this.introductionDatasource.get();
    }

    public uploadIntroduction(introduction: UpdateIntroductionDTO) {
        console.log(introduction);
        return this.introductionDatasource.update(introduction)
    }
}