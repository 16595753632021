<style>
    :host {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

        color: #333;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 8px 0;
    }

</style>

<ng-container>
    <mat-sidenav-container>
        <mat-sidenav mode="side" disableClose="true" opened="true" [class.closed]="sidebarClosed"
                     style="padding-top: 16px;">
            <button mat-icon-button (click)="toggleSidebar()" class="menu-button">
                <mat-icon *ngIf="sidebarClosed"> menu</mat-icon>
                <mat-icon *ngIf="!sidebarClosed"> menu_open</mat-icon>
            </button>
            <mat-nav-list>
                <mat-list-item routerLink="speisekarte" routerLinkActive #speisekarte="routerLinkActive"
                               [activated]="speisekarte.isActive">
                    <mat-icon matListItemIcon class="material-symbols-outlined">menu_book</mat-icon>
                    <h4 *ngIf="!sidebarClosed" matListItemTitle>Speisekarte</h4>
                </mat-list-item>

                <mat-list-item routerLink="offnungszeiten" routerLinkActive #offnungszeiten="routerLinkActive"
                               [activated]="offnungszeiten.isActive">
                    <mat-icon matListItemIcon class="material-symbols-outlined">schedule</mat-icon>
                    <h4 *ngIf="!sidebarClosed" matListItemTitle>Öffnungszeiten</h4>
                </mat-list-item>

                <mat-list-item routerLink="kontakt" routerLinkActive #kontakt="routerLinkActive"
                               [activated]="kontakt.isActive">
                    <mat-icon matListItemIcon class="material-symbols-outlined">call</mat-icon>
                    <h4 *ngIf="!sidebarClosed" matListItemTitle>Kontakt</h4>
                </mat-list-item>

                <mat-list-item routerLink="gestaltung" routerLinkActive #gestaltung="routerLinkActive"
                               [activated]="gestaltung.isActive">
                    <mat-icon matListItemIcon class="material-symbols-outlined">brush</mat-icon>
                    <h4 *ngIf="!sidebarClosed" matListItemTitle>Gestaltung</h4>
                </mat-list-item>

                <mat-list-item routerLink="basisdaten" routerLinkActive #basisdaten="routerLinkActive"
                               [activated]="basisdaten.isActive">
                    <mat-icon matListItemIcon class="material-symbols-outlined">interests</mat-icon>
                    <h4 *ngIf="!sidebarClosed" matListItemTitle>Basisdaten</h4>
                </mat-list-item>

                <a tourAnchor="website" *ngIf="websiteUrl|async; let url" style="text-decoration: none" href="{{'https://' + url}}" target=”_blank”
                   rel="noopener noreferrer">
                    <mat-list-item tourAnchor="loadingWebsite"  routerLinkActive #goToSite="routerLinkActive"
                                   [activated]="goToSite.isActive">
                        <mat-icon matListItemIcon class="material-symbols-outlined">public</mat-icon>
                        <div matListItemTitle *ngIf="!(isRendering$|async); else isRendering">
                            <h4 *ngIf="!sidebarClosed">Deine Website</h4>
                        </div>
                        <ng-template #isRendering>
                            <div matListItemTitle>
                                <div style="font-size: 12px" *ngIf="!sidebarClosed">
                                    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
                                    Deine Webseite wird erstellt.
                                </div>
                            </div>
                        </ng-template>
                    </mat-list-item>
                </a>

                <mat-list-item style="position: absolute; bottom: 16px;" (click)="logout()">
                    <mat-icon matListItemIcon class="material-symbols-outlined">logout</mat-icon>
                    <h4 *ngIf="!sidebarClosed" matListItemTitle>Ausloggen</h4>
                </mat-list-item>
            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content [class.closed]="sidebarClosed">
            <div style="height: 100vh;">
                <router-outlet></router-outlet>

            </div>
        </mat-sidenav-content>

    </mat-sidenav-container>
</ng-container>
