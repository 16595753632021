import {Component,} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {RegistrationService} from "./registration.service";
import {Registration, VerificationMail} from "./registration.type";
import {Router} from "@angular/router";
import {firstValueFrom, tap} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ValidationMessages} from "../../shared/Validation/validation.messages";
import {ButtonTypes} from "../ui/loading-button/loading-button.component";
import {environment} from "../../environments/environment";


@Component(
    {
        selector: 'registration',
        templateUrl: './registration.component.html',
        styleUrls: ['./registration.component.scss']
    }
)
export class RegistrationComponent {
    isNotLoading: boolean = true;
    isNotLoadingResend: boolean = true;
    buttonTypes = ButtonTypes
    MOBILE_PATTERN = /^[^A-Za-z]+$/
    LETTER_PATTERN = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
    registration: string = this.router.url.replace('/registration', '')
    registrationFormGroup = new FormGroup({
        id: new FormControl(""),
        firstName: new FormControl("", [Validators.required, Validators.pattern(this.LETTER_PATTERN)]),
        lastName: new FormControl("", [Validators.required, Validators.pattern(this.LETTER_PATTERN)]),
        email: new FormControl("", [Validators.required, Validators.email]),
        phoneNumber: new FormControl("", [Validators.pattern(this.MOBILE_PATTERN)]),
        password: new FormControl(""),
        confirmPassword: new FormControl(""),
    })
    addressFormGroup = new FormGroup({
        id: new FormControl(""),
        street: new FormControl("", [Validators.required, Validators.pattern(this.LETTER_PATTERN)]),
        houseNumber: new FormControl("", [Validators.required]),
        city: new FormControl("", [Validators.required, Validators.pattern(this.LETTER_PATTERN)]),
        countryId: new FormControl(""),
        postalCode: new FormControl("", [Validators.required]),
    })

    link: string = "hello" ;

    constructor(private registrationService: RegistrationService, private router: Router, private _snackBar: MatSnackBar) {
        console.log(this.registration.replace('/registration', ''))
    }

    async sendVerificationMail() {
        this.isNotLoadingResend = false
        const callbackUrl = environment.ccUrl + "/verify-mail"
        try{
            await firstValueFrom(this.registrationService.sendVerificationMail(new VerificationMail(this.registrationFormGroup.get('email')?.value!, callbackUrl)))
            console.log("success")
            this._snackBar.open(ValidationMessages.GOT_MAIL, ValidationMessages.OK)
            this.isNotLoadingResend = true
        }
        catch(error: any){
            this._snackBar.open(ValidationMessages.HTTP_FAILURE, ValidationMessages.OK)
            console.log(error.error);
            this.isNotLoadingResend = true
        }
    }

    async signIn(repeat: boolean) {
        this.isNotLoading = false;
        if (!this.registrationFormGroup.valid) {
            this.registrationFormGroup.markAllAsTouched();
            this.addressFormGroup.markAllAsTouched();
            this._snackBar.open(ValidationMessages.FAILURE, ValidationMessages.OK);
            this.isNotLoading = true
            return;
        }
        try {
            await firstValueFrom(this.registrationService.signIn(Registration.fromJson(this.registrationFormGroup.getRawValue(), this.addressFormGroup.getRawValue())))
            window.location.href="/registration#success"
            this.registration = '#success';
            if (repeat) {
                this._snackBar.open(ValidationMessages.GOT_MAIL, ValidationMessages.OK,);
            }
        } catch (e: any) {
            if(e.status == 0){
                this._snackBar.open(ValidationMessages.NO_INTERNET, ValidationMessages.OK,);
            }else{
                this._snackBar.open(ValidationMessages.REGISTRATION_FAILED, ValidationMessages.OK,);
            }
            if(e.error.toString().includes("Bad Request: User with email")){
                this.registrationFormGroup.get('email')?.setErrors({email: true})
                this._snackBar.open(ValidationMessages.EMAIL_IN_USE, ValidationMessages.OK);
                console.error(e.error);
            }
        }
        this.isNotLoading = true
    }



    backToLogin() {
        this.router.navigate(["/login"])
    }

    protected readonly ValidationMessages = ValidationMessages;
    protected readonly ButtonTypes = ButtonTypes;
}
