import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Category} from "../category/category.types";
import {CategoryService} from "../category/category.service";

@Component({
    selector: 'category-delete-dialog',
    templateUrl: './category-delete.dialog.html',
    styleUrls: ['./category-delete.dialog.scss']
})
export class CategoryDeleteDialog {

    deleting = false;

    constructor(public dialogRef: MatDialogRef<CategoryDeleteDialog>, @Inject(MAT_DIALOG_DATA) public category: Category, private categoryService: CategoryService) {
    }

    deleteCategory() {
        this.deleting = true;
        this.categoryService.deleteCategory(this.category.id).subscribe(() => {
            this.deleting = false;
            this.dialogRef.close(true);
        });

    }
}

