import {
    enableProdMode, isDevMode
} from "@angular/core";
import {
    platformBrowserDynamic
} from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular-ivy";
import {
    AppModule
} from "./app/app.module";

if (!isDevMode()) {
    Sentry.init({
        dsn: "https://8edea62b4d2dea46500aab16eff9e26d@o4505483484397568.ingest.sentry.io/4505657405276160",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
            new Sentry.Replay(),
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

enableProdMode();
platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((success) => console.log('Bootstrap success'))
    .catch((err) => console.error(err));
