export class Hero{
    constructor(
        public body: string,
        public image: string,
        public imageUrl: string
    ) {
    }

    public static fromJson(json: any){
        return new Hero(
            json.body,
            json.image,
            json.imageUrl
        )
    }
}

export class Placeholder{
    constructor(
        public id: string,
        public uploadedBy: string,
        public uploadedTs: string,
        public filename: string,
        public description: string,
        public filepath: string,
        public type: string
    ){}

    public static fromJson(json: any){
        return new Placeholder(
            json.id,
            json.uploadedBy,
            json.uploadedTs,
            json.filename,
            json.description,
            json.filepath,
            json.type
        )
    }
}