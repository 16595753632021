import {Component} from "@angular/core";
import {VerifyMailService} from "./verify-mail.service";
import {ActivatedRoute,Router} from "@angular/router";
import {Observable, timer} from "rxjs";

@Component(
    {
        selector: 'verify-mail',
        templateUrl: './verify-mail.component.html',
        styleUrls: ['./verify-mail.component.scss']
    }
)
export class VerifyMailComponent{
    notLoading = true;
    verifyMail$! : Observable<any> ;

    constructor(private verifyMailService: VerifyMailService, private router: Router, private activeRoute: ActivatedRoute) {
        this.notLoading = false;
       this.verifyMail().then(r => this.notLoading = true);
    }

    async verifyMail() {
        try {
            let token = '';
            this.activeRoute.queryParams.subscribe((params) => token = params['code']);
            console.log(token);
            this.verifyMail$ = this.verifyMailService.verify(token);
        } catch (err) {
        }
    }

    toLogin(){
        this.router.navigate(["/login"])
    }
}