import {Component, EventEmitter, Input, Output} from "@angular/core";
import {MenuItem} from "../menu-item.types";

@Component({
    selector: 'item-card',
    templateUrl: './item-card.component.html',
    styleUrls: ['./item-card.component.scss']
})
export class ItemCardComponent {

    @Input()
    item?: MenuItem;

    @Output()
    editClicked = new EventEmitter<any>();

    @Output()
    deleteClicked = new EventEmitter<any>();

    @Output()
    imageUploadClicked = new EventEmitter<any>();

    editMode: boolean = false;

    constructor() {
    }

    toggleEditMode() {
        this.editClicked.emit(this.item);
    }

    deleteItem() {
        this.deleteClicked.emit(this.item);
    }
    uploadItem() {
        this.imageUploadClicked.emit(this.item);
    }

    getitemPrice(item: any){
        let decimalsNumber = this.countDecimals(item?.price);
        if(decimalsNumber == 0){
            return item.price.toString() + ",00"
        }
        if(decimalsNumber == 1){
            return item.price.toString().replace("." , ",") + "0"
        }
        return item.price.toString().replace("." , ",")
    }

    countDecimals(number: number) {
        const decimalIndex = number.toString().indexOf('.');
        return decimalIndex >= 0 ? number.toString().length - decimalIndex - 1 : 0;
    }
}
