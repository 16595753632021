import {MenuItem} from "../menu-item/menu-item.types";

export class Category {
    constructor(
        public id: string,
        public name: string,
        public menuItems: MenuItem[],
        public description?: string,
    ) {
    }

    static fromJson(json: any): Category {
        return new Category(
            json?.id ?? "",
            json?.name ?? "",
            json?.menuItems?.map((item: any) => MenuItem.fromJson(item)) ?? [],
            json?.description ?? "",
        )
    }
}
