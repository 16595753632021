import {Component} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {firstValueFrom, Observable, ReplaySubject, tap} from "rxjs";
import {MediaService, MediaType} from "../../media/media.service";
import {Hero, Placeholder} from "./hero.type";
import {UpdateHeroDTO} from "./hero.datasource";
import {HeroService} from "./hero.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ValidationMessages} from "../../../shared/Validation/validation.messages";
import {environment} from "../../../environments/environment";
import {Endpoint} from "../../../endpoints.definition";
import {FormGroupComponentInterface} from "../../ui/CanDeactivateGuard/formgroupcomponent.interface";

@Component(
    {
        selector: 'hero',
        templateUrl: './hero.component.html',
        styleUrls: ['./hero.component.scss']
    }
)
export class HeroComponent implements FormGroupComponentInterface {

    heroFormGroup!: FormGroup;
    heroImageLink: string = '';
    heroImageId: string = '';
    imageError = false;
    imageErrorMessage = "";
    isLoading: boolean = false;
    waitingForResponse = false
    constructor(private mediaService: MediaService, private heroService: HeroService, private snackbar: MatSnackBar) {
    }


    private _currentHeroSubject: ReplaySubject<Hero> = new ReplaySubject(1);
    currentHero$ = this._currentHeroSubject.asObservable();

    hero$ = this.heroService.getHero().pipe(tap(  (hero) => {
            this._currentHeroSubject.next(hero);
            this.heroFormGroup = this.buildHeroFormGroup(hero);
            this.heroImageLink = hero.imageUrl
            this.heroImageId = hero.image
        }
    ))

    buildHeroFormGroup(hero?: Hero) {
        return new FormGroup<any>({
            heroBody: new FormControl(hero?.body ?? ''),
        });
    }

    async submitHero() {
        if(this.imageError){
            this.snackbar.open(ValidationMessages.FAILURE, ValidationMessages.OK)
            return
        }
        this.heroFormGroup.markAsPristine();
        this.waitingForResponse = true;
        let hero: UpdateHeroDTO = new UpdateHeroDTO(this.heroFormGroup.get('heroBody')?.value, this.heroImageId)
        try{
            const updatedHero = await firstValueFrom(this.heroService.uploadHero(hero));
            this._currentHeroSubject.next(updatedHero);
            this.snackbar.open(ValidationMessages.SUCCES, ValidationMessages.OK, {duration: 5000, panelClass: ['success-snackbar']})

        }
        catch(err){
            this.snackbar.open(ValidationMessages.HTTP_FAILURE, ValidationMessages.OK)
            this.heroFormGroup.markAsDirty();
        }
        this.waitingForResponse = false
    }

    async abortHero() {
        const originalData = await firstValueFrom(this.currentHero$);
        this.heroFormGroup?.get('heroBody')?.setValue(originalData.body);
        this.heroImageLink = originalData.imageUrl;
        this.heroImageId = originalData.image;
        this.heroFormGroup.markAsPristine();
    }

    async onHeroImageSelected($event: Event) {
        const file: File = ($event?.target as any)?.files[0];
        if (!file) {
            return
        }
        let imageValid = true;
        if (!(file?.type == "image/jpeg" || file?.type == "image/png" || file?.type == "image/svg+xml")) {
            imageValid = false;
            this.imageError = true
            this.heroImageLink = "/assets/placeholder-image.jpeg";
            this.imageErrorMessage = ValidationMessages.WRONG_IMAGE
        }
        if (file?.size >= 10000000) {
            imageValid = false;
            this.imageError = true
            this.heroImageLink = "/assets/placeholder-image.jpeg";
            this.imageErrorMessage = ValidationMessages.MAX_IMAGE_SIZE;
        }


        if (imageValid) {
            this.isLoading = true;
            this.imageError = false
            try {
                const uploadedImage = await firstValueFrom(this.mediaService.uploadImage(file, MediaType.HERO_SECTION))
                this.heroImageId = uploadedImage.imageId;
                this.heroImageLink = uploadedImage.path;
                this.isLoading = false
                this.heroFormGroup.markAsDirty()
            } catch (err) {
                this.snackbar.open(ValidationMessages.IMAGE_NOT_UPLOADED, ValidationMessages.OK);
                this.isLoading = false
            }
        }
    }

    getFormGroups(): FormGroup[] {
        return [this.heroFormGroup]
    }

    disableSaveButton(){
        return !(this.heroFormGroup?.dirty && this.heroFormGroup?.valid && !this.waitingForResponse);
    }
}
