import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Address} from "./address.type";
import {AddressForm, buildAddressFormGroup} from "./address.form";
import {ValidationMessages} from "../../shared/Validation/validation.messages";

/**
 * Use EITHER the [form] Input OR the [formGroup] Input.
 *
 * [formGroup]:
 * If you use the formGroup Input, you must pass in a FormGroup<AddressForm> object.
 * [required] and [initialValue] are ignored.
 *
 * [form]:
 * If you use the form Input, the component will build and append a FormGroup<AddressForm> object to it, called 'address'.
 * [required] and [initialValue] are used to build the FormGroup<AddressForm> object.
 *
 * When you set an Error with the "server" Key on a FormControl, it will be displayed.
 */

@Component({
    selector: 'flav-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
    @Input() form: FormGroup | undefined;
    @Input() formGroup: FormGroup<AddressForm> | undefined;
    @Input() required: boolean = false;
    @Input() initialValue?: Address;
    @Input() compact: boolean = false;

    addressFormGroup?: FormGroup<AddressForm>;

    ngOnInit() {
        this.addressFormGroup = this.formGroup ? this.formGroup : buildAddressFormGroup(this.initialValue, this.required)
        if (this.form && !this.formGroup) {
            this.form.addControl('address', this.addressFormGroup);
        }
    }

    getErrorMessage(controlName: string){
        const control = this.addressFormGroup?.get(controlName) as FormControl;
        if(control.hasError('required')){
            return ValidationMessages.REQUIRED
        }
        if(control.hasError('pattern')){
            return ValidationMessages.WRONG_ADDRESS
        }
        return control.getError("server")
    }

    protected readonly ValidationMessages = ValidationMessages;
}

