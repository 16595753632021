import {NgModule} from "@angular/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {DefaultCardModule} from "../../ui/default-card/default-card.module";
import {PasswordConfiguratorComponent} from "./password-configurator.component";
import {PasswordConfiguratorService} from "./password-configurator.service";
import {PasswordConfiguratorDatasource} from "./password-configurator.datasource";
import {MatIconModule} from "@angular/material/icon";
import {FlavPasswordFieldModule} from "../../ui/flav-password-field/flav-password-field.module";

@NgModule({
    declarations: [
        PasswordConfiguratorComponent
    ],
    imports: [
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        CommonModule,
        DefaultCardModule,
        MatIconModule,
        FlavPasswordFieldModule

    ],
    providers: [
        PasswordConfiguratorService,
        PasswordConfiguratorDatasource
    ]
})
export class PasswordConfiguratorModule {
}