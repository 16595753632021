import {NgModule} from "@angular/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {DefaultCardModule} from "../ui/default-card/default-card.module";
import {MatIconModule} from "@angular/material/icon";
import {PageNotFoundComponent} from "./page-not-found.component";

@NgModule({
    declarations: [
        PageNotFoundComponent
    ],
    imports: [
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        CommonModule,
        DefaultCardModule,
        MatIconModule
    ],
})
export class PageNotFoundModule {
}