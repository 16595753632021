import {Injectable} from "@angular/core";
import {Endpoint} from "../../endpoints.definition";
import {RequestPassword} from "./password-reset.types";
import {CRUDDatasource} from "../../shared/datasource/crud-datasource.abstract";

@Injectable()
export class PasswordResetDatasource extends CRUDDatasource<RequestPassword, RequestPassword, RequestPassword> {
    readonly path = Endpoint.REQUEST_PASS_RESET;
    readonly serializer = RequestPassword.fromJson;

    requestPassReset(createDTO: RequestPassword){
       return this.http.post(this.path, createDTO, {responseType: 'text' as 'json'})
    }
}