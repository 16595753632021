<div style="padding: 16px" [formGroup]="selectedFormGroup" >
    <ng-container *ngIf="selectedDays.length > 0; else noSelectedDays">
    <mat-selection-list formControlName="selectedDays">
        <mat-list-option *ngFor="let day of selectedDaysWithGerman" [value]="day.key">
            {{day.value}}
        </mat-list-option>
    </mat-selection-list>
    </ng-container>
    <div style="display: flex; flex-direction: row; justify-content: end;"><button mat-raised-button color="accent"  (click)="close()">Speichern</button></div>
</div>

<ng-template  #noSelectedDays>
    <div style="text-align: center">
    <div style="font-size: 16px; font-weight: bold; padding-bottom: 16px">Es sind keine Tage zum Transferieren vorhanden.</div>
   <div style="font-size: 16px; font-weight: bold; padding-bottom: 16px"> Füge einen weiteren Tag hinzu.</div>
    </div>
</ng-template>