import {Component} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AddressForm} from "../../address/address.form";
import {FlavoroHttpClient} from "../../../shared/http/http.client";
import {firstValueFrom} from "rxjs";
import CountryDatasource from "../../country/country.datasource";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Endpoint} from "../../../endpoints.definition";

type TenantForm = {
    firstName: FormControl<string | null>,
    lastName: FormControl<string | null>,
    address?: FormGroup<AddressForm> | undefined
}

@Component({
    selector: 'flav-tenant-setup-page',
    templateUrl: './setup.page.html',
    styleUrls: ['./setup.page.scss']
})
export class TenantSetupPage {

    constructor(private _http: FlavoroHttpClient, private countryDatasource: CountryDatasource, private router: Router, private snack: MatSnackBar) {
    }

    form: FormGroup<TenantForm> = new FormGroup<TenantForm>({
        firstName: new FormControl("", [Validators.required]),
        lastName: new FormControl("", [Validators.required]),
    });

    formError: string | undefined;

    async setupTenant() {
        this.resetError();
        const tenantForm = this.form.value;
        if (!tenantForm.address) {
            console.error("TenantForm address is not set, even tho it should be set!")
            this.setGeneralError()
            return;
        }
        try {
            await firstValueFrom(this._http.post(Endpoint.SETUP_TENANT, tenantForm));
            this.router.navigate(["/"], {queryParams: {showTour: true}})
            this.snack.open("Ihr Account ist jetzt bereit!", "OK", {duration: 5000})
        } catch (e) {
            this.setGeneralError()
            return;
        }
    }

    resetError() {
        this.formError = undefined;
    }

    setGeneralError() {
        this.formError = "Dieser Service scheint derzeit nicht zu funktionieren. Bitte versuchen Sie es später erneut."
    }
}
