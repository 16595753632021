import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ValidationMessages} from "../../shared/Validation/validation.messages";

@Component(
    {
        selector: 'login',
        templateUrl: './login.component.html',
        styleUrls: ['./login.component.scss']
    }
)
export class LoginComponent {
    tooltipText = "Gib die E-Mail ein, welche du zum Buchen des Abos benutzt hast. Das Passwort für das Control Center wurde dir zugesandt.";
    hide = true;
    login = true;
    loginGroup = new FormGroup({
        email: new FormControl(""),
        password: new FormControl("")
    })

    justbought = false;

    constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private snackbar: MatSnackBar) {
        this.route.queryParams.subscribe(params => {
            this.justbought = params['justbought'] == "true";
        })
    }

    logIn(){
        const username = this.loginGroup.get("email")?.value;
        const password = this.loginGroup.get("password")?.value;
        if(!username || !password) {
            this.loginGroup.markAllAsTouched();
            this.snackbar.open(ValidationMessages.LOGIN_FAILURE, "OK", {duration: 5000});
            return
        }
            this.authService.login({username: username, password: password}).subscribe(() => {
                this.router.navigate(["/"]);
            }, (error) => {
                console.log(error)
                if(error.status == 402){
                    this.router.navigate(['/subscribe'])
                }else{
                    this.snackbar.open(ValidationMessages.LOGIN_FAILURE, "OK", {duration: 5000});
                }
            });

    }

    toRegistration(){
        this.router.navigate(["/registration"])
    }
    forgotPass(){
        this.router.navigate(["/password-reset"])
    }
}
