<div style="height: 100vh; display: flex; justify-content: center; align-items: center">
    <default-card>
        <div style="min-height: 450px; width: 440px; display: flex; flex-direction: column; justify-content: space-between">
            <h1>Konto fertigstellen</h1>
            <form [formGroup]="form">
                <div style="display: flex; justify-content: center; text-align: center; margin-bottom: 8px">
                    <b>Bevor du Flavoro nutzen kannst, musst du noch ein paar Daten angeben. ✌🏻</b>
                </div>
                <div>
                    <mat-form-field color="accent">
                        <mat-label>Vorname</mat-label>
                        <input matInput placeholder="Vorname" formControlName="firstName">
                    </mat-form-field>
                    <mat-form-field color="accent" style="margin-left: 16px">
                        <mat-label>Nachname</mat-label>
                        <input matInput placeholder="Nachname" formControlName="lastName">
                    </mat-form-field>
                    <flav-address [compact]="true" [required]="true" [form]="form"></flav-address>
                </div>
                <mat-error style="display: flex; align-items: center" *ngIf="formError">
                    <mat-icon style="margin-right: 8px; flex-shrink: 0">error</mat-icon>
                    {{formError}}
                </mat-error>
            </form>
            <div style="display: flex; justify-content: flex-end; margin-top: 16px">
                <button mat-raised-button color="accent" (click)="setupTenant()" [disabled]="!form.valid">Account
                    erstellen
                </button>
            </div>
        </div>
    </default-card>
</div>
