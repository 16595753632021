import {Component, ElementRef, HostListener, Input} from "@angular/core";
import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

@Component({
    selector: 'price-field',
    templateUrl: './price-field.component.html',
    styleUrls: ['./price-field.component.scss']
})
export class PriceFieldComponent {
    @Input() priceFormGroup!: FormGroup;

    constructor(private eRef: ElementRef) {
    }
    @HostListener('document:click', ['$event'])
    clickout(event: any) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            const price = this.priceFormGroup.get('price')?.value
            const decimalsNumber = this.countDecimals(price)
            if(decimalsNumber == 0 && price != 0){
              return this.priceFormGroup.get('price')?.setValue(price + ".00")
            }
            if(decimalsNumber == 1){
                return this.priceFormGroup.get('price')?.setValue(price + "0")
            }
            return price
        }
    }

    countDecimals(number: number) {
        const decimalIndex = number.toString().indexOf('.');
        return decimalIndex >= 0 ? number.toString().length - decimalIndex - 1 : 0;
    }

}

export function priceFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const price = control.value;
        if(price?.toString().includes('-')){
            return {negative: true}
        }
        if (price) {
            let priceString = price.toString();
            priceString = priceString.replace(",", ".").replace(" ", "").replace("€", "");
            const amountDots = priceString.split(".").length - 1;
            if(amountDots > 1) {
                return {priceFormat: {value: control.value}};
            }
            if(amountDots == 1) {
                const cents = priceString.split(".")[1];
                if(cents.length > 2) {
                    return {priceFormat: {value: control.value}};
                }
            }
        }
        return null;
    };
}

