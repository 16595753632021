import {Injectable} from "@angular/core";
import {PasswordResetDatasource} from "./password-reset.datasource";
import {RequestPassword} from "./password-reset.types";

@Injectable()
export class PasswordResetService {
    constructor(private passwordResetDatasource: PasswordResetDatasource) {
    }

    public requestResetPassword(request: RequestPassword){
        return this.passwordResetDatasource.requestPassReset(request);
    }

}