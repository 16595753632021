import {Injectable} from "@angular/core";
import {v4 as uuidv4} from 'uuid';
import {FlavoroHttpClient} from "../../shared/http/http.client";
import {map} from "rxjs";
import {Endpoint} from "../../endpoints.definition";

@Injectable({
    providedIn: 'root'
})
export class MediaService {

    constructor(private http: FlavoroHttpClient) {
    }

    public uploadImage(image: File, type: MediaType) {
        const formData = new FormData();
        formData.append('image', image);
        formData.append('type', type)
        //TODO: Error handling
       /* return this.http.post<CreateMediaResponse>("media", formData).pipe(map((response) => response.path));*/
        return this.http.post<CreateMediaResponse>(Endpoint.MEDIA, formData);
    }
}

export enum MediaType {
    MENU_ITEM = 'menuItem',
    CATEGORY = 'category',
    MENU = 'menu',
    RESTAURANT = 'restaurant',
    HERO_SECTION = 'restaurantHeroSection',
    INTRODUCTION = 'restaurantIntroduction',
}

export interface CreateMediaResponse {
    path: string;
    imageId: string,
    error: string;
}
